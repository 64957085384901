import React, { useState, useEffect } from 'react';
// mui
import {
  Box,
  Table,
  Stack,
  Dialog,
  TableRow,
  MenuItem,
  TableCell,
  TableHead,
  TableBody,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import CloseIcon from '../../../../../../../../../../components/customIcon/Close.svg';
// components
import { fetchDiagnosticReport, SubmitDownload } from '../../../../../../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function DownloadReport(props) {
  const { t } = useTranslation();

  const { evseName } = props;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [download, setDownload] = useState({});
  const [report, setReport] = useState();

  const cp_id = evseName;

  const column = [
    {
      id: 'evse',
      label: t('EVSE'),
      width: 80,
    },
    {
      id: 'name',
      label: t('File name'),
      width: 120,
    },
    {
      id: 'status',
      label: t('Status'),
      width: 100,
    },
    {
      id: 'period',
      label: t('Period'),
      width: 160,
    },
    {
      id: 'option',
      label: t('Option'),
      width: 160,
    },
  ];

  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  useEffect(() => {
    fetchDiagnosticReport(cp_id, setError, setErrorMessage).then((promiseResult) => setReport(promiseResult));
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDownload = async (diagnostic_report_id) => {
    setDownload({ ...download, [diagnostic_report_id]: true });
    SubmitDownload(diagnostic_report_id, setError, setErrorMessage).then((promiseResult) => {
      const url = window.URL.createObjectURL(new Blob([promiseResult]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', diagnostic_report_id);
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <Box>
      <MenuItem onClick={handleClickOpen}>{t('down_diaReport')}</MenuItem>
      <Dialog open={open} onClose={handleClose} aria-labelledby="ChangeAvailablity" maxWidth="xl">
        <DialogTitle id="DownloadDiagnosticReport">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('down_diaReport')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          {report === undefined ? (
            <Typography variant="body1">{t('No_Report_downloaded')}</Typography>
          ) : (
            <Typography variant="body1">{t('Select_Report_downloaded')}</Typography>
          )}
          {report === undefined ? (
            <br />
          ) : (
            <Table aria-labelledby="tableTitles">
              <TableHead>
                <TableRow>
                  {column.map((headCell) => (
                    <TableCell key={headCell.id} align="left" padding="normal" sx={{ width: headCell.width }}>
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {report?.map((data) => (
                  <TableRow hover key={data.cp_id}>
                    <TableCell align="left" padding="normal">
                      {data.cp_id}
                    </TableCell>
                    <TableCell align="left" padding="normal">
                      {data.file_name}
                    </TableCell>
                    <TableCell align="left" padding="normal">
                      {data.status}
                    </TableCell>
                    <TableCell>
                      {data.start_date}-{data.end_date}
                    </TableCell>
                    <TableCell>
                      <IconButton aria-label={t('delete')} disabled={data.status === 'Uploaded' ? false : true}>
                        <DeleteIcon />
                      </IconButton>
                      <IconButton
                        aria-label={t('download')}
                        disabled={data.status === 'Uploaded' ? false : true}
                        onClick={() => handleDownload(data.file_name)}
                      >
                        {download[data.file_name] ? <DownloadDoneIcon /> : <DownloadIcon />}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
}
