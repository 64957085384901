import React, { useEffect, useState } from 'react';
// mui
import { Stack, Typography, Card, CardContent, CardHeader, ListItem, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function Con_flat({ defaultData, allData }) {
  const [formData, setFormData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (defaultData) {
      setFormData(defaultData);
      //   console.log(allData);
    }
  }, [defaultData]);
  function getDayType(daysArray) {
    // const weekdays = ['Friday', 'Monday', 'Thursday', 'Tuesday', 'Wednesday'];
    const weekdays = ['FRIDAY', 'MONDAY', 'THURSDAY', 'TUESDAY', 'WEDNESDAY'];
    //const weekends = ['Saturday', 'Sunday'];
    const weekends = ['SATURDAY', 'SUNDAY'];

    const sortedDays = [...daysArray].sort(); // Sort the days for comparison

    if (sortedDays.toString() === weekdays.toString()) {
      return 'weekdays';
    } else if (sortedDays.toString() === weekends.toString()) {
      return 'weekends';
    } else if (daysArray.length === 7) {
      return 'Every day';
    } else {
      return daysArray;
    }
  }

  return (
    <div>
      {/* Flat start or transaction fee */}
      <Card variant="outlined">
        <CardContent>
          <h3>{t('Start or transaction fee')}</h3>
          {formData.map((ele, ind) => {
            let sentence = '';

            if (ele.restrictions_show) {
              if (ele.restrictions.day_of_week.length !== 0) {
                let trans = getDayType(ele.restrictions.day_of_week);
                if (trans == 'weekdays' || trans == 'weekends') {
                  sentence += ' ' + t('sentence_on') + ' ' + t(trans);
                } else if (trans == 'Every day') {
                  sentence += ' ' + t('Every day');
                } else {
                  sentence += ' ' + t('sentence_on') + ' ';
                  for (let index = 0; index < trans.length; index++) {
                    const element = trans[index];
                    sentence += t(element) + ',';
                  }
                }
              }
              const start = ele.restrictions.start_time;
              const end = ele.restrictions.end_time;

              if (start && end && start !== '' && end !== '') {
                sentence += ` ${t('between_and', { start, end })}`;
              }
            }
            return (
              <div key={ind} style={{ marginTop: '10px' }}>
                {sentence && (
                  <ul style={{ marginLeft: '20px' }}>
                    <li>{sentence}</li>
                  </ul>
                )}
                <ul style={{ marginLeft: '40px' }}>
                  <li>
                    {ele.price_component[0].price} {allData.currency}({t('excl.VAT')})
                  </li>
                  <li>
                    {ele.price_component[0].vat} % {t('VAT')}
                  </li>
                </ul>
              </div>
            );
          })}
        </CardContent>
      </Card>
    </div>
  );
}
