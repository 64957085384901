import React, { createContext } from 'react';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import Pool from './UserPool';

// ----------------------------------------------------------------------

const AccountContext = createContext();

const Account = (props) => {
  const getUserAttributes = (user) => {
    return new Promise((resolve, reject) => {
      user.getUserAttributes((err, attributes) => {
        if (err) {
          reject(err);
        } else {
          const results = {};
          for (let attribute of attributes) {
            const { Name, Value } = attribute;
            results[Name] = Value;
          }
          resolve(results);
        }
      });
    });
  };

  const getSession = async () => {
    try {
      const user = Pool.getCurrentUser();
      if (!user) {
        throw new Error('User not found.');
      }
      const session = await new Promise((resolve, reject) => {
        user.getSession((err, session) => {
          if (err) {
            reject(err);
          } else {
            resolve(session);
          }
        });
      });
      const attributes = await getUserAttributes(user);
      return { user, ...session, ...attributes };
    } catch (error) {
      return error;
    }
  };

  const authenticate = async (
    Username,
    Password,
    newPassword,
    setPage,
    setUsername,
    setErrMsg,
    setFirstLogin,
    navigate,
    success
  ) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          console.log('onSuccess: ', data);
          console.log(data.idToken.jwtToken);
          sessionStorage.setItem('email', data.idToken.payload.email);
          sessionStorage.setItem('aws_token', data.idToken.jwtToken);
          if (data.idToken.payload.email_verified === true) {
            navigate(success, { replace: true });
          } else {
            console.log('email not verified');
            user.getAttributeVerificationCode('email', {
              onSuccess: () => {
                console.log('Verification code sent successfully');
                setPage(true);
                setUsername(Username);
              },
              onFailure: (err) => {
                console.log('Failed to send verification code:', err);
              },
            });
          }
          reject(data);
        },
        onFailure: (err) => {
          console.log('onFailure: ', err);
          setErrMsg(`${err}`);
          resolve(err);
        },
        newPasswordRequired: (data) => {
          console.log('newPasswordRequired: ', data);
          sessionStorage.setItem('email', data.idToken.payload.email);
          setFirstLogin(true);
          user.completeNewPasswordChallenge(
            newPassword,
            {},
            {
              onSuccess: (data) => {
                console.log('onSuccess: ', data);
                console.log(data.idToken.jwtToken);
                sessionStorage.setItem('aws_token', data.idToken.jwtToken);
                navigate(success, { replace: true });
              },
              onFailure: (err) => {
                console.log('onFailure: ', err);
                setErrMsg(`${err}`);
              },
            }
          );
          resolve(data);
        },
      });
    });
  };

  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
    }
  };
  return (
    <AccountContext.Provider value={{ authenticate, getSession, logout }}>{props.children}</AccountContext.Provider>
  );
};

export { Account, AccountContext };
