import React, { useState, useEffect } from 'react';
// mui
import {
  Box,
  Stack,
  Dialog,
  Button,
  MenuItem,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { buttonR, buttonL } from '../../../../../../../../../../components/button/buttonStyle';
import CloseIcon from '../../../../../../../../../../components/customIcon/Close.svg';
import { SubmitUpload } from '../../../../../../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function UploadFile(props) {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState();
  const [name, setName] = useState('Choose File');
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setName('Choose File');
    setFile(null);
  };

  const onSubmit = () => {
    setLoading(true);
    SubmitUpload(file, setError, setErrorMessage).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        props.setSuccess(true);
        props.CloseMain();
        handleClose();
      } else {
        props.setErrorMessage(promiseResult.message);
        props.setError(true);
      }
      setLoading(false);
    });
  };

  return (
    <div>
      <MenuItem onClick={handleClickOpen}>{t('Upload File')}</MenuItem>
      <Dialog open={open} onClose={handleClose} aria-labelledby="UploadFirmwareDialog" maxWidth="sm">
        <DialogTitle id="UploadFirmware">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('Upload Firmware for EVSE')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Typography>{t('Choose firmware to be uploaded')}</Typography>
          <Button variant="contained" component="label">
            {name}
            <input
              hidden
              // accept="image/*"
              // multiple
              type="file"
              onChange={(e) => {
                setName(e.target.files[0].name);
                setFile(e.target.files[0]);
              }}
            />
          </Button>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} sx={{ mt: 1 }}>
            <LoadingButton variant="outlined" onClick={() => handleClose()} sx={buttonL}>
              {t('Cancel')}
            </LoadingButton>
            <LoadingButton onClick={onSubmit} loading={loading} sx={buttonR}>
              {t('Upload')}
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
