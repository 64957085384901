import React, { useState, useEffect, useRef } from 'react';
// mui
import {
  Box,
  Button,
  IconButton,
  Stack,
  Dialog,
  Switch,
  MenuItem,
  FormControlLabel,
  TextField,
  DialogTitle,
  DialogContent,
  Card,
  CardContent,
  Typography,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from '@mui/lab';
import { useForm, Controller } from 'react-hook-form';
// components
import useAuth from '../../../../../../../../hooks/useAuth';
import { buttonR, buttonL } from '../../../../../../../../components/button/buttonStyle';
import CloseIcon from '../../../../../../../../components/customIcon/Close.svg';
import { fetchEVSECS, SubmitChangeAvailabilityCS } from '../../../../../../../../api/AllAPI';
import TimeComponet from './TimeComponet';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { BorderBottom } from '@mui/icons-material';
import { createTheme, ThemeProvider, makeStyles } from '@mui/material/styles';
import img_url from './image/smartCharging-image.png';
import { useTranslation } from 'react-i18next';

export default function SmartCharging(props) {
  const { t } = useTranslation();

  const { auth } = useAuth();
  const { stationID } = props;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState();
  const [open, setOpen] = useState(false);
  const [formMCP, setFormMCP] = useState(false);
  const [formStopTime, setFormStopTime] = useState(false);
  const [formConvergence, setFormConvergence] = useState(false);
  const theme = createTheme({
    overrides: {
      MuiAccordion: {
        root: {
          '&:before': {
            content: '',
            // top:'0px',
            // backgound: 'red',
            // height:'0px'
          },
        },
      },
    },
  });

  useEffect(() => {
    setFormData({
      // stop_time: '5',
      // convergence: '0.005',
      // update_frequency: '900',
      station_breaker_voltage: '400',
      station_breaker_phase: '',
      station_breaker_amp: '100',
      grid_source_voltage: '400',
      grid_source_phase: '',
      grid_source_amp: '100',
      grid_source: false,
      // eves_mininum_power:'1.4',
      // eves_maxinum_power:'7.0',
      // mininum_charging_percentage: '0.0',
      evse_charge_strat: 'amt',
      // Unit:'amt',
      // is_near_hotel:'false',
      renewable: false,
      renewable_re: 'max_re_usuage',
      Charging_station_config: false,
    });
  }, []);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (reason) => {
    // console.log(reason)
    setOpen(false);
    setFormData({
      // stop_time: '5',
      // convergence: '0.005',
      // update_frequency: '900',
      station_breaker_voltage: '400',
      station_breaker_phase: '',
      station_breaker_amp: '100',
      grid_source_voltage: '400',
      grid_source_phase: '',
      grid_source_amp: '100',
      grid_source: false,
      // eves_mininum_power:'1.4',
      // eves_maxinum_power:'7.0',
      // mininum_charging_percentage: '0.0',
      evse_charge_strat: 'amt',
      // Unit:'amt',
      // is_near_hotel:'false',
      renewable: false,
      renewable_re: 'max_re_usuage',
      Charging_station_config: false,
    });
    setErrors({
      grid_source_phase: '',
      station_breaker_phase: '',
    });
  };

  // Form Data
  const [formData, setFormData] = useState({
    // stop_time: '5',
    // convergence: '0.005',
    // update_frequency: '900',
    station_breaker_voltage: '400',
    station_breaker_phase: '',
    station_breaker_amp: '100',
    grid_source_voltage: '400',
    grid_source_phase: '',
    grid_source_amp: '100',
    grid_source: false,
    // eves_mininum_power:'1.4',
    // eves_maxinum_power:'7.0',
    // mininum_charging_percentage: '0.0',
    evse_charge_strat: 'amt',
    // Unit:'amt',
    // is_near_hotel:'false',
    renewable: true,
    renewable_re: 'max_re_usuage',
    Charging_station_config: false,
  });
  const [errors, setErrors] = useState({
    grid_source_phase: '',
    station_breaker_phase: '',
  });
  const handleChange = (event) => {
    const val = event.target.value;
    if (val < 0 || val === '') {
      setFormData({
        ...formData,
        [event.target.name]: '0',
      });
    } else {
      setFormData({
        ...formData,
        [event.target.name]: val,
      });
    }
  };
  // handleKeyPress
  const handleKeyPress = (e) => {
    // not allowed input小数点（.）and（e/E）
    if (e.key === '.' || e.key === 'e' || e.key === 'E') {
      e.preventDefault();
    }
  };
  // handleSubmit
  const handleSubmit = (event) => {
    event.preventDefault();
    // not empty verify
    let hasError = false;
    if (formData.Charging_station_config) {
      for (const key in formData) {
        const er = errors;
        if (formData.hasOwnProperty(key) && formData[key] === '' && er.hasOwnProperty(key)) {
          er[key] = t('required fields');
          setErrors({ ...errors, [key]: t('required fields') });
          hasError = true;
        }
      }
    }
    // do not submit
    if (hasError) {
      return;
    }

    console.log(formData); //get formData array
    console.log(getDataHandler()); //electricity price array
  };

  // Electricity price part==================================================================

  // Click add button to add a time componet
  const componentRefs = useRef([]);
  const [componentCount, setComponentCount] = useState(1);
  const [components, setComponents] = useState([1]);
  // let component data range keep 0-24
  const [time_keep_Data, setTime_keep_Data] = useState([
    {
      start_time: '00:00',
      end_time: '00:00',
      price: '0.00',
    },
  ]);
  // Click button addComponent function
  const addComponent = () => {
    setComponentCount(componentCount + 1);
    setComponents([...components, componentCount + 1]);
    // change fill data
    const allComponetData = getDataHandler();
    const saveData = allComponetData[allComponetData.length - 1].end_time;
    // var keepData=time_keep_Data
    allComponetData[allComponetData.length - 1].end_time = '';
    setTime_keep_Data([
      ...allComponetData,
      {
        start_time: '',
        end_time: saveData,
        price: '0.00',
      },
    ]);
  };
  // Click button deleteComponent function
  const deleteComponent = () => {
    if (componentCount > 1) {
      const allComponetData = getDataHandler();
      setComponentCount(componentCount - 1);
      setComponents(components.slice(0, -1));
      componentRefs.current.pop();
      // change fill data
      const saveData = allComponetData[allComponetData.length - 1].end_time;
      // var keepData=time_keep_Data
      // keepData[keepData.length-1].end_time=""
      const data = allComponetData.slice(0, -1);
      data[data.length - 1].end_time = saveData;
      setTime_keep_Data(data);
    }
    console.log(componentRefs.current);
  };
  // get componet data
  const getDataHandler = () => {
    const gData = [];
    componentRefs.current.forEach((ref) => {
      gData.push(ref.current.getData());
      ref.current.getData(); // call componet function
    });
    return gData;
  };
  const getTriggleValue = (val, componentIndex) => {
    const allComponetData = getDataHandler();
    allComponetData[componentIndex].start_time = val;
    allComponetData[componentIndex - 1].end_time = val;
    setTime_keep_Data([...allComponetData]);
  };
  // ========================================================================

  return (
    <div>
      <MenuItem onClick={handleClickOpen}>{t('Smart Charging')}</MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="SmartCharging"
        fullWidth
        maxWidth="md"
        disableEscapeKeyDown
      >
        <DialogTitle id="SmartCharging">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('Charging Station Level')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            {/* Electricity Price Config  #17262A */}
            <Accordion defaultExpanded style={{ marginBottom: '16px' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5a-content"
                id="panel5a-header"
                style={{ background: '#232F34', borderBottom: '1px solid #2b3b45' }}
              >
                <Typography>{t('Electricity Price Config')}</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ background: '#232F34' }}>
                <Box style={{ marginTop: '0px' }}>
                  <div style={{ padding: '0' }}>
                    {/* add time complate  */}
                    <div style={{ position: 'relative' }}>
                      <div style={{ position: 'absolute', right: '0', top: '13px' }}>
                        <AddCircleOutlineIcon style={{ cursor: 'pointer' }} title={t('Add')} onClick={addComponent} />
                        <RemoveCircleOutlineIcon
                          style={{ cursor: 'pointer' }}
                          title={t('Remove')}
                          onClick={deleteComponent}
                        />
                      </div>
                      <div style={{ marginRight: '50px' }}>
                        {components.map((index) => {
                          const ref = React.createRef();
                          componentRefs.current[index] = ref;
                          return (
                            <div
                              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                              key={index}
                            >
                              <TimeComponet
                                key={index}
                                componentIndex={index}
                                getTriggleValue={getTriggleValue}
                                set_Keep_Data={time_keep_Data}
                                ref={ref}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Box>
              </AccordionDetails>
            </Accordion>
            {/*  Charging Station Config */}
            <div style={{ padding: '5px 16px ', background: '#232F34' }}>
              <span style={{ marginTop: '5px' }}>{t('Charging Station Config')}</span>
              <Switch
                checked={formData.Charging_station_config}
                onChange={function (event) {
                  const val = event.target.checked;
                  setFormData({
                    ...formData,
                    [event.target.name]: val,
                  });
                }}
                name="Charging_station_config"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
            <div style={{ display: formData.Charging_station_config ? 'block' : 'none' }}>
              {/* Station Hardware Config */}
              <Accordion style={{ marginBottom: '0px' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  style={{ marginTop: '0px', marginBottom: '0px', background: '#232F34', border: 'none' }}
                >
                  <Typography>{t('Station Hardware Config')}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ background: '#232F34', padding: '0 30px' }}>
                  <div style={{ minwWidth: '350px', height: '400px', position: 'relative' }}>
                    <Box
                      component="img"
                      src={img_url}
                      sx={{ minwWidth: '350px', height: '350px', position: 'absolute' }}
                    />
                    {/* Grid Source */}
                    <div
                      style={{
                        position: 'absolute',
                        left: '130px',
                        top: '90px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {/* <span style={{ marginTop: '5px',color:'black' }}>
                        Grid Source
                      </span> */}
                      <div style={{}}>
                        <Switch
                          checked={formData.grid_source}
                          onChange={function (event) {
                            const val = event.target.checked;
                            const data = formData;
                            if (!val) {
                              data.grid_source_amp = '100';
                              data.grid_source_phase = '';
                              data.grid_source_voltage = '400';
                            }
                            setFormData({
                              ...data,
                              [event.target.name]: val,
                            });
                          }}
                          name="grid_source"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      </div>
                      <div
                        style={{
                          display: formData.grid_source ? 'inline-block' : 'none',
                        }}
                      >
                        <TextField
                          margin="dense"
                          select
                          name="grid_source_phase"
                          label={t('Grid Source Phase')}
                          value={formData.grid_source_phase}
                          error={!!errors.grid_source_phase}
                          helperText={errors.grid_source_phase}
                          onChange={(e) => {
                            handleChange(e);
                            setErrors({ ...errors, grid_source_phase: '' });
                          }}
                          size="small"
                          type="number"
                          style={{ width: '180px' }}
                        >
                          <MenuItem key="3-Phase" value="3-Phase">
                            {t('3-Phase')}
                          </MenuItem>
                          <MenuItem key="2-Phase" value="2-Phase">
                            {t('2-Phase')}
                          </MenuItem>
                          <MenuItem key="1-Phase" value="1-Phase">
                            {t('1-Phase')}
                          </MenuItem>
                        </TextField>
                        <TextField
                          margin="dense"
                          name="grid_source_voltage"
                          label={t('Grid Source Voltage')}
                          value={formData.grid_source_voltage}
                          onChange={handleChange}
                          defaultValue="400"
                          size="small"
                          type="number"
                          onKeyPress={handleKeyPress}
                          style={{ width: '140px' }}
                        />
                        <TextField
                          margin="dense"
                          name="grid_source_amp"
                          label={t('Grid Source Amp')}
                          value={formData.grid_source_amp}
                          onChange={handleChange}
                          defaultValue="100"
                          size="small"
                          type="number"
                          onKeyPress={handleKeyPress}
                          style={{ width: '120px' }}
                        />
                      </div>
                    </div>
                    {/* station_breaker */}
                    <div
                      style={{
                        position: 'absolute',
                        left: '247px',
                        top: '155px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <TextField
                        margin="dense"
                        select
                        name="station_breaker_phase"
                        label={t('Station Breaker Phase')}
                        value={formData.station_breaker_phase}
                        onChange={(e) => {
                          handleChange(e);
                          setErrors({ ...errors, station_breaker_phase: '' });
                        }}
                        style={{ width: '180px' }}
                        size="small"
                        type="number"
                        error={!!errors.station_breaker_phase}
                        helperText={errors.station_breaker_phase}
                      >
                        <MenuItem key="3-Phase" value="3-Phase">
                          {t('3-Phase')}
                        </MenuItem>
                        <MenuItem key="2-Phase" value="2-Phase">
                          {t('2-Phase')}
                        </MenuItem>
                        <MenuItem key="1-Phase" value="1-Phase">
                          {t('1-Phase')}
                        </MenuItem>
                      </TextField>
                      <TextField
                        margin="dense"
                        name="station_breaker_voltage"
                        label={t('Station Breaker Voltage')}
                        value={formData.station_breaker_voltage}
                        onChange={handleChange}
                        defaultValue="400"
                        size="small"
                        type="number"
                        onKeyPress={handleKeyPress}
                        style={{ width: '155px' }}
                        helperText=""
                      />
                      <TextField
                        margin="dense"
                        name="station_breaker_amp"
                        label={t('Station Breaker Amp')}
                        value={formData.station_breaker_amp}
                        onChange={handleChange}
                        style={{ width: '155px' }}
                        helperText=""
                        defaultValue="100"
                        size="small"
                        type="number"
                        onKeyPress={handleKeyPress}
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
            {/* Renewable */}
            <Box style={{ borderTop: '1px solid #2b3b45', margin: '16px 0px', background: '#232F34' }}>
              <div style={{ padding: '10px 0', padding: '0 16px 10px' }}>
                <span style={{ marginTop: '5px' }}>{t('Renewable')}</span>
                <Switch
                  checked={formData.renewable}
                  onChange={function (event) {
                    const val = event.target.checked;
                    const data = formData;
                    data.evse_charge_strat = 'amt';
                    // data.is_near_hotel='false'
                    data.renewable_re = 'max_re_usuage';
                    setFormData({
                      ...data,
                      [event.target.name]: val,
                    });
                  }}
                  name="renewable"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </div>
              <div
                style={{ display: formData.renewable ? 'block' : 'none', background: '#232F34', padding: '15px 15px' }}
              >
                {/* Evse Charge Start */}
                <Box style={{ background: '#232F34' }}>
                  <TextField
                    select
                    label=""
                    name="renewable_re"
                    size="small"
                    value={formData.renewable_re}
                    onChange={function (event) {
                      const val = event.target.value;
                      setFormData({
                        ...formData,
                        [event.target.name]: val,
                      });
                    }}
                    variant="outlined"
                  >
                    <MenuItem key="max_re_usuage" value="max_re_usuage">
                      {t('Re Usuage')}
                    </MenuItem>
                    <MenuItem key="max_re_selling" value="max_re_selling">
                      {t('Re Selling')}
                    </MenuItem>
                  </TextField>

                  <Box style={{ display: formData.renewable_re === 'max_re_usuage' ? 'inline-block' : 'none' }}>
                    <TextField
                      select
                      style={{ width: '370px' }}
                      // fullWidth
                      label={t('Evse Charge strategy')}
                      name="evse_charge_strat"
                      size="small"
                      value={formData.evse_charge_strat}
                      onChange={handleChange}
                      variant="outlined"
                    >
                      <MenuItem key="amt" value="amt">
                        {t('Amount')}
                      </MenuItem>
                      <MenuItem key="percentage" value="percentage">
                        {t('Percentage')}
                      </MenuItem>
                    </TextField>
                  </Box>
                  <Box style={{ display: formData.renewable_re === 'max_re_selling' ? 'inline-block' : 'none' }}>
                    <TextField
                      disabled
                      style={{ width: '370px' }}
                      // fullWidth
                      defaultValue="max_re_selling"
                      size="small"
                      variant="outlined"
                    />
                  </Box>
                </Box>
              </div>
            </Box>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
              <Button sx={buttonR} variant="outlined" type="submit">
                {t('Submit')}
              </Button>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
