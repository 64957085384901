import React from 'react';
// mui
import { Stack, Typography, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function SelectRestartPage({ formData, setFormData, setReset_type }) {
  const { t } = useTranslation();

  const handleCheck1 = (event) => {
    setFormData({ ...formData, soft: event.target.checked, hard: false, DateTime: false });
    setReset_type('Soft');
  };

  const handleCheck2 = (event) => {
    setFormData({ ...formData, hard: event.target.checked, soft: false, DateTime: true });
    setReset_type('Hard');
  };
  return (
    <Stack direction="column" spacing={3}>
      <Typography variant="body1">
        {t('checked_Restart', { checked: formData.checked })}
        {/* // Selected {formData.checked} to carry out Restart. */}
      </Typography>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={formData.soft} onChange={handleCheck1} name="soft" />}
          label={t('Soft Restart')}
        />
        <FormControlLabel
          control={<Checkbox checked={formData.hard} onChange={handleCheck2} name="hard" />}
          label={t('Hard Restart')}
        />
      </FormGroup>
    </Stack>
  );
}
