import React, { useState, useEffect } from 'react';
// mui
import { Box, Stack, Dialog, DialogTitle, DialogContent, MenuItem, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import SelectUser from './SelectUser';
import { buttonR, buttonL } from '../../../../../../../../../components/button/buttonStyle';
import CloseIcon from '../../../../../../../../../components/customIcon/Close.svg';
import { fetchEVSE, SubmitLocalList } from '../../../../../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function LocalList(props) {
  const { evseName } = props;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    searchuser: [],
    inserteduser: [],
  });

  const cp_id = evseName;
  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  useEffect(() => {
    fetchEVSE(cp_id, setError, setErrorMessage).then((promiseResult) =>
      setFormData({
        ...formData,
        searchuser: promiseResult.accepted_user,
        inserteduser: promiseResult.existing_user,
      })
    );
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    setLoading(true);
    const user_list = formData.inserteduser;
    SubmitLocalList(cp_id, user_list, setError, setErrorMessage).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        // setFormData({ ...formData, searchuser: [], inserteduser: [] });
        props.setSuccess(true);
        props.CloseMain();
        handleClose();
      } else {
        props.setErrorMessage(promiseResult.message);
        props.setError(true);
      }
      setLoading(false);
    });
  };

  return (
    <div>
      <MenuItem onClick={handleClickOpen}>{t('Local List')}</MenuItem>
      <Dialog open={open} onClose={handleClose} aria-labelledby="LocalListDialog" fullWidth maxWidth="md">
        <DialogTitle id="LocalList">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('Local List')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <SelectUser formData={formData} setFormData={setFormData} />
          <br />
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <LoadingButton
              variant="outlined"
              onClick={() => {
                handleClose();
              }}
              sx={buttonL}
            >
              {t('Cancel')}
            </LoadingButton>
            <LoadingButton onClick={onSubmit} loading={loading} sx={buttonR}>
              {t('Submit')}
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
