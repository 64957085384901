import React, { useEffect, useState } from 'react';
// mui
import { Grid, Box, Stack, Card, CardHeader } from '@mui/material';
// sections
import RevenueChart from '../../../../components/apexcharts/BarChart';
import RevenueWidget from '../../../../components/Widget';
import LocationSelect from '../../../../components/Select/LocationSelect';
import GraphSelect from '../../../../components/Select/GraphSelect';
import date from '../../../../components/LastDate';
import LoginAgain from '../../../../components/LoginAgain';
import TotalRevenue from '../../../../components/customIcon/icon-revenue-40.svg';
import LowRevenue from '../../../../components/customIcon/icon-lowest-transaction-40.svg';
import HighRevenue from '../../../../components/customIcon/icon-highest-transaction-40.svg';
import { fetchRevenueLocation } from '../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

export default function DashboardApp(props) {
  const { locationList } = props;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [dataRevenue, setDataRevenue] = useState([]);
  const [widgetSelect, setWidgetSelect] = useState('');
  const [graphSelect, setGraphSelect] = useState('');
  const [graphRevenue, setGraphRevenue] = useState([]);
  const [tableYRevenue, setTableYRevenue] = useState(date.Last7Days());
  const [unauthorized, setUnauthorized] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  const handleCallbackRevenue = (location) => {
    fetchRevenueLocation(location, setError, setErrorMessage, setUnauthorized).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        setDataRevenue(promiseResult.data[0]);
      } else {
        props.setErrorMessage(promiseResult.message);
        props.setError(true);
      }
    });
    setWidgetSelect(location);
    setGraphSelect(1);
  };

  const handleCallbackGraphRevenue = (childData) => {
    setGraphSelect(childData);
  };

  useEffect(() => {
    if (graphSelect === 2) {
      setTableYRevenue(date.Last7Months);
      setGraphRevenue(dataRevenue.monthly);
    } else if (graphSelect === 3) {
      setTableYRevenue(date.Last7Years);
      setGraphRevenue(dataRevenue.yearly);
    } else {
      setTableYRevenue(date.Last7Days);
      setGraphRevenue(dataRevenue.daily);
    }
  }, [widgetSelect, graphSelect, dataRevenue]);

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      {unauthorized && <LoginAgain />}
      <Box>
        <Card sx={{ mb: 2, borderRadius: '4px', pl: 3, pb: 3, pr: 3, boxShadow: 0 }}>
          <Stack direction="row" justifyContent="flex-start" alignItems="center">
            {dataRevenue && (
              <LocationSelect
                parentCallback={handleCallbackRevenue}
                locations={locationList}
                // datafromparent={widgetSelect}
              />
            )}
          </Stack>
          <Stack direction="row" justifyContent="space-around" alignItems="flex-start" spacing={3}>
            <RevenueWidget
              title={t('Location Revenue')}
              currency="SGD"
              total={dataRevenue.revenue}
              color="info"
              icon={TotalRevenue}
            />
            <RevenueWidget
              title={t('Highest Transaction')}
              currency="SGD"
              total={dataRevenue.highest_transaction}
              color="info"
              icon={HighRevenue}
            />
            <RevenueWidget
              title={t('Lowest Transaction')}
              currency="SGD"
              total={dataRevenue.lowest_transaction}
              color="info"
              icon={LowRevenue}
            />
          </Stack>
        </Card>

        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Card
              sx={{
                boxShadow: 0,
                borderRadius: '4px',
                color: '#F4F4F4',
              }}
            >
              <CardHeader
                title={t('Dollars Earned')}
                action={<GraphSelect parentCallback={handleCallbackGraphRevenue} datafromparent={graphSelect} />}
              />
              {graphRevenue && <RevenueChart yData={tableYRevenue} xLabel={t('Dollars')} xData={graphRevenue} />}
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
