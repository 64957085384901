import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate, Link as RouterLink, useLocation } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment, Typography, styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// socket-io
import io from 'socket.io-client';
import { socketUrl } from '../../../api/socket';
// components
import { octtUser } from '../../../api/OCTT/OCTTuser';
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import useAuth from '../../../hooks/useAuth';
import { SubmitLogin } from '../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

let socket1;

export default function LoginForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const octt = location.state?.from?.pathname || '/pages/octt';
  const from = location.state?.from?.pathname || '/pages/summary';
  const { setAuth } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordConvergence, setPasswordConvergence] = useState(false);
  const [helperText, setHelperText] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    setErrMsg('');
  }, [email, password]);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(t('verify_emailAddress')),
    password: Yup.string(),
  });

  const methods = useForm({ resolver: yupResolver(LoginSchema) });

  const { handleSubmit } = methods;

  const onSubmit = () => {
  // sessionStorage.setItem('email', 'email');
  // sessionStorage.setItem('access_token', 'eyJhbGciOiJSUzI1NiJ9.eyJjdXN0b206dGVuYW50X2lkIjoibmFtZSIsImlhdCI6MTcxMzIzODA5NiwibmFtZSI6Ik1pbmdzaGVuIiwicm9sZSI6IlN1cGVyQWRtaW4iLCJzdWIiOiJ0ZXN0c3ViIn0.Xc61HvFHimcFjb4lpDN557TabllapXu5hs4th8CcPhP9oX5kRRajUSINwDBtwCMrcxRJ4xyoLyYmRQ-SPzYUK2W_ZJoRze4VN_SedCzoKHVbA6uOCXn7Yt77hvDYesMRPascepZYcpZ_Uu23FlL2hQ1sTeOA1mcBtdSHhlaX3LlliGTD53ZiburWoYHTGucrFVS1zDHXYtPYk5QYjCyis2y2jkH2BTnxsccWnDht0fU9r7aIx3QP4s2QnJZeE2tSqwpQVRL6AUMci2p0MRfCFkU-yZdqWRMNpiLfeThpfxPxTeS2f1wHoXW0a2BewhlEmbjdl7OGbt8VjRIaiQs63A');
  // sessionStorage.setItem('name', 'name');
  // sessionStorage.setItem('role', 'role');
  // navigate(from, { replace: true });
    SubmitLogin(email, password, setErrMsg)
      .then((promiseResult) => {
        if (promiseResult.status === 401) {
          setErrMsg(promiseResult.message);
        } else {
          console.log("promiseResult.AuthenticationResult is ", promiseResult.AuthenticationResult)
          const { AccessToken, IdToken } = promiseResult.AuthenticationResult;

          let payload = IdToken.split('.')[1];
          let base64 = payload.replace('-', '+').replace('_', '/');
          let userdata = JSON.parse(window.atob(base64));

          const { name, 'custom:role': role } = userdata;

          setAuth({ email, IdToken, name, role });
          console.log("email is ", email)
          console.log("access_token is ", IdToken)
          console.log("name is ", name)
          console.log("role is ", role)
          sessionStorage.setItem('email', email);
          sessionStorage.setItem('access_token', IdToken);
          sessionStorage.setItem('name', name);
          sessionStorage.setItem('role', role);
          setEmail('');
          setPassword('');
          if (email === octtUser) {
            navigate(octt, { replace: true });
          } else {
            navigate(from, { replace: true });
          }
          socket1 = io.connect(socketUrl, {
            // transportOptions: {
            //   polling: {
            extraHeaders: {
              authorization: sessionStorage.getItem('access_token'),
            },
            //   },
            // },
          });
        }
      })
      .catch((error) => {
        console.log('error:' + error);
      });
  };

  // mouse loses focus passWord cpmplexity verification
  const password_onBlur = (e) => {
    const password = e.target.value;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const digitRegex = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
    setPasswordConvergence(true);
    if (password.length < 8) {
      setHelperText(t('veify_length8'));
    } else if (!lowercaseRegex.test(password)) {
      setHelperText(t('veify_lowercase'));
    } else if (!uppercaseRegex.test(password)) {
      setHelperText(t('veify_uppercase'));
    } else if (!specialCharRegex.test(password)) {
      setHelperText(t('veify_specialchar'));
    } else if (!digitRegex.test(password)) {
      setHelperText(t('veify_number'));
    } else {
      setHelperText('');
      setPasswordConvergence(false);
    }
  };

  return (
    <ContentStyle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Typography variant="subtitle1" sx={{ pl: 1, color: 'error.main' }}>
            {errMsg}
          </Typography>
          <RHFTextField
            name="email"
            label={t('Email address')}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <RHFTextField
            error={passwordConvergence}
            margin="dense"
            helperText={helperText}
            name="password"
            label={t('Password')}
            onChange={(e) => {
              setPassword(e.target.value);
              setHelperText('');
              setPasswordConvergence(false);
            }}
            // onBlur={password_onBlur} // mouse loses focus
            value={password}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <Link variant="subtitle2" underline="hover" component={RouterLink} to="/forgotpassword">
            {t('Forgot password')}?
          </Link>
          <Link variant="subtitle2" underline="hover" component={RouterLink} to="/AWSCognitoLogin">
            {t('AWS_Login')}
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{
            background: 'linear-gradient(to left, #66D0D5, #00B0B9)',
            boxShadow: 0,
            color: '#1E1E1E',
            textAlign: 'center',
          }}
        >
          {t('Login')}
        </LoadingButton>
      </FormProvider>
    </ContentStyle>
  );
}
export { socket1 };

const ContentStyle = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
}));
