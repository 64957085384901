import React, { useState } from 'react';
// mui
import { Box, Menu, IconButton, Tooltip } from '@mui/material';
// component
import ChangeAvailability from './changeavailability/index';
import Smartcharging from './smartcharging/index';
import Icon from '../../../../../../../components/customIcon/icon-kebab-menu-24.svg';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

export default function ChargingStationMenu(props) {
  const { t } = useTranslation();
  const { stationID } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Tooltip title={t('Menu')}>
        <IconButton onClick={handleClickOpen} sx={{ width: '38px', height: '38px', zIndex: 'modal' }}>
          <Box component="img" src={Icon} sx={{ width: '100%', height: '100%' }} />
        </IconButton>
      </Tooltip>
      <Menu
        id="chargingstationmenu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <ChangeAvailability
          handleCloseMain={handleClose}
          stationID={stationID}
          setSuccess={(childData) => props.setSuccess(childData)}
          setErrorMessage={(childData) => props.setErrorMessage(childData)}
          setError={(childData) => props.setError(childData)}
          CAUpdate={(childData) => props.CAUpdate(childData)}
        />
        <Smartcharging
          andleCloseMain={handleClose}
          stationID={stationID}
          setSuccess={(childData) => props.setSuccess(childData)}
          setErrorMessage={(childData) => props.setErrorMessage(childData)}
          setError={(childData) => props.setError(childData)}
          CAUpdate={(childData) => props.CAUpdate(childData)}
        />
      </Menu>
    </div>
  );
}
