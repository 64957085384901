import React, { useEffect, useState } from 'react';
// mui
import { Box, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function GraphSelect(props) {
  const { t } = useTranslation();
  const { datafromparent } = props;
  const [choice, setChoice] = useState('');

  const handleChange = (event) => {
    setChoice(event.target.value);
    props.parentCallback(event.target.value);
  };

  useEffect(() => {
    if (datafromparent === 1) {
      setChoice(1);
    }
  }, [datafromparent]);

  return (
    <Box>
      <FormControl sx={{ width: 'auto', minWidth: 120 }} size="small">
        <InputLabel id="graph-select-location">{t('Select')}</InputLabel>
        <Select
          labelId="graph-select-location"
          id="graph-select-location"
          value={choice}
          label={t('Select')}
          onChange={handleChange}
        >
          {dates.map((dates) => (
            <MenuItem value={dates.id} key={dates.id}>
              {t(dates.name)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

const dates = [
  { id: 1, name: 'Past 7 Days' },
  { id: 2, name: 'Past 7 Months' },
  { id: 3, name: 'Past 7 Years' },
];
