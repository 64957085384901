import React, { useState } from 'react';
// mui
import { Stack, Snackbar, Alert } from '@mui/material';
// sections
import StationChargingLocation from './CL/index';
import StationAddDialog from './StationAddDialog';
import StationSearch from './StationSearch';
import StationFilter from './StationFilter';

// ----------------------------------------------------------------------

export default function ChargingStation(props) {
  const [LocationUpdate, setLocationUpdate] = useState();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setError(false);
  };

  const handleButton = () => {
    if (sessionStorage.getItem('role') === 'SuperAdmin') {
      return (
        <StationAddDialog
          LocationUpdate={(childData) => {
            setLocationUpdate(childData);
          }}
          setSuccess={(childData) => setSuccess(childData)}
          setError={(childData) => setError(childData)}
          setErrorMessage={(childData) => setErrorMessage(childData)}
        />
      );
    }
    return;
  };

  return (
    <>
      <Snackbar open={success} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert severity="success">Success!</Alert>
      </Snackbar>
      <Snackbar open={error} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} sx={{ mb: '17px' }}>
        {handleButton()}
        {/* <StationFilter />
            <StationSearch /> */}
      </Stack>
      <StationChargingLocation
        LocationUpdate={LocationUpdate}
        StationCoordinate={(childData) => props.StationCoordinate(childData)}
        setSuccess={(childData) => setSuccess(childData)}
        setErrorMessage={(childData) => setErrorMessage(childData)}
        setError={(childData) => setError(childData)}
      />
    </>
  );
}
