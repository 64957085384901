import { useState } from 'react';
// mui
import {
  Box,
  Card,
  Alert,
  Stack,
  Button,
  Snackbar,
  TextField,
  IconButton,
  Typography,
  CardContent,
  InputAdornment,
} from '@mui/material';
// components
import Iconify from '../../../components/Iconify';
import useAuth from '../../../hooks/useAuth';
import { SubmitChangePassword } from '../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

function ChangePasswordForm() {
  const { auth } = useAuth();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [old_password, setOld_password] = useState('');
  const [password, setPassword] = useState('');
  const [confirm_password, setConfirm_password] = useState('');
  const [show_old_password, setShow_old_password] = useState(false);
  const [show_password, setShow_password] = useState(false);
  const [show_confirm_password, setShow_confirm_password] = useState(false);
  const { t } = useTranslation();

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setError(false);
  };

  const onSubmit = () => {
    SubmitChangePassword(password, old_password, confirm_password, setErrorMessage, setError).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        setSuccess(true);
        setPassword('');
        setOld_password('');
        setConfirm_password('');
      } else {
        setErrorMessage(promiseResult.message);
        setError(true);
      }
    });
  };

  return (
    <Box>
      <Snackbar open={success} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert severity="success">{t('Success')}!</Alert>
      </Snackbar>
      <Snackbar open={error} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Card sx={{ width: 450 }}>
        <CardContent>
          <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
            <Typography variant="h5">{t('Change Password')}</Typography>
            <TextField
              name="oldPassword"
              label={t('Old Password')}
              onChange={(e) => setOld_password(e.target.value)}
              value={old_password}
              type={show_old_password ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShow_old_password(!show_old_password)} edge="end">
                      <Iconify icon={show_old_password ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
            <TextField
              name="password"
              label={t('New Password')}
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              type={show_password ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShow_password(!show_password)} edge="end">
                      <Iconify icon={show_password ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
            <TextField
              name="confirmPassword"
              label={t('Re-type New Password')}
              onChange={(e) => setConfirm_password(e.target.value)}
              value={confirm_password}
              type={show_confirm_password ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShow_confirm_password(!show_confirm_password)} edge="end">
                      <Iconify icon={show_confirm_password ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
            <Button size="large" variant="outlined" onClick={onSubmit}>
              {t('Save')}
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
}

export default ChangePasswordForm;
