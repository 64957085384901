import { useState } from 'react';
// form
import { useForm, Controller } from 'react-hook-form';
// mui
import {
  Box,
  Stack,
  Alert,
  Button,
  Dialog,
  Avatar,
  Snackbar,
  TextField,
  IconButton,
  DialogTitle,
  Autocomplete,
  DialogContent,
  InputAdornment,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import LoginAgain from '../../../components/LoginAgain';
import { FormProvider } from '../../../components/hook-form';
import Iconify from '../../../components/Iconify';
import { buttonR, buttonL } from '../../../components/button/buttonStyle';
import CloseIcon from '../../../components/customIcon/Close.svg';
import { fetchCS, SubmitAdminAddDialog } from '../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function AddNewAdminDialog(props) {
  const [loading, setLoading] = useState();
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [name, setName] = useState();
  const [employee_id, setEmployee_id] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [charging_station_id, setCharging_station_id] = useState();
  const [all_charging_station, setAll_charging_station] = useState([]);
  const { t } = useTranslation();
  const [unauthorized, setUnauthorized] = useState(false);

  const handleClickOpen = () => {
    fetchCS(setError, setErrorMessage, setUnauthorized).then((promiseResult) => setAll_charging_station(promiseResult));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setError(false);
  };

  // submit form
  const methods = useForm({
    reValidateMode: 'onBlur',
  });

  const onSubmit = () => {
    setLoading(true);
    const charging_stations_id = charging_station_id.map(station => station.charging_station)
    SubmitAdminAddDialog(name, employee_id, email, password, charging_stations_id, setError, setErrorMessage).then(
      (promiseResult) => {
        if (promiseResult.category === 'Success') {
          setSuccess(true);
          props.AddUpdate(Math.random());
          handleClose();
          setName();
          setEmployee_id();
          setEmail();
          setPassword();
          setCharging_station_id();
        } else {
          setErrorMessage(promiseResult.message);
          setError(true);
        }
        setLoading(false);
      }
    );
  };

  return (
    <div>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        sx={{
          width: '153px',
          height: '32px',
          background: 'linear-gradient(to left, #66D0D5, #00B0B9)',
          boxShadow: 'none',
          color: '#1E1E1E',
          fontFamily: 'Arial',
          fontWeight: 700,
          fontSize: '14px',
          lineHeight: '22px',
          textAlign: 'center',
        }}
      >
        + {t('Add New Admin')}
        <Snackbar open={success} autoHideDuration={5000} onClose={handleCloseSnackbar}>
          <Alert severity="success">Success!</Alert>
        </Snackbar>
        <Snackbar open={error} autoHideDuration={5000} onClose={handleCloseSnackbar}>
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="AddNewAdminDialog">
        {unauthorized && <LoginAgain />}
        <DialogTitle id="AddNewAdminDialog">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('Add New Admin')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="10vh">
            <IconButton color="primary" aria-label={t('upload picture')} component="label">
              <input hidden accept="image/*" type="file" />
              <Avatar
                alignItems="center"
                justifyContent="center"
                src="/broken-image.jpg"
                Alt="Profile Picture"
                sx={{ m: 4, width: 80, height: 80 }}
              />
            </IconButton>
          </Box>
          <FormProvider methods={methods}>
            <Stack spacing={3} direction={{ xs: 'column' }} width={500}>
              <TextField
                name="name"
                label={t('Admin Name')}
                onChange={(e) => setName(e.target.value)}
                value={name}
                fullWidth
                variant="outlined"
              />
              <TextField
                name="employee_id"
                margin="dense"
                label={t('Employee ID')}
                onChange={(e) => setEmployee_id(e.target.value)}
                value={employee_id}
                fullWidth
                variant="outlined"
              />
              <TextField
                name="email"
                margin="dense"
                label={t('Email address')}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                fullWidth
                variant="outlined"
              />
              <TextField
                name="password"
                label={t('Password')}
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Controller
                name="charging_station_name"
                render={({ field: { ref, onChange, ...field } }) => (
                  <Autocomplete
                    multiple
                    options={all_charging_station}
                    getOptionLabel={(option) => option.charging_station_name}
                    onChange={(_, data) => {
                      onChange(setCharging_station_id(data));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...field}
                        {...params}
                        fullWidth
                        inputRef={ref}
                        variant="outlined"
                        label={t('Charging Station(s)')}
                      />
                    )}
                  />
                )}
              />
              <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Button variant="outlined" onClick={handleClose} sx={buttonL}>
                  {t('Cancel')}
                </Button>
                <LoadingButton loading={loading} onClick={onSubmit} sx={buttonR}>
                  {t('Add')}
                </LoadingButton>
              </Stack>
            </Stack>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}

function getStyles(name, all_charging_station, theme) {
  return {
    fontWeight:
      all_charging_station.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
