import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, useTheme, styled } from '@mui/material';
// utils
import { fNumber } from '../../utils/formatNumber';
// components
import { BaseOptionChart } from '../chart';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 370;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(3),
}));

// ----------------------------------------------------------------------

AppTotalChargers.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartColors: PropTypes.arrayOf(PropTypes.string),
  chartData: PropTypes.array,
};

export default function AppTotalChargers({ title, subheader, chartData, ...other }) {
  const { t } = useTranslation();
  const theme = useTheme();

  const chartLabels = chartData.map((i) => t(i.label));

  const chartSeries = chartData.map((i) => i.value);

  const chartOptions = {
    colors: [
      theme.palette.available.main,
      theme.palette.inuse.main,
      theme.palette.faulty.main,
      theme.palette.unavailable.main,
      theme.palette.reserve.main,
    ],
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper] },
    legend: {
      fontFamily: 'Arial',
      fontWeight: 400,
      fontSize: '14px',
      offsetY: 55,
      labels: {
        colors: theme.palette.texts.main,
      },
      formatter: function (val, opts) {
        return val + ' : ' + opts.w.globals.series[opts.seriesIndex];
      },
    },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              label: t('Total Chargers'),
              fontFamily: 'Arial',
              color: theme.palette.title.main,
              fontSize: '16px',
              fontWeight: 400,
              showAlways: true,
              show: true,
            },
            value: {
              fontFamily: 'Arial',
              color: theme.palette.texts.main,
              fontSize: '34px',
              fontWeight: 700,
            },
          },
        },
      },
    },
  };

  return (
    <Card
      {...other}
      sx={{
        boxShadow: 0,
        borderRadius: '4px',
        color: theme.palette.texts.main,
      }}
    >
      <CardHeader title={title} subheader={subheader} />

      <ChartWrapperStyle dir="ltr">
        <ReactApexChart type="donut" series={chartSeries} options={chartOptions} height={280} />
      </ChartWrapperStyle>
    </Card>
  );
}
