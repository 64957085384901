import React, { useState, useEffect } from 'react';
// mui
import { Box, Container, Tab, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
// sections
import ChargingStation from './station/index';
import ChargerLocation from './location/index';
// components
import Page from 'src/components/Page';
import { fetchCoordinate } from '../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function Charger() {
  const [value, setValue] = useState('1');
  const [coordinates, setCoordinates] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    fetchCoordinate().then((promiseResult) => setCoordinates(promiseResult));
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page title={t('Charger Management')}>
      <Container maxWidth="false">
        <Typography
          sx={{
            fontFamily: 'Arial',
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '36px',
            color: 'texts.main',
            mb: 1,
          }}
        >
          {t('Charger Management')}
        </Typography>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label={t('tabs')} textColor="secondary" indicatorColor="secondary">
              <Tab
                label={t('Charging Station')}
                value="1"
                sx={{
                  fontFamily: 'Arial',
                  fontWeight: 700,
                  fontSize: '14px',
                  lineHeight: '22px',
                }}
              />
              <Tab
                label={t('Charger Location')}
                value="2"
                sx={{
                  fontFamily: 'Arial',
                  fontWeight: 700,
                  fontSize: '14px',
                  lineHeight: '22px',
                }}
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <ChargingStation />
          </TabPanel>
          <TabPanel value="2">
            <ChargerLocation coordinates={coordinates} />
          </TabPanel>
        </TabContext>
      </Container>
    </Page>
  );
}
