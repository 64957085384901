import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
// pages
import CSMSSummary from './pages/tabs/1summary/index';
import ChargerManagement from './pages/tabs/3charger/index';
import TariffManagement from './pages/tabs/4tariff';
import User from './pages/tabs/5user';
import Revenue from './pages/tabs/2revenue/index';
import Session from './pages/tabs/6session/index';
import Ocpp from './pages/tabs/7ocpp/index';
import Admin from './pages/tabs/8admin/index';
import Octt from './pages/tabs/9octt/index';
import UserProfile from './pages/accountsetting/userprofile/index';
import NotificationAlerts from './pages/accountsetting/notification&alerts/index';
import ChangePassword from './pages/accountsetting/changepassword/index';
import ChangeEmail from './pages/accountsetting/changeemail/index';
import VerificationForm from './pages/accountsetting/verificationcode/index';
import Login from './pages/auth/login/index';
import NotFound from './pages/error/Page404';
import Register from './pages/auth/register/index';
import Forgotpassword from './pages/auth/forgotpassword/index';
import Resetmessage from './pages/auth/resetmessage/index';
import Resetpassword from './pages/auth/resetpassword/index';
import RequireAuth from './pages/auth/login/RequireAuth';
import AWSLogin from './pages/auth/AWSCognito/Login/index';
import AWSSignup from './pages/auth/AWSCognito/SignUp/index';
import AWSForgotPassword from './pages/auth/AWSCognito/ForgotPassword/index';
import Success from './pages/auth/AWSCognito/successPage';
import AWSMobileLogin from './pages/auth/AWSCognito/MobileLogin/index';
import AWSMobileSignup from './pages/auth/AWSCognito/MobileLogin/SignUp/index';

// const DashboardApp = lazy(() => import('./pages/tabs/1dashboard/index'));
// const ChargerManagement = lazy(() => import('./pages/tabs/3charger/index'));
// const TariffManagement = lazy(() => import('./pages/tabs/4tariff'));
// const User = lazy(() => import('./pages/tabs/5user'));

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Login />,
    },
    {
      path: '/pages',
      element: <RequireAuth />,
      // element: <DashboardLayout />,
      children: [
        { path: 'summary', element: <CSMSSummary /> },
        { path: 'chargermanagement', element: <ChargerManagement /> },
        { path: 'tariffmanagement', element: <TariffManagement /> },
        { path: 'user', element: <User /> },
        { path: 'revenue', element: <Revenue /> },
        { path: 'sessionsummary', element: <Session /> },
        { path: 'ocpplog', element: <Ocpp /> },
        { path: 'adminmanagement', element: <Admin /> },
        { path: 'octt', element: <Octt /> },
        { path: 'userprofile', element: <UserProfile /> },
        { path: 'notification&alerts', element: <NotificationAlerts /> },
        { path: 'changepassword', element: <ChangePassword /> },
        { path: 'changeemail', element: <ChangeEmail /> },
        { path: 'verificationform', element: <VerificationForm /> },
      ],
    },
    {
      path: 'register',
      element: <Register />,
    },
    {
      path: 'forgotpassword',
      element: <Forgotpassword />,
    },
    {
      path: 'resetmessage',
      element: <Resetmessage />,
    },
    {
      path: 'resetpassword',
      element: <Resetpassword />,
    },
    {
      path: 'AWSCognitoLogin',
      element: <AWSLogin />,
    },
    {
      path: 'AWSCognitoSignUp',
      element: <AWSSignup />,
    },
    {
      path: 'AWSCognitoForgotPassword',
      element: <AWSForgotPassword />,
    },
    {
      path: 'AWSCognitoMobileLogin',
      element: <AWSMobileLogin />,
    },
    {
      path: 'AWSCognitoMobileSignUp',
      element: <AWSMobileSignup />,
    },
    {
      path: 'Success',
      element: <Success />,
    },
    // {
    //   path: '/',
    //   element: <LogoOnlyLayout />,
    //   children: [
    //     { path: '/', element: <Navigate to="/pages/dashboard" /> },
    //     { path: '404', element: <NotFound /> },
    //     { path: '*', element: <Navigate to="/404" /> },
    //   ],
    // },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
