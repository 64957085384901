import React, { useState } from 'react';
// mui
import { Box, IconButton, Menu, Tooltip } from '@mui/material';
// components
import EditLocationDialog from './EditLocationDialog';
import DeleteLocationDialog from './DeleteLocationDialog';
import Icon from '../../../../../../components/customIcon/Union.svg';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

export default function EditChargingLocation(props) {
  const { locationName } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Tooltip title={t('Edit')}>
        <IconButton onClick={handleClickOpen} sx={{ width: '38px', height: '38px', zIndex: 'modal' }}>
          <Box component="img" src={Icon} sx={{ width: '100%', height: '100%' }} />
        </IconButton>
      </Tooltip>
      {/* <Menu
        id="chargingstationmenu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <EditLocationDialog
          closeAll={handleClose}
          location={locationName}
          EditUpdate={props.Update}
          setSuccess={(childData) => props.setSuccess(childData)}
          setErrorMessage={(childData) => props.setErrorMessage(childData)}
          setError={(childData) => props.setError(childData)}
        />
        <DeleteLocationDialog
          closeAll={handleClose}
          location={locationName}
          DeleteUpdate={props.Update}
          setSuccess={(childData) => props.setSuccess(childData)}
          setErrorMessage={(childData) => props.setErrorMessage(childData)}
          setError={(childData) => props.setError(childData)}
        />
      </Menu> */}
    </>
  );
}
