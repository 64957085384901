import { useState } from 'react';

function formatDate(date) {
  let dd = date.getDate() + 1;
  let mm = date.getMonth() + 1;
  let yyyy = date.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  date = dd + '/' + mm + '/' + yyyy;
  return date;
}

const Last7Days = () => {
  const result = [];
  for (let i = 0; i < 7; i++) {
    const d = new Date();
    d.setDate(d.getDate() - i);
    result.push(formatDate(d));
  }
  const formattedDates = result.map((date) => {
    const dateObject = new Date(date.replace(/(\d+)\/(\d+)\/(\d+)/, '$2/$1/$3'));
    const day = dateObject.getUTCDate();
    const suffix = day > 3 && day < 32 ? 'th' : ['st', 'nd', 'rd'][(day % 10) - 1] || 'th';
    return day + suffix + ' ' + dateObject.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
  });
  return formattedDates.slice().reverse();
};

function Last7Months() {
  const result = [];
  for (let i = 0; i < 7; i++) {
    const d = new Date();
    d.setMonth(d.getMonth() - i);
    result.push(formatDate(d));
  }
  const formattedDates = result.map((date) => {
    const dateObject = new Date(date.replace(/(\d+)\/(\d+)\/(\d+)/, '$2/$1/$3'));
    return dateObject.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
  });
  return formattedDates.slice().reverse();
}

function Last7Years() {
  const currentYear = new Date().getFullYear();
  const lastSevenYears = Array.from({ length: 7 }, (_, index) => currentYear - index);
  const lastSevenYearsArray = lastSevenYears.map((year) => String(year));
  return lastSevenYearsArray.slice().reverse();
}

const Last7DaysID = () => {
  const result = [];
  for (let i = 0; i < 7; i++) {
    const d = new Date();
    d.setDate(d.getDate() - i);
    result.push(d.getDate());
  }
  const initial = [0, 0, 0, 0, 0, 0, 0];
  const data = result.map((day, index) => ({
    id: day,
    energy: String(initial[index]),
  }));
  return data.slice().reverse();
};

function Last7MonthsID() {
  const result = [];
  for (let i = 0; i < 7; i++) {
    const d = new Date();
    d.setMonth(d.getMonth() - i);
    result.push(d.getMonth() + 1);
  }
  const initial = [0, 0, 0, 0, 0, 0, 0];
  const data = result.map((month, index) => ({
    id: month,
    energy: String(initial[index]),
  }));
  return data.slice().reverse();
}

function Last7YearsID() {
  const currentYear = new Date().getFullYear();
  const lastSevenYears = Array.from({ length: 7 }, (_, index) => currentYear - index);
  const initial = [0, 0, 0, 0, 0, 0, 0];
  const data = lastSevenYears.map((year, index) => ({
    id: year,
    energy: String(initial[index]),
  }));
  return data.slice().reverse();
}

function test(log1, log2) {
  const initialData1 = [
    { id: 1, energy: '0' },
    { id: 2, energy: '0' },
    { id: 3, energy: '0' },
  ];

  const initialData2 = [
    { id: 3, energy: '6' },
    { id: 1, energy: '2' },
  ];

  const combinedData = [...initialData1, ...initialData2].reduce((acc, curr) => {
    const existingIndex = acc.findIndex((item) => item.id === curr.id);
    if (existingIndex !== -1) {
      acc[existingIndex] = { ...acc[existingIndex], ...curr };
    } else {
      acc.push(curr);
    }
    return acc;
  }, []);
  const data = combinedData.map(({ id, energy }) => ({ id, energy }));
  const energyValues = data.map((item) => item.energy);
  return energyValues;
}

export default { Last7Days, Last7Months, Last7Years, Last7DaysID, Last7MonthsID, Last7YearsID, test };
