// routes
import Router from './routes';
// theme
import React, { useState, useEffect } from 'react';
// import ThemeProvider1 from './pages/theme';
import ThemeProvider1 from './pages/theme/index';
// components
import ScrollToTop from './components/ScrollToTop';
import { Account } from './pages/auth/AWSCognito/Account';

// ----------------------------------------------------------------------

export const ThemeContext = React.createContext();

export default function App() {
  const [darkTheme, setDarkTheme] = useState(true);

  const toggleTheme = () => {
    setDarkTheme((prevTheme) => !prevTheme);
  };

  return (
    <ThemeContext.Provider value={{ darkTheme, toggleTheme }}>
      <ThemeProvider1>
        <Account>
          <ScrollToTop />
          <Router />
        </Account>
      </ThemeProvider1>
    </ThemeContext.Provider>
  );
}
