import * as React from 'react';
import { useEffect, useState } from 'react';
import io from 'socket.io-client';
// @mui
import { Grid, Stack, Card, CardHeader, Box } from '@mui/material';
// sections
import AppTotalChargers from '../../../../components/apexcharts/Donut';
import OverviewChart from '../../../../components/apexcharts/BarChart';
import AppWidgetSummary from '../../../../components/Widget';
import WidgetSelect from '../../../../components/Select/WidgetSelect';
import GraphSelect from '../../../../components/Select/GraphSelect';
import date from '../../../../components/LastDate';
import LoginAgain from '../../../../components/LoginAgain';
import { socketUrl } from '../../../../api/socket';
import User from '../../../../components/customIcon/icon-users-64.svg';
import Location from '../../../../components/customIcon/icon-location-64.svg';
import ChargedEnergy from '../../../../components/customIcon/icon-chargedenergy-64.svg';
import Revenue from '../../../../components/customIcon/icon-revenue-64.svg';
import ChargingSession from '../../../../components/customIcon/icon-chargesession-64.svg';
import { fetchSummaryOverview } from '../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

// const socket = io.connect(socketUrl, {
//   extraHeaders: {
//     X_My_Auth: sessionStorage.getItem('access_token'),
//   },
// });

export default function DashboardOverview(props) {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [dbdata, setDbdata] = useState([]);
  const [dataChargedEnergy, setDataChargedEnergy] = useState([]);
  const [dataRevenue, setDataRevenue] = useState([]);
  const [dataChargingSession, setDataChargingSession] = useState([]);
  const [widgetSelect, setWidgetSelect] = useState(1);
  const [graphSelect, setGraphSelect] = useState(1);
  const [graphdataOverview, setGraphDataOverview] = useState({ data: [] });
  const [TableYOverview, setTableYOverview] = useState();
  const [dataIn, setDataIn] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);
  const [chartData, setChartData] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  useEffect(() => {
    fetchSummaryOverview(setError, setErrorMessage, setUnauthorized).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        setDbdata(promiseResult.data[0]);
        setChartData(promiseResult.data[0].chart);
      } else {
        props.setError(true);
        props.setErrorMessage(promiseResult.message);
      }
    });
    setDataIn(true);
  }, []);

  // useEffect(() => {
  //   socket.on('chart_data', (data) => {
  //     setChartData(data);
  //   });
  // }, [socket]);

  const handleCallbackOverview = (childData) => {
    setWidgetSelect(childData);
  };

  useEffect(() => {
    if (widgetSelect === 2) {
      setDataChargedEnergy(dbdata.charged_energy_365);
      setDataRevenue(dbdata.revenue_365);
      setDataChargingSession(dbdata.charging_session_365);
    } else if (widgetSelect === 3) {
      setDataChargedEnergy(dbdata.charged_energy_30);
      setDataRevenue(dbdata.revenue_30);
      setDataChargingSession(dbdata.charging_session_30);
    } else if (widgetSelect === 4) {
      setDataChargedEnergy(dbdata.charged_energy_7);
      setDataRevenue(dbdata.revenue_7);
      setDataChargingSession(dbdata.charging_session_7);
    } else {
      setDataChargedEnergy(dbdata.charged_energy_overview);
      setDataRevenue(dbdata.revenue_overview);
      setDataChargingSession(dbdata.charging_session_overview);
    }
  }, [handleCallbackOverview]);

  const handleCallbackGraphOverview = (childData) => {
    setGraphSelect(childData);
  };

  useEffect(() => {
    props.LocationList(dbdata.location);
    if (graphSelect === 2) {
      setGraphDataOverview(dbdata.monthly);
      setTableYOverview(date.Last7Months);
    } else if (graphSelect === 3) {
      setGraphDataOverview(dbdata.yearly);
      setTableYOverview(date.Last7Years);
    } else {
      setGraphDataOverview(dbdata.daily);
      setTableYOverview(date.Last7Days);
    }
  }, [dataChargedEnergy, graphSelect]);

  return (
    <>
      {unauthorized && <LoginAgain />}
      {dataIn && (
        <Box>
          <Card sx={{ mb: 2, borderRadius: '4px', pl: 3, pb: 3, pr: 3, boxShadow: 0 }}>
            <Stack direction="row" justifyContent="flex-end" alignItems="center">
              <WidgetSelect parentCallback={handleCallbackOverview} datafromparent={widgetSelect} />
            </Stack>
            <Stack direction="row" justifyContent="space-around" alignItems="flex-start" spacing={3}>
              <AppWidgetSummary title={t('Total Users')} total={dbdata.user} color="available" icon={User} />
              <AppWidgetSummary
                title={t('Total Locations')}
                total={dbdata.total_location}
                color="faulty"
                icon={Location}
              />
              <AppWidgetSummary
                title={t('Total Charged Energy')}
                total={dataChargedEnergy}
                unit="kWh"
                color="inuse"
                icon={ChargedEnergy}
              />
              <AppWidgetSummary
                title={t('Total Revenue')}
                currency="SGD"
                total={dataRevenue}
                color="unavailable"
                icon={Revenue}
              />
              <AppWidgetSummary
                title={t('Total Charging Sessions')}
                total={dataChargingSession}
                color="reserve"
                icon={ChargingSession}
              />
            </Stack>
          </Card>
          <Grid container spacing={2}>
            <Grid item xs={12} md={7} lg={7}>
              <Card
                sx={{
                  boxShadow: 0,
                  borderRadius: '4px',
                  color: '#F4F4F4',
                }}
              >
                <CardHeader
                  title={t('Energy Usage')}
                  action={<GraphSelect parentCallback={handleCallbackGraphOverview} datafromparent={graphSelect} />}
                />
                {graphdataOverview && (
                  <OverviewChart yData={TableYOverview} xLabel={t('Energy (kWh)')} xData={graphdataOverview} />
                )}
              </Card>
            </Grid>
            <Grid item xs={12} md={5} lg={5}>
              {chartData && <AppTotalChargers title={t('Operational Status')} chartData={chartData} />}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
