import React, { useState, useEffect } from 'react';
// mui
import { Stack, Dialog, DialogContent, IconButton, Typography, Box, Tooltip, DialogTitle } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Icon from '../../../components/customIcon/icon-delete-turquoise-16.svg';
import { buttonR, buttonL } from '../../../components/button/buttonStyle';
import CloseIcon from '../../../components/customIcon/Close.svg';
import { SubmitAdminDeleteDialog } from '../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function DeleteAdminDialog(props) {
  const { t } = useTranslation();
  const { info } = props;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const email = info.email;

  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    setLoading(true);
    SubmitAdminDeleteDialog(email, setError, setErrorMessage, setLoading).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        props.setSuccess(true);
        props.DeleteUpdate(Math.random());
        handleClose();
      } else {
        props.setErrorMessage(promiseResult.message);
        props.setError(true);
      }
      setLoading(false);
    });
  };

  return (
    <Box>
      <Tooltip title={t('Delete')}>
        <IconButton onClick={handleClickOpen} sx={{ width: '35px', height: '35px', zIndex: 'modal' }}>
          <Box component="img" src={Icon} sx={{ width: '100%', height: '100%' }} />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} aria-labelledby="AdminDeleteDialog" sx={{ zIndex: 2000 }}>
        <DialogTitle id="AdminDelete">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('Confirm')} ?
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ pb: 2 }}>
            {t('tip_delete', { stationName: info.name })}
            {/* Are you sure you want to delete {info.name} ? */}
          </Typography>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <LoadingButton variant="outlined" onClick={handleClose} sx={buttonL}>
              {t('Back')}
            </LoadingButton>
            <LoadingButton onClick={onSubmit} loading={loading} sx={buttonR}>
              {t('Delete')}
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
