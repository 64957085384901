import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
// mui
import { TextField, MenuItem, Box, Typography, Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// import { LoadingButton } from '@mui/lab';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import DateFnsUtils from '@date-io/date-fns';
// import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
// ----------------------------------------------------------------------

export default function Default({ cRef, defaultData, setSteps, steps }) {
  const { t, i18n } = useTranslation();
  let currencyArr = ['SGD', 'TWD', 'USD', 'GBP', 'EUR'];

  const [endDateError, setEndDateError] = useState(false);
  const [errors, setErrors] = useState({
    name: '',
    currency: '',
  });
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    currency: 'SGD',
    tariff_type: null,
    tariff_text: [],
    description: '',
    start_date_time: null,
    end_date_time: null,
    language: selectedLanguage,
    // elements: [],
    min_price: {
      excl_vat: '0.00',
      incl_vat: '0.00',
    },
    max_price: {
      excl_vat: '0.00',
      incl_vat: '0.00',
    },
    reservation_show: false,
    reservationExpires_show: false,
  });
  useEffect(() => {
    if (Object.keys(defaultData) != 0) {
      setFormData(defaultData);
    }
  }, [defaultData]);

  useImperativeHandle(cRef, () => ({
    getComponentData,
  }));
  // handleSubmit
  const getComponentData = (event) => {
    // event.preventDefault();
    // not empty verify
    let hasError = false;
    let fd = formData;
    for (const key in fd) {
      const er = errors;
      if (fd.hasOwnProperty(key) && fd[key] === '' && er.hasOwnProperty(key)) {
        er[key] = t('required fields');
        setErrors({ ...errors, [key]: t('required fields') });
        hasError = true;
      }
    }
    // do not submit
    if (hasError) {
      return false;
    } else {
      return formData;
    }
  };

  const handleSwitch = (event, name, stepName) => {
    const val = event.target.checked;
    const data = formData;
    setFormData({
      ...data,
      [name]: val,
    });
    let stepsArr = steps;
    if (val) {
      if (stepsArr.indexOf('Reservation') !== -1) {
        const insertIndex = stepsArr.indexOf('Reservation') + 1;
        stepsArr.splice(insertIndex, 0, stepName);
      } else {
        const insertIndex = stepsArr.indexOf('Elements') + 1;
        stepsArr.splice(insertIndex, 0, stepName);
      }
    } else {
      const removeIndex = stepsArr.indexOf(stepName);
      if (removeIndex !== -1) {
        stepsArr.splice(removeIndex, 1);
      }
      if (stepName === 'Expires' && formData.elements) {
        let arr = formData.elements.filter((element) => element.eleType !== 'expires');
        setFormData({
          ...formData,
          reservationExpires_show: false,
          elements: arr,
        });
      }
      if (stepName === 'Reservation' && formData.elements) {
        let arr = formData.elements.filter((element) => element.eleType !== 'reservation');
        setFormData({
          ...formData,
          reservation_show: false,
          elements: arr,
        });
      }
    }
    setSteps([...stepsArr]);
  };
  return (
    <div>
      <form onSubmit={getComponentData}>
        <div>
          <span style={{ color: 'red' }}>* {t('Mandatory fields')}</span>
        </div>
        <TextField
          margin="dense"
          name="name"
          disabled={defaultData.id ? true : false}
          label={
            <>
              <span style={{ color: 'red' }}>* </span>
              {t('Name')}
            </>
          }
          value={formData.name}
          fullWidth
          size="small"
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          error={!!errors.name}
          helperText={errors.name}
        />
        <TextField
          margin="dense"
          select
          fullWidth
          name="currency"
          label={
            <>
              <span style={{ color: 'red' }}>* </span>
              {t('Currency')}
            </>
          }
          value={formData.currency}
          onChange={(e) => {
            setFormData({ ...formData, currency: e.target.value });
            setErrors({ ...errors, currency: '' });
          }}
          size="small"
          error={!!errors.currency}
          helperText={errors.currency}
        >
          {currencyArr.map((label, index) => {
            return (
              <MenuItem key={label} value={label}>
                {t(label)}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          margin="dense"
          select
          fullWidth
          name="tariff_type"
          label={t('Type')}
          value={formData.tariff_type}
          onChange={(e) => {
            setFormData({ ...formData, tariff_type: e.target.value });
            setErrors({ ...errors, tariff_type: '' });
          }}
          size="small"
        >
          <MenuItem key="AD_HOC_PAYMENT" value="AD_HOC_PAYMENT">
            AD_HOC_PAYMENT
          </MenuItem>
          <MenuItem key="REGULAR" value="REGULAR">
            REGULAR
          </MenuItem>
        </TextField>
        <TextField
          margin="dense"
          name="description"
          label={t('Description')}
          value={formData.description}
          fullWidth
          size="small"
          onChange={(e) => setFormData({ ...formData, description: e.target.value })}
        />
        {/* <TextField
          margin="dense"
          name="language"
          label={t('language')}
          value={formData.language}
          fullWidth
          size="small"
          onChange={(e) => setFormData({ ...formData, language: e.target.value })}
        /> */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            name="start_date_time"
            label={t('Start Date')}
            views={['year', 'month', 'day']}
            value={formData.start_date_time}
            onChange={(date) => setFormData({ ...formData, start_date_time: date })}
            renderInput={(params) => <TextField style={{ width: '50%' }} margin="dense" size="small" {...params} />}
          />
        </LocalizationProvider>{' '}
        {/* <div style={{ display: 'inline-block', height: '50px', lineHeight: '55px' }}>—</div> */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            name="end_date_time"
            label={t('End Date')}
            views={['year', 'month', 'day']}
            value={formData.end_date_time}
            onChange={(date) => {
              // Check if the selected end date is before the start date
              if (formData.start_date_time && date.isBefore(formData.start_date_time, 'day')) {
                // Show an error or handle the invalid date
                setEndDateError(true);
              } else {
                // Clear the error state if the selected date is valid
                setEndDateError(false);
                setFormData({ ...formData, end_date_time: date });
              }
            }}
            renderInput={(params) => (
              <TextField
                style={{ width: '49%' }}
                margin="dense"
                size="small"
                error={endDateError}
                helperText={endDateError ? t('date_before') : ''}
                {...params}
              />
            )}
          />
        </LocalizationProvider>
        <Box sx={{ width: '100%', border: '1px solid #757575', padding: '10px', marginTop: '30px' }}>
          <Typography variant="h5" sx={{ marginTop: '-25px', width: '145px', background: '#17262A' }}>
            {t('Minimum')}&nbsp;{t('Price')}
          </Typography>
          <TextField
            margin="dense"
            name="excl_vat"
            type="number"
            label={t('Price excl.Tax')}
            value={formData.min_price.excl_vat}
            fullWidth
            size="small"
            onChange={(e) =>
              setFormData({
                ...formData,
                min_price: {
                  ...formData.min_price,
                  excl_vat: e.target.value,
                },
              })
            }
          />
          <TextField
            margin="dense"
            name="incl_vat"
            type="number"
            label={t('Price incl.Tax')}
            value={formData.min_price.incl_vat}
            fullWidth
            size="small"
            onChange={(e) =>
              setFormData({
                ...formData,
                min_price: {
                  ...formData.min_price,
                  incl_vat: e.target.value,
                },
              })
            }
          />
        </Box>
        <Box sx={{ width: '100%', border: '1px solid #757575', padding: '10px', marginTop: '30px' }}>
          <Typography variant="h5" sx={{ marginTop: '-25px', width: '150px', background: '#17262A' }}>
            {t('Maximum')}&nbsp;{t('Price')}
          </Typography>
          <TextField
            margin="dense"
            name="excl_vat"
            type="number"
            label={t('Price excl.Tax')}
            value={formData.max_price.excl_vat}
            fullWidth
            size="small"
            onChange={(e) =>
              setFormData({
                ...formData,
                max_price: {
                  ...formData.max_price,
                  excl_vat: e.target.value,
                },
              })
            }
          />
          <TextField
            margin="dense"
            name="incl_vat"
            type="number"
            label={t('Price incl.Tax')}
            value={formData.max_price.incl_vat}
            fullWidth
            size="small"
            onChange={(e) =>
              setFormData({
                ...formData,
                max_price: {
                  ...formData.max_price,
                  incl_vat: e.target.value,
                },
              })
            }
          />
        </Box>
        <div style={{ padding: '10px 0 5px 0' }}>
          <span style={{ marginTop: '5px' }}>{t('Reservation')}</span>
          <Switch
            checked={formData.reservation_show}
            onChange={(event) => handleSwitch(event, event.target.name, 'Reservation')}
            name="reservation_show"
          />
        </div>
        <div style={{ padding: '10px 0 5px 0' }}>
          <span style={{ marginTop: '5px' }}>{t('Reservation Expires')}</span>
          <Switch
            checked={formData.reservationExpires_show}
            onChange={(event) => handleSwitch(event, event.target.name, 'Expires')}
            name="reservationExpires_show"
          />
        </div>
      </form>
    </div>
  );
}
