import React, { useState, useEffect, useImperativeHandle } from 'react';
import { styled, alpha, useTheme } from '@mui/material/styles';
// mui
import {
  Box,
  Alert,
  Stack,
  Table,
  Button,
  Snackbar,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  InputLabel,
  IconButton,
  FormControl,
  TableFooter,
  OutlinedInput,
  InputAdornment,
  TablePagination,
  tableCellClasses,
} from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
// components
import UserAddDialog from './UserAddDialog';
import UserRefreshButton from './UserRefreshButton';
import UserEdit from './useractions/UserEdit';
import UserReserve from './useractions/UserReserve';
import UserCancel from './useractions/UserCancel';
import UserDelete from './useractions/UserDelete';
import useAuth from '../../../hooks/useAuth';
import LoginAgain from '../../../components/LoginAgain';
import Icon from '../../../components/customIcon/icon-search-16.svg';
import Export from '../../../components/customIcon/icon-export-18.svg';
import { utils, writeFile } from 'xlsx';
import { fetchUserList, fetchCS } from '../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function UserTable() {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [user, setUser] = useState([]);
  const [update, setUpdate] = useState();
  const [chargingStationList, setChargingStationList] = useState();
  const [unauthorized, setUnauthorized] = useState(false);
  const columns = [
    { field: 'id', headerName: t('No.'), width: 40 },
    { field: 'name', headerName: t('Name'), width: 190 },
    { field: 'mobile_id', headerName: t('Mobile ID'), width: 150 },
    { field: 'rfid', headerName: t('RFID'), width: 150 },
    { field: 'status', headerName: t('Status'), width: 150 },
    { field: 'reservation', headerName: t('Reservation Status'), width: 150 },
    { field: 'action', headerName: t('Actions'), width: 200 },
  ];  
  const [mobileId, setmobileId] = useState('');
  const index = 0;

  // useEffect(() => {    
  //   // fetchUser(setErrorMessage, setError, setUnauthorized).then((promiseResult) => setUser(promiseResult));
  //   fetchUserList(JSON.stringify(data),setErrorMessage, setError).then((promiseResult) => setUser(promiseResult));
  //   fetchCS(setErrorMessage, setError, setUnauthorized).then((promiseResult) => setChargingStationList(promiseResult));
  // }, [update]);

  // Start: XF add to fix Request body does not match model schema for content type application/json: 
  // [object has missing required properties (["page_number","page_size"])] 
  useEffect(() => {     
    // fetchUser(setErrorMessage, setError, setUnauthorized).then((promiseResult) => setUser(promiseResult));
    // fetchUserList(JSON.stringify(data),setErrorMessage, setError).then((promiseResult) => {
    //   console.log("promiseResult",promiseResult.data.data);
    //   setUser(promiseResult.data.data);
    // });
    console.log("<<--- User table!!");
    update_List({ oprType: 'search' });
    // need to post to Admin management
    console.log("<<--- fetch CS")
    // fetchCS(setErrorMessage, setError, setUnauthorized).then((promiseResult) => setChargingStationList(promiseResult));
  }, [update]);
  
  const update_List = (dataType) => {
    const type = dataType.oprType;
    let data = {
      page_number: page + 1,
      page_size: rowsPerPage,
      mobile_id: mobileId,
    };
    switch (type) {
      case 'add':
        setPage(0);
        console.log("add set page=0");
        data.page_number = 1;
        setSearchWord('');
        break;
      case 'edit':
        break;
      case 'delete':
        break;
      case 'change':
        break;
      case 'search':
        console.log("search set page=0");
        setPage(0);
        data.page_number = 1;
        break;
      default:
        break;
    }
    console.log("<<update_List data:",data);
    console.log("<<update_List type:",type);
    // setLoading(true);
    console.log("<<--fetchUserList")
    fetchUserList(JSON.stringify(data), setErrorMessage, setError).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        console.log("promiseResult",promiseResult.data.data);
        setUser(promiseResult.data.data);
        // setAmount_data(promiseResult.data.total_record);
        setSuccess(true);
      } else {
        setErrorMessage(promiseResult.message);
        setError(true);
        console.log("promiseResult",promiseResult.data.data);
        setPage(0);
      }
      // setLoading(false);
    });    
  };
  // End: XF add to fix
  
  const filteredItems = getFilteredItems(query, user);
  
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setError(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value, 10);
    setPage(0);
  };

  const handleExport = () => {
    const reordered = filteredItems.map((item) => ({
      No: item.id,
      AdminName: item.name,
      MobileID: item.mobile_id,
      RFID: item.rfid,
      Status: item.status,
      ReservationStatus: item.reservation === true ? 'Yes' : 'No',
    }));
    const wb = utils.book_new(),
      ws = utils.json_to_sheet(reordered);

    utils.book_append_sheet(wb, ws, 'MySheet1');
    writeFile(wb, 'MyExcel.xlsx');
  };

  return (
    <Box sx={{ height: 660, width: '100%' }}>
      {unauthorized && <LoginAgain />}
      <Snackbar open={success} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert severity="success">{t('Success')}!</Alert>
      </Snackbar>
      <Snackbar open={error} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ pb: 1 }}>
        <FormControl fullWidth sx={{ mr: 1, width: '100%' }}>
          <InputLabel htmlFor="outlined-adornment-search">{t('Search')}</InputLabel>
          <OutlinedInput
            id="outlined-adornment-search"
            startAdornment={
              <InputAdornment position="start">
                <Box component="img" src={Icon} sx={{ width: '16px', height: '16px' }} />
              </InputAdornment>
            }
            label={t('Search')}
            onChange={(event) => {
              console.log('Input value:', event.target.value); // Print log statement
              setQuery(event.target.value);
            }}
            sx={{ height: '32px' }}
          />
        </FormControl>
        <UserAddDialog AddUpdate={(childData) => setUpdate(childData)} />
        {/* <UserRefreshButton RefreshUpdate={(childData) => setUpdate(childData)} /> */}
      </Stack>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{ color: '#F4F4F4', fontFamily: 'Arial', fontWeight: 700, fontSize: '14px', lineHeight: '22px' }}
              colSpan={2}
            >
              {t('total_User', { userlength: user.length })}
              {/* Total User: {user.length} */}
              <Button
                startIcon={<Box component="img" src={Export} sx={{ width: '16px', height: '16px', ml: 2 }} />}
                onClick={handleExport}
              >
                {t('Export')}
              </Button>
            </TableCell>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, { label: t('All'), value: -1 }]}
              count={filteredItems.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage={t('rows per page')}
              // SelectProps={{
              //   inputProps: {
              //     'aria-label': t('rows per page'),
              //   },
              //   native: true,
              // }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              // ActionsComponent={TablePaginationActions}
              ActionsComponent={(props) => <TablePaginationActions t={t} {...props} />}
              sx={{ color: '#F4F4F4', fontFamily: 'Arial', fontWeight: 400, fontSize: '14px', lineHeight: '22px' }}
            />
          </TableRow>
          <TableRow>
            {columns.map((column) => (
              <StyledTableCell align="left" sx={{ width: column.width }} key={column.headerName}>
                {column.headerName}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : filteredItems
          ).map((row,index) => (
            <StyledTableRow align="left" key={row.id} hover>
              {/* <StyledTableCell sx={sx}>{row.id}</StyledTableCell> */}
              <StyledTableCell sx={sx}>{index+1}</StyledTableCell>
              <StyledTableCell sx={sx}>{row.name}</StyledTableCell>
              <StyledTableCell sx={sx}>{row.mobile_id}</StyledTableCell>
              <StyledTableCell sx={sx}>{row.rfid && row.rfid.length > 0 &&row.rfid[0].rfid_num}</StyledTableCell>              
              <StyledTableCell sx={sx}>{row.status}</StyledTableCell>
              <StyledTableCell sx={sx}>{row.reservation === true ? t('Yes') : t('No')}</StyledTableCell>
              <StyledTableCell sx={sx}><Stack direction="row" justifyContent="left" alignItems="center" spacing={1}>
                {/* XF: comment the reservation code due to UserReserve code fail for SetChargeStation  */}
                  {/* {row.reservation === false ? (                    
                    <UserReserve                    
                      info={row}
                      // chargingStationList={chargingStationList}
                      ReserveUpdate={(childData) => setUpdate(childData)}
                      setSuccess={(childData) => setSuccess(childData)}
                      setErrorMessage={(childData) => setErrorMessage(childData)}
                      setError={(childData) => setError(childData)}
                    />
                  ) : (
                    <UserCancel
                      info={row}
                      CancelUpdate={(childData) => setUpdate(childData)}
                      setSuccess={(childData) => setSuccess(childData)}
                      setErrorMessage={(childData) => setErrorMessage(childData)}
                      setError={(childData) => setError(childData)}
                    />
                  )} */}
                  <UserEdit
                    info={row}
                    EditUpdate={(childData) => setUpdate(childData)}
                    setSuccess={(childData) => setSuccess(childData)}
                    setErrorMessage={(childData) => setErrorMessage(childData)}
                    setError={(childData) => setError(childData)}
                  />
                  {/* below code is originally commented */}
                  {/* <UserDelete key={row.name} info={row} setSuccess={(childData) => setSuccess(childData)}
                    setErrorMessage={(childData) => setErrorMessage(childData)}
                    setError={(childData) => setError(childData)} /> */}
                </Stack>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, { label: t('All'), value: -1 }]}
              count={filteredItems.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage={t('rows per page')}
              // SelectProps={{
              //   inputProps: {
              //     'aria-label': t('rows per page'),
              //   },
              //   native: true,
              // }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              // ActionsComponent={TablePaginationActions}
              ActionsComponent={(props) => <TablePaginationActions t={t} {...props} />}
              sx={{ color: '#F4F4F4', fontFamily: 'Arial', fontWeight: 400, fontSize: '14px', lineHeight: '22px' }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Box>
  );
}

function TablePaginationActions({ t, ...props }) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label={t('first page')}>
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label={t('previous page')}>
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('next page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('last page')}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const getFilteredItems = (query, user) => {
  console.log("Query=",query);
  if (!query) {
    console.log("~~~getFilteredItems,return user data!");
    return user;
  }
  const search = user.filter((user) => {
    // const name = user.name.toLowerCase().includes(query);
    // const Name = user.name.includes(query);
    const mobileId = user.mobile_id.toLowerCase().includes(query);
    const MobileId = user.mobile_id.includes(query);
    // const RFID = user.rfid.toLowerCase().includes(query); // empty RFID need to return '' instead of null
    const status = user.status.toLowerCase().includes(query);
    const Status = user.status.includes(query);
    // const Rstatus = user.reservation.toLowerCase().includes(query); // need to be in string instead of boolean
    return MobileId || mobileId || Status || status;
  });
  return search;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#17262A',
    color: '#F4F4F4',
    fontFamily: 'Arial',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '22px',
  },
  [`&.${tableCellClasses.body}`]: {
    color: '#F4F4F4',
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: alpha('#344955', 0.2),
  },
  '&:nth-of-type(even)': {
    backgroundColor: alpha('#344955', 0.2),
  },
}));

const sx = { pt: '3.5px', pb: '3.5px' };
