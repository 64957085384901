import React, { useState } from 'react';
// @mui
import { Container, Typography, Box, Tab, Snackbar, Alert } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
// components
import Page from 'src/components/Page';
// sections
import Overview from './overview/index';
import Location from './location/index';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const [value, setValue] = useState('1');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [locationList, setLocationList] = useState();
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setError(false);
  };

  return (
    <Page title={t('CSMS Summary')}>
      <Snackbar open={success} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert severity="success">{t('Success')}!</Alert>
      </Snackbar>
      <Snackbar open={error} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Container maxWidth="false">
        <Typography
          sx={{
            fontFamily: 'Arial',
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '36px',
            color: 'texts.main',
            mb: 1,
          }}
        >
          {t('CSMS Summary')}
        </Typography>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label={t('tabs')} textColor="secondary" indicatorColor="secondary">
                <Tab
                  label={t('Overview')}
                  value="1"
                  sx={{
                    fontFamily: 'Arial',
                    fontWeight: 700,
                    fontSize: '14px',
                    lineHeight: '22px',
                  }}
                />
                <Tab
                  label={t('Location Overview')}
                  value="2"
                  sx={{
                    fontFamily: 'Arial',
                    fontWeight: 700,
                    fontSize: '14px',
                    lineHeight: '22px',
                  }}
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Overview
                setSuccess={(childData) => setSuccess(childData)}
                setErrorMessage={(childData) => setErrorMessage(childData)}
                setError={(childData) => setError(childData)}
                LocationList={(childData) => setLocationList(childData)}
              />
            </TabPanel>
            <TabPanel value="2">
              {locationList && (
                <Location
                  setSuccess={(childData) => setSuccess(childData)}
                  setErrorMessage={(childData) => setErrorMessage(childData)}
                  setError={(childData) => setError(childData)}
                  locationList={locationList}
                />
              )}
            </TabPanel>
          </TabContext>
        </Box>
      </Container>
    </Page>
  );
}
