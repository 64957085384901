import React from 'react';
// mui
import { Container, Typography } from '@mui/material';
// component
import Page from '../../../components/Page';
import UserTable from './UserTable';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function UserManagementView() {
  const { t } = useTranslation();

  return (
    <Page title={t('User Management')}>
      <Container maxWidth="false">
        <Typography
          sx={{
            fontFamily: 'Arial',
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '36px',
            color: 'texts.main',
            mb: 2,
          }}
        >
          {t('User Management')}
        </Typography>
        <UserTable />
      </Container>
    </Page>
  );
}
