// mui
import { Box, Card, Container, Typography, styled } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Page from '../../../components/Page';
import Background from '../../../components/customIcon/Group 676.svg';
import Logo from '../../../components/customIcon/logo-liteon-csms-xsmall.svg';
// sections
import LoginForm from './LoginForm';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 677,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const mdUp = useResponsive('up', 'md');
  const { t } = useTranslation();

  return (
    <Page title={t('Login')}>
      <RootStyle>
        {mdUp && (
          <SectionStyle>
            <Box component="img" src={Background} sx={{ width: '100%', height: '100%', zIndex: 0 }} />
          </SectionStyle>
        )}

        <Container>
          <ContentStyle>
            <Box component="img" src={Logo} sx={{ width: '160px', height: '47px', mb: 1 }} />
            <Typography
              sx={{
                fontFamily: 'Arial',
                fontWeight: 700,
                fontSize: '24px',
                lineHeight: '36px',
                color: 'texts.main',
                mb: 1,
              }}
            >
              {t('Welcome_word')}
              {/* Welcome to LITEON's Charging Station Management System (CSMS) */}
            </Typography>
            <LoginForm />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
