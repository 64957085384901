import React, { useState, useEffect } from 'react';
// mui
import { Box, Table, Stack, TableRow, TableBody, TableCell, TableHead } from '@mui/material';
// sections
import EVSEMenu from './menu/index';
import Restart from './restart/index';
import Unlock from './unlock/index';
import Start from './start/index';
import Stop from './stop/index';
import MoveTo from './moveto/index';
import Available from '../../../../../../../components/customIcon/Available.svg';
import Faulty from '../../../../../../../components/customIcon/Faulty.svg';
import Inused from '../../../../../../../components/customIcon/Inused.svg';
import Reserved from '../../../../../../../components/customIcon/Reserve.svg';
import Unavailable from '../../../../../../../components/customIcon/Unavailable.svg';
import type2Icon from '../../../../../../../components/customIcon/icon-type-2.svg';
import { fetchL } from '../../../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function EVSETable(props) {
  const { t } = useTranslation();
  const { dataforevse, idfromdata, ogLocation } = props;
  const [locationList, setLocationList] = useState();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const columns = [
    { field: 'name', headerName: t('name_evse_id'), width: 60, align: 'left' },
    { field: 'status', headerName: t('Status'), width: 120, align: 'left' },
    { field: 'no_connector', headerName: t('No. of Connector(s)'), width: 140, align: 'left' },
    { field: 'connector_type', headerName: t('Connector Type'), width: 130, align: 'left' },
    { field: 'no_transaction', headerName: t('Total No. of Transaction(s)'), width: 160, align: 'left' },
    { field: 'rated_power', headerName: t('Rated Power (kW)'), width: 110, align: 'left' },
    { field: 'action', headerName: '', width: 50, align: 'center' },
  ];
  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  useEffect(() => {
    fetchL(setError, setErrorMessage).then((promiseResult) => setLocationList(promiseResult.chargerstation_data));
  }, []);

  return (
    <Box>
      <Table>
        <TableHead sx={{ background: '#17262A' }}>
          <TableRow>
            {columns.map((column) => (
              <TableCell align={column.align} sx={{ width: column.width }} key={column.headerName}>
                {column.headerName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataforevse?.map((evse) => (
            <TableRow key={evse.id} hover>
              <TableCell align="left">{evse.cp_id}</TableCell>
              <TableCell>{multiConnector(evse)}</TableCell>
              <TableCell>{evse.connector.length}</TableCell>
              <TableCell>
                <Stack direction="row" justifyContent="flex-start" alignItems="center">
                  {connectorIcon(evse)}
                  {multiConnectorType(evse)}
                </Stack>
              </TableCell>
              <TableCell>{evse.transaction}</TableCell>
              <TableCell>{evse.rated_power}</TableCell>
              <TableCell>
                <Stack direction="row" justifyContent="flex-end" alignItems="center">
                  <Restart
                    evseName={evse.cp_id}
                    setSuccess={(childData) => props.setSuccess(childData)}
                    setError={(childData) => props.setError(childData)}
                    setErrorMessage={(childData) => props.setErrorMessage(childData)}
                  />
                  <Unlock
                    evseName={evse.cp_id}
                    numConnector={evse.connector.length}
                    setSuccess={(childData) => props.setSuccess(childData)}
                    setError={(childData) => props.setError(childData)}
                    setErrorMessage={(childData) => props.setErrorMessage(childData)}
                  />
                  <StartStop
                    evse={evse}
                    setSuccess={(childData) => props.setSuccess(childData)}
                    setError={(childData) => props.setError(childData)}
                    setErrorMessage={(childData) => props.setErrorMessage(childData)}
                    delayUpdate={(childData) => props.delayUpdate(childData)}
                  />
                  <MoveTo
                    key={evse.cp_id}
                    old_id={idfromdata.id}
                    oglocation={ogLocation}
                    evseName={evse.cp_id}
                    locationList={locationList}
                    setSuccess={(childData) => props.setSuccess(childData)}
                    setError={(childData) => props.setError(childData)}
                    setErrorMessage={(childData) => props.setErrorMessage(childData)}
                    MoveToUpdate={(childData) => props.Update(childData)}
                    handleCloseMoveTo={(childData) => props.Close(childData)}
                  />
                  <EVSEMenu
                    evse={evse}
                    setSuccess={(childData) => props.setSuccess(childData)}
                    setError={(childData) => props.setError(childData)}
                    setErrorMessage={(childData) => props.setErrorMessage(childData)}
                    Update={(childData) => props.Update(childData)}
                    Close={(childData) => props.Close(childData)}
                  />
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

const StartStop = ({ evse, setSuccess, setError, setErrorMessage, delayUpdate }) => {
  if (evse.mobile_id !== null) {
    return (
      <Stop
        evseName={evse.cp_id}
        user_name={evse.user_name}
        mobile_id={evse.mobile_id}
        setSuccess={(childData) => setSuccess(childData)}
        setError={(childData) => setError(childData)}
        setErrorMessage={(childData) => setErrorMessage(childData)}
        StopUpdate={(childData) => delayUpdate(childData)}
      />
    );
  }
  return (
    <Start
      evseName={evse.cp_id}
      evseId={evse.id}
      numConnector={evse.connector.length}
      setSuccess={(childData) => setSuccess(childData)}
      setError={(childData) => setError(childData)}
      setErrorMessage={(childData) => setErrorMessage(childData)}
      StartUpdate={(childData) => delayUpdate(childData)}
    />
  );
};

const connectorIcon = (evse) => {
  if (evse.connector[0].connector_type === 'Type 2') {
    return <Box component="img" src={type2Icon} sx={{ width: '24px', height: '24px', mr: 1.5 }} />;
  }
};

const multiConnectorType = (evse) => {
  if (evse.connector.length === 2) {
    return (
      <>
        {evse.connector[0].connector_type}
        <br />
        {evse.connector[1].connector_type}
      </>
    );
  }
  if (evse.connector.length === 3) {
    return (
      <>
        {evse.connector[0].connector_type}
        <br />
        {evse.connector[1].connector_type}
        <br />
        {evse.connector[2].connector_type}
      </>
    );
  }
  return evse.connector[0].connector_type;
};

const multiConnector = (evse) => {
  if (evse.connector.length === 2) {
    return (
      <>
        {statusIcon(evse.connector[0].status)}
        <br />
        {statusIcon(evse.connector[1].status)}
      </>
    );
  }
  if (evse.connector.length === 3) {
    return (
      <>
        {statusIcon(evse.connector[0].status)}
        <br />
        {statusIcon(evse.connector[1].status)}
        <br />
        {statusIcon(evse.connector[2].status)}
      </>
    );
  }
  return statusIcon(evse.connector[0].status);
};

const statusIcon = (status) => {
  console.log(status);
  if (status === 'Unavailable') {
    return <Box component="img" src={Unavailable} sx={{ height: '14px' }} />;
  }
  if (status === 'Faulted') {
    return <Box component="img" src={Faulty} sx={{ height: '14px' }} />;
  }
  if (status === 'Charging') {
    return <Box component="img" src={Inused} sx={{ height: '14px' }} />;
  }
  if (status === 'Reserved') {
    return <Box component="img" src={Reserved} sx={{ height: '14px' }} />;
  }
  if (status === 'Finishing') {
    return <Box component="img" src={Inused} sx={{ height: '14px' }} />;
  }
  return <Box component="img" src={Available} sx={{ height: '14px' }} />;
};
