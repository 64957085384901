import React, { useEffect, useState } from 'react';
// mui
import { Box, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function OverviewSelect(props) {
  const { locations } = props;
  const [choice, setChoice] = useState(locations[0]);
  const { t } = useTranslation();

  useEffect(() => {
    props.parentCallback(choice);
  }, [choice]);

  const handleChange = (event) => {
    setChoice(event.target.value);
  };

  return (
    <Box>
      <FormControl sx={{ minWidth: 120, width: 'auto', mb: 2, mt: 2, mr: 2 }} size="small">
        <InputLabel id="location-select-label">{t('Location')}</InputLabel>
        <Select
          labelId="overview-select-label"
          id="overview-select"
          value={choice}
          label={t('Location')}
          onChange={handleChange}
        >
          {locations.map((location) => (
            <MenuItem value={location} key={location}>
              {location}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
