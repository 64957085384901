import React, { useState, useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
// mui
import {
  Box,
  Stack,
  Button,
  Dialog,
  Select,
  Tooltip,
  MenuItem,
  TextField,
  IconButton,
  InputLabel,
  Typography,
  FormControl,
  DialogTitle,
  Autocomplete,
  DialogContent,
  FormHelperText,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../../../../components/Iconify';
import Icon from '../../../../../../components/customIcon/icon-add-24.svg';
import { buttonR, buttonL } from '../../../../../../components/button/buttonStyle';
import CloseIcon from '../../../../../../components/customIcon/Close.svg';
import { SubmitAddCL } from '../../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------
//const facilities = [];
export default function AddNewChargingStation(props) {
  const { t } = useTranslation();
  const { location, url } = props;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [postal_code, setPostalCode] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [parking_type, setparking_style] = useState('');
  const [facilities, setfacilities] = useState([]);
  const [startTime, setStartTime] = useState('');
  const [stopTime, setStopTime] = useState('');
  const [customTime, setCustomTime] = useState(false);
  const [operatingHours, setOperatingHours] = useState('');
  const [choice, setChoice] = useState('');

  const facilitiesArr = [
    { label: t('HOTEL'), value: 'HOTEL' },
    { label: t('RESTAURANT'), value: 'RESTAURANT' },
    { label: t('CAFE'), value: 'CAFE' },
    { label: t('MALL'), value: 'MALL' },
    { label: t('SUPERMARKET'), value: 'SUPERMARKET' },
    { label: t('SPORT'), value: 'SPORT' },
    { label: t('RECREATION') + '_' + t('AREA'), value: 'RECREATION_AREA' },
    { label: t('NATURE'), value: 'NATURE' },
    { label: t('MUSEUM'), value: 'MUSEUM' },
    { label: t('BIKE') + '_' + t('SHARING'), value: 'BIKE_SHARING' },
    { label: t('BUS') + '_' + t('STOP'), value: 'BUS_STOP' },
    { label: t('TAXI') + '_' + t('STAND'), value: 'TAXI_STAND' },
    { label: t('TRAM') + '_' + t('STOP'), value: 'TRAM_STOP' },
    { label: t('METRO') + '_' + t('STATION'), value: 'METRO_STATION' },
    { label: t('TRAIN') + '_' + t('STATION'), value: 'TRAIN_STATION' },
    { label: t('AIRPORT'), value: 'AIRPORT' },
    { label: t('PARKING') + '_' + t('LOT'), value: 'PARKING_LOT' },
    { label: t('CARPOOL') + '_' + t('PARKING'), value: 'CARPOOL_PARKING' },
    { label: t('FUEL') + '_' + t('STATION'), value: 'FUEL_STATION' },
    { label: t('WIFI'), value: 'WIFI' },
  ];

  const parkingTypeArr = [
    { label: t('ALONG') + '_' + t('MOTORWAY'), value: 'ALONG_MOTORWAY' },
    { label: t('PARKING') + '_' + t('GARAGE'), value: 'PARKING_GARAGE' },
    { label: t('PARKING') + '_' + t('LOT'), value: 'PARKING_LOT' },
    { label: t('ON') + '_' + t('DRIVEWAY'), value: 'ON_DRIVEWAY' },
    { label: t('ON') + '_' + t('STREET'), value: 'ON_STREET' },
    { label: t('UNDERSROUNG') + '_' + t('GARAGE'), value: 'UNDERGROUND_GARAGE' },
  ];

  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  const handleChange = (event) => {
    // handleChange_after(event)

    if (event.target.value === 'Custom') {
      setCustomTime(true);
      const data = formData;
      data.startTime = '';
      data.stopTime = '';
      setFormData({ ...data });

      const error = errors;
      error.startTime = '';
      error.stopTime = '';
      setErrors({ ...error });
      setStartTime('');

      // setFormData({ ...formData, 'stopTime': '' });
      setStopTime('');
      // setErrors({ ...errors, 'stopTime': '' });

      setOperatingHours('');
    } else {
      setOperatingHours(event.target.value);
      setCustomTime(false);
    }
    setErrors({ ...errors, choice: '' });
    setFormData({ ...formData, choice: event.target.value });
    setChoice(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [formData, setFormData] = useState({
    name: '',
    address: '',
    city: '',
    country: '',
    postal_code: '',
    parking_type: '',
    facilities:[],
    choice: '',
    startTime: '',
    stopTime: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    address: '',
    city: '',
    country: '',
    postal_code: '',
    parking_type: '',
    facilities:[],
    choice: '',
    startTime: '',
    stopTime: '',
  });
  const handleChange_after = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const onSubmit = () => {
    setLoading(true);
    // Not empty verify
    let hasError = false;
    for (const key in formData) {
      const er = errors;
      if (formData.hasOwnProperty(key) && formData[key] === '') {
        if (!customTime && key === 'startTime') {
          er[key] = '';
        } else if (!customTime && key === 'stopTime') {
          er[key] = '';
        } else {
          setErrors({ ...errors, [key]: t('required fields') });
          er[key] = t('required fields');
          hasError = true;
        }
      }
    }
    //  do not submit
    if (hasError) {
      setLoading(false);
      return;
    }

    setLoading(false);
    SubmitAddCL(
      location,
      name,
      address,
      city,
      country,
      postal_code,
      parking_type,
      facilities,
      operatingHours,
      startTime,
      stopTime,
      setError,
      setErrorMessage
    ).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        props.AddCSUpdate(Math.random());
        props.setSuccess(true);
        setAddress('');
        setCity('');
        setCountry('');
        setPostalCode('');
        setparking_style('');
        setfacilities([]);
        handleClose();
        setName('');
      } else {
        props.setErrorMessage(promiseResult.message);
        props.setError(true);
      }
      setLoading(false);
    });
  };

  return (
    <Box>
      <Tooltip title={t('Add')}>
        <IconButton onClick={handleClickOpen} sx={{ width: '38px', height: '38px', zIndex: 'modal' }}>
          <Box component="img" src={Icon} sx={{ width: '100%', height: '100%' }} />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} aria-labelledby="AddNewUserDialog">
        <DialogTitle id="AddNewChargingStation">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('btn_addNewStation')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" justifyContent="flex-start" spacing={2} sx={{ width: 450 }}>
            <TextField
              margin="dense"
              name="name"
              error={!!errors.name}
              helperText={errors.name}
              label={
                <>
                  <span style={{ color: 'red' }}>* </span>
                  {t('name_station')}
                </>
              }
              onChange={(e) => {
                setName(e.target.value);
                handleChange_after(e);
              }}
              value={name}
              fullWidth
              variant="outlined"
            />
            <TextField
              margin="dense"
              name="address"
              label={
                <>
                  <span style={{ color: 'red' }}>* </span>
                  {t('Address')}
                </>
              }
              error={!!errors.address}
              helperText={errors.address}
              onChange={(e) => {
                setAddress(e.target.value);
                handleChange_after(e);
              }}
              value={address}
              fullWidth
              variant="outlined"
            />
            <TextField
              margin="dense"
              name="city"
              label={
                <>
                  <span style={{ color: 'red' }}>* </span>
                  {t('City')}
                </>
              }
              error={!!errors.city}
              helperText={errors.city}
              onChange={(e) => {
                setCity(e.target.value);
                handleChange_after(e);
              }}
              value={city}
              fullWidth
              variant="outlined"
            />
            <TextField
              margin="dense"
              name="country"
              label={
                <>
                  <span style={{ color: 'red' }}>* </span>
                  {t('Country')}
                </>
              }
              error={!!errors.country}
              helperText={errors.country}
              onChange={(e) => {
                setCountry(e.target.value);
                handleChange_after(e);
              }}
              value={country}
              fullWidth
              variant="outlined"
            />
            <TextField
              margin="dense"
              name="postal_code"
              label={
                <>
                  <span style={{ color: 'red' }}>* </span>
                  {t('Postal Code')}
                </>
              }
              error={!!errors.postal_code}
              helperText={errors.postal_code}
              onChange={(e) => {
                setPostalCode(e.target.value);
                handleChange_after(e);
              }}
              value={postal_code}
              fullWidth
              variant="outlined"
            />
            <TextField
              margin="dense"
              select
              fullWidth
              name="parking_type"
              label={
                <>
                  <span style={{ color: 'red' }}>* </span>
                  {t('Parking Type')}
                </>
              }
              error={!!errors.parking_type}
              helperText={errors.parking_type}
              onChange={(e) => {
                setparking_style(e.target.value);
                handleChange_after(e);
              }}
              value={parking_type}
              variant="outlined"
              size="small"
            >
              {parkingTypeArr.map((data, index) => {
                return (
                  <MenuItem key={data.label} value={data.value}>
                    {data.label}
                  </MenuItem>
                );
              })}
            </TextField>

            <TextField
              margin="dense"
              select
              fullWidth
              name="facilities"
              label={
                <>
                  <span style={{ color: 'red' }}>* </span>
                  {t('Facilities')}
                </>
              }
              error={!!errors.facilities}
              helperText={errors.facilities}
              onChange={(e) => {
                setfacilities([e.target.value]);
                handleChange_after(e);
              }}
              value={facilities}
              variant="outlined"
              size="small"
            >
              {facilitiesArr.map((data, index) => {
                return (
                  <MenuItem key={data.label} value={data.value}>
                    {data.label}
                  </MenuItem>
                );
              })}
            </TextField>

            
            {/* <FormControl fullWidth error={!!errors.choice}>
              <InputLabel id="facilities-label">{t('facilities')}</InputLabel>
              <Select
                name="choice" //operatingHours
                labelId="facilities-label"
                id="facilities"
                value={choice}
                label={t('facilities')}
                onChange={handleChange}
              >
                <MenuItem value={'HOTEL'}>{t('HOTEL')}</MenuItem>
                <MenuItem value={'MALL'}>{t('MALL')}</MenuItem>
              </Select>
              <FormHelperText>{errors.choice}</FormHelperText>
            </FormControl> */}
            <FormControl fullWidth error={!!errors.choice}>
              <InputLabel id="operating-hours-select-label">{t('Operating hours')}</InputLabel>
              <Select
                name="choice" //operatingHours
                labelId="operating-hours-select-label"
                id="operating-hours"
                value={choice}
                label={
                  <>
                    <span style={{ color: 'red' }}>* </span>
                    {t('Operating Hours')}
                  </>
                }
                onChange={handleChange}
              >
                <MenuItem value={'24'}>24 {t('hours')}</MenuItem>
                <MenuItem value={'Custom'}>{t('Custom')}</MenuItem>
              </Select>
              <FormHelperText>{errors.choice}</FormHelperText>
            </FormControl>
            {customTime ? (
              <Stack direction="row" alignItems="center" justifyContent="center">
                <Autocomplete
                  fullWidth
                  id="combo-box-demo"
                  options={timing}
                  onChange={(event, newValue) => {
                    // handleChange_after(event)
                    setFormData({ ...formData, startTime: newValue });
                    setErrors({ ...errors, startTime: '' });
                    setStartTime(newValue);
                  }}
                  getOptionLabel={(timing) => timing.label}
                  renderInput={(params) => (
                    <TextField
                      // name="startTime"
                      error={!!errors.startTime}
                      helperText={errors.startTime}
                      value={startTime}
                      {...params}
                      label={t('Timing')}
                    />
                  )}
                />
                <Typography variant="h6" sx={{ p: 1 }}>
                  {t('to')}
                </Typography>
                <Autocomplete
                  fullWidth
                  id="combo-box-demo"
                  options={timing}
                  onChange={(event, newValue) => {
                    // handleChange_after(event, newValue.label)
                    setFormData({ ...formData, stopTime: newValue });
                    setStopTime(newValue);
                    setErrors({ ...errors, stopTime: '' });
                  }}
                  getOptionLabel={(timing) => timing.label}
                  renderInput={(params) => (
                    <TextField
                      // name="stopTime"
                      error={!!errors.stopTime}
                      helperText={errors.stopTime}
                      value={stopTime}
                      {...params}
                      label={t('Timing')}
                    />
                  )}
                />
              </Stack>
            ) : (
              ''
            )}
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
              <TextField
                disabled
                margin="dense"
                name="url"
                label={t('URL_station')} //"URL for Connection of Charging Station"
                value={url}
                fullWidth
                variant="outlined"
              />

              <CopyToClipboard text={url} onCopy={() => setCopied(true)}>
                <Tooltip title={t('Copy')}>
                  <Button variant="outlined" sx={{ height: 52, borderColor: '#00B0B9', color: '#00B0B9' }}>
                    <Iconify icon="material-symbols:content-copy-rounded" width={22} height={22} />
                  </Button>
                </Tooltip>
              </CopyToClipboard>
            </Stack>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
              <LoadingButton variant="outlined" onClick={handleClose} sx={buttonL}>
                {t('Cancel')}
              </LoadingButton>
              <LoadingButton type="submit" onClick={onSubmit} loading={loading} sx={buttonR}>
                {t('Add')}
              </LoadingButton>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

const timing = [
  { label: '12:00 AM' },
  { label: '12:30 AM' },
  { label: '1:00 AM' },
  { label: '1:30 AM' },
  { label: '2:00 AM' },
  { label: '2:30 AM' },
  { label: '3:00 AM' },
  { label: '3:30 AM' },
  { label: '4:00 AM' },
  { label: '4:30 AM' },
  { label: '5:00 AM' },
  { label: '5:30 AM' },
  { label: '6:00 AM' },
  { label: '6:30 AM' },
  { label: '7:00 AM' },
  { label: '7:30 AM' },
  { label: '8:00 AM' },
  { label: '8:30 AM' },
  { label: '9:00 AM' },
  { label: '9:30 AM' },
  { label: '10:00 AM' },
  { label: '10:30 AM' },
  { label: '11:00 AM' },
  { label: '11:30 AM' },
  { label: '12:00 PM' },
  { label: '12:30 PM' },
  { label: '1:00 PM' },
  { label: '1:30 PM' },
  { label: '2:00 PM' },
  { label: '2:30 PM' },
  { label: '3:00 PM' },
  { label: '3:30 PM' },
  { label: '4:00 PM' },
  { label: '4:30 PM' },
  { label: '5:00 PM' },
  { label: '5:30 PM' },
  { label: '6:00 PM' },
  { label: '6:30 PM' },
  { label: '7:00 PM' },
  { label: '7:30 PM' },
  { label: '8:00 PM' },
  { label: '8:30 PM' },
  { label: '9:00 PM' },
  { label: '9:30 PM' },
  { label: '10:00 PM' },
  { label: '10:30 PM' },
  { label: '11:00 PM' },
  { label: '11:30 PM' },
];
