import { useLoadScript } from '@react-google-maps/api';
import Map from './map';
import { useTranslation } from 'react-i18next';

export default function Home(props) {
  const { coordinates } = props;
  const { t } = useTranslation();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyA-JUWwCMuTnCAQWPBMT74cxiGW2d0P1Fk',
    libraries: ['places'],
  });

  if (!isLoaded) return <div>{t('Loading')}...</div>;
  return <Map coordinates={coordinates} />;
}
