import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, styled, Typography, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { SubmitForgetPassword } from '../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const { t } = useTranslation();

  const onSubmit = () => {
    SubmitForgetPassword(email, setErrMsg).then((promiseResult) => {
      if (promiseResult.status === 401) {
        setErrMsg(promiseResult.message);
      } else {
        setEmail('');
        navigate('/resetmessage', { replace: true });
      }
    });
  };

  const handleClick = async () => {
    navigate('/', { replace: true });
  };

  return (
    <ContentStyle>
      <Stack spacing={3}>
        <Typography variant="subtitle1" sx={{ pl: 1, color: 'error.main' }}>
          {errMsg}
        </Typography>
        <TextField name="email" label={t('Email address')} onChange={(e) => setEmail(e.target.value)} value={email} />
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <LoadingButton
            fullWidth
            size="large"
            variant="outlined"
            onClick={handleClick}
            sx={{
              boxShadow: 0,
              color: '#00B0B9',
              textAlign: 'center',
            }}
          >
            {t('Back')}
          </LoadingButton>
          <LoadingButton
            fullWidth
            size="large"
            variant="contained"
            onClick={onSubmit}
            sx={{
              background: 'linear-gradient(to left, #66D0D5, #00B0B9)',
              boxShadow: 0,
              color: '#1E1E1E',
              textAlign: 'center',
            }}
          >
            {t('Send')}
          </LoadingButton>
        </Stack>
      </Stack>
    </ContentStyle>
  );
}

const ContentStyle = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
}));
