import { useState, useEffect, useContext } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, Typography, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../../components/Iconify';
import { FormProvider } from '../../../../components/hook-form';
import { AccountContext } from '../Account';
// Cognito
import { Auth } from 'aws-amplify';
import UserPool from '../UserPool';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

let socket1;

export default function LoginForm({ setPage, setUsername }) {
  const navigate = useNavigate();
  const success = '/Success';
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [firstLogin, setFirstLogin] = useState(false);
  const [newPassword, setNewPassword] = useState();
  const [showPassword2, setShowPassword2] = useState(false);

  const { authenticate } = useContext(AccountContext);
  const { t } = useTranslation();

  useEffect(() => {
    setErrMsg('');
  }, [email, password]);

  const onSubmit = (event) => {
    event.preventDefault();
    authenticate(email, password, newPassword, setPage, setUsername, setErrMsg, setFirstLogin, navigate, success)
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <FormProvider>
      <Stack spacing={3}>
        <Typography variant="subtitle1" sx={{ pl: 1, color: 'error.main' }}>
          {errMsg}
        </Typography>
        <TextField name="email" label={t('Email address')} onChange={(e) => setEmail(e.target.value)} value={email} />
        <TextField
          name="password"
          label={t('Password')}
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {firstLogin && (
          <TextField
            name="password"
            label={t('Password')}
            onChange={(e) => setNewPassword(e.target.value)}
            value={newPassword}
            type={showPassword2 ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword2(!showPassword2)} edge="end">
                    <Iconify icon={showPassword2 ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link variant="subtitle2" underline="hover" component={RouterLink} to="/AWSCognitoSignUp">
          {t('Sign Up')}?
        </Link>
        <Link variant="subtitle2" underline="hover" component={RouterLink} to="/AWSCognitoForgotPassword">
          {t('Forget Password')}?
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        // type="submit"
        variant="contained"
        onClick={onSubmit}
        sx={{ background: 'linear-gradient(to left, #66D0D5, #00B0B9)' }}
      >
        {t('Login')}
      </LoadingButton>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link variant="subtitle2" underline="hover" component={RouterLink} to="/">
          {t('Back')}
        </Link>
        <Link variant="subtitle2" underline="hover" component={RouterLink} to="/AWSCognitoMobileLogin">
          {t('Mobile Login')}
        </Link>
      </Stack>
    </FormProvider>
  );
}
export { socket1 };
