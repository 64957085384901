import React, { useState, useEffect } from 'react';
// mui
import { Box, Card, Stack, Collapse, IconButton, Typography } from '@mui/material';
// components
import ChargingStation from './CS/index';
import StationChargingLocationAddDialog from './addbutton/index';
import StationChargingLocationEditDialog from './editbutton/index';
import LoginAgain from '../../../../../components/LoginAgain';
import down from '../../../../../components/customIcon/icon-expandedarrow-24.svg';
import right from '../../../../../components/customIcon/icon-collapsedarrow-24.svg';
import { fetchChargerStation } from '../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

export default function LocationTable(props) {
  const [dbdata, setDbdata] = useState([]);
  const [update, setUpdate] = useState();
  const { t } = useTranslation();
  const [updated, setUpdated] = useState();
  const [unauthorized, setUnauthorized] = useState(false);
  const [Close, setClose] = useState('');
  const [url, setUrl] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  const delayUpdate = (childData) => {
    setTimeout(() => {
      setUpdate(childData);
    }, 2000);
  };

  useEffect(() => {
    fetchChargerStation(setError, setErrorMessage, setUnauthorized).then((promiseResult) => {
      setDbdata(promiseResult.chargerstation_data);
      setUrl(promiseResult.url);
    });
    setUpdated(Math.random());
    setClose(Math.random());
  }, [props.LocationUpdate, update]);

  return (
    <Box>
      {unauthorized && <LoginAgain />}
      {dbdata.map((location) => (
        <LocationRow
          key={location.id}
          url={url}
          location={location}
          updated={updated}
          setSuccess={(childData) => props.setSuccess(childData)}
          setErrorMessage={(childData) => props.setErrorMessage(childData)}
          setError={(childData) => props.setError(childData)}
          Update={(childData) => setUpdate(childData)}
          delayUpdate={(childData) => delayUpdate(childData)}
          Close={Close}
        />
      ))}
    </Box>
  );
}

function LocationRow(props) {
  const { location, updated, Close, url } = props;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    setOpen(false);
  }, [Close]);

  const handleButton = () => {
    if (sessionStorage.getItem('role') === 'SuperAdmin') {
      return (
        <StationChargingLocationAddDialog
          location={location.name}
          AddCSUpdate={(childData) => props.delayUpdate(childData)}
          url={url}
          setSuccess={(childData) => props.setSuccess(childData)}
          setErrorMessage={(childData) => props.setErrorMessage(childData)}
          setError={(childData) => props.setError(childData)}
        />
      );
    }
    return;
  };

  return (
    <Card
      sx={{
        boxShadow: 0,
        mb: 1,
        color: '#17262A',
        p: 1,
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
          <IconButton aria-label={t('expand row')} size="small" onClick={() => setOpen(!open)}>
            {open ? (
              <Box component="img" src={down} sx={{ width: '30px', height: '30px' }} />
            ) : (
              <Box component="img" src={right} sx={{ width: '30px', height: '30px' }} />
            )}
          </IconButton>
          <Typography
            sx={{
              fontFamily: 'Arial',
              fontWeight: 700,
              fontSize: '14px',
              lineHeight: '22px',
              color: 'texts.main',
            }}
          >
            {location.name}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          {handleButton()}
          <StationChargingLocationEditDialog
            locationName={location.name}
            Update={(childData) => props.delayUpdate(childData)}
            setSuccess={(childData) => props.setSuccess(childData)}
            setErrorMessage={(childData) => props.setErrorMessage(childData)}
            setError={(childData) => props.setError(childData)}
          />
        </Stack>
      </Stack>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ margin: 1 }}>
          <ChargingStation
            locationName={location.name}
            dataforstation={location.station}
            url={url}
            Close={(childData) => setOpen(childData)}
            setSuccess={(childData) => props.setSuccess(childData)}
            setErrorMessage={(childData) => props.setErrorMessage(childData)}
            setError={(childData) => props.setError(childData)}
            updated={updated}
            delayUpdate={(childData) => props.delayUpdate(childData)}
            Update={(childData) => props.Update(childData)}
          />
        </Box>
      </Collapse>
    </Card>
  );
}
