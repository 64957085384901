import React, { useState, useEffect, useRef } from 'react';
// mui
import {
  Box,
  Step,
  Stack,
  Dialog,
  Stepper,
  MenuItem,
  Button,
  StepLabel,
  IconButton,
  DialogTitle,
  DialogContent,
  Snackbar,
  Alert,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Default from './Default';
import Elements from './Elements/index';
import Reservation from './Reservation/index';
import Expires from './Expires/index';
import Confirmation from './Confirmation/index';
import { buttonR, buttonL } from '../../../../components/button/buttonStyle';
// import CloseIcon from '../../../../../../components/customIcon/Close.svg';
import CloseIcon from '../../../../components/customIcon/Close.svg';
import { addTariffFunction, editTariffFunction } from '../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';
import LoginAgain from '../../../../components/LoginAgain';
// import MuiAlert from '@mui/lab/Alert';

// ----------------------------------------------------------------------

// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

export default function editTariff_index({ operation, propsData, setOpenEdit, updateList }) {
  const { t, i18n } = useTranslation();
  const childRef_default = useRef();
  const childRef_elements = useRef();
  const childRef_reservation = useRef();
  const childRef_expires = useRef();
  // const { operation, propsData, setOpenEdit, updateList } = props;

  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [defaultData, setDefaultData] = useState({});
  // const steps = ['Select EVSE', 'Change Availability', 'Confirmation'];
  const [removeId, setRemoveId] = useState([]);
  const [steps, setSteps] = useState(['Default', 'Elements', 'Confirmation']);
  const [openAlert, setOpenAlert] = useState(false);

  const [unauthorized, setUnauthorized] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCloseSnackbar_fun = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setError(false);
  };

  useEffect(() => {
    if (operation && operation == 'edit') {
      handleClickOpen();
      if (propsData.reservationExpires_show && propsData.reservation_show) {
        setSteps(['Default', 'Elements', 'Reservation', 'Expires', 'Confirmation']);
      } else if (propsData.reservationExpires_show && !propsData.reservation_show) {
        setSteps(['Default', 'Elements', 'Expires', 'Confirmation']);
      } else if (!propsData.reservationExpires_show && propsData.reservation_show) {
        setSteps(['Default', 'Elements', 'Reservation', 'Confirmation']);
      }
      setDefaultData(propsData);
    } else if (operation && operation == 'add') {
      setDefaultData({});
      setPage(0);
      setSteps(['Default', 'Elements', 'Confirmation']);
    }
  }, [operation]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDefaultData({});
    setPage(0);
    setSteps(['Default', 'Elements', 'Confirmation']);
    if (operation && operation == 'edit') {
      setOpenEdit(false);
    }
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };
  const formatDate = (date1) => {
    const date = new Date(date1);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };
  const formatData = () => {
    console.log('startData', defaultData);
    console.log('startData————', JSON.stringify(defaultData));
    const transData = {};
    let eleData = [];
    defaultData.language = selectedLanguage; // set language if you need

    defaultData.tariff_type == '' ? (transData.tariff_type = null) : defaultData.tariff_type;
    const dataArr = Object.keys(defaultData);
    for (let index = 0; index < dataArr.length; index++) {
      const element = dataArr[index];
      if (element !== 'elements') {
        transData[element] = defaultData[element];
      } else {
        eleData = defaultData[element];
      }
    }

    console.log('transdata element after loop: ', transData)
    console.log("eleData is", eleData)
    transData.min_price_excl_vat = defaultData.min_price.excl_vat ? defaultData.min_price.excl_vat : null;
    transData.min_price_incl_vat = defaultData.min_price.incl_vat ? defaultData.min_price.incl_vat : null;
    transData.max_price_excl_vat = defaultData.max_price.excl_vat ? defaultData.max_price.excl_vat : null;
    transData.max_price_incl_vat = defaultData.max_price.incl_vat ? defaultData.max_price.incl_vat : null;
    // const arrTariff_text = defaultData.tariff_text;
    defaultData.end_date_time
      ? (transData.end_date_time = formatDate(defaultData.end_date_time))
      : (transData.end_date_time = null);
    defaultData.start_date_time
      ? (transData.start_date_time = formatDate(defaultData.start_date_time))
      : (transData.start_date_time = null);

    if (defaultData.id == '' || defaultData.tariff_text.length == 0) {
      transData.tariff_text = [
        { description: defaultData.description ? defaultData.description : null, language: selectedLanguage },
      ];
      // transData.text = defaultData.description ? defaultData.description : null;
      // transData.language = selectedLanguage;
    } else {
      transData.tariff_text = [{ description: defaultData.description, language: selectedLanguage }];
      // let hasLan = false;
      // for (let index = 0; index < arrTariff_text.length; index++) {
      //   const element = arrTariff_text[index];
      //   if (element.language === selectedLanguage) {
      //     hasLan = true;
      //     element.description = defaultData.description;
      //   }
      // }
      // if (!hasLan) {
      //   defaultData.tariff_text.push({ description: defaultData.description, language: selectedLanguage });
      // }
    }
    console.log('eleData before processing: ', eleData)
    let transElesArrData = [];
    for (let index = 0; index < eleData.length; index++) {
      const ele = eleData[index];
      if(!ele.price_component){
        continue;
      }
      let obj = {};
      obj = { ...obj, ...ele.price_component[0] };
      obj.vat = Number(obj.vat);
      obj.id = ele.id;
      if (ele.restrictions) {
        let objArr = Object.keys(ele.restrictions);
        for (let ind = 0; ind < objArr.length; ind++) {
          ele.restrictions[objArr[ind]] ? ele.restrictions[objArr[ind]] : (ele.restrictions[objArr[ind]] = null);
        }
        // debugger;   have Reservation && no day_of_week
        ele.restrictions.days = ele.restrictions.day_of_week;
        ele.restrictions.start_date
          ? (ele.restrictions.start_date = formatDate(ele.restrictions.start_date))
          : (ele.restrictions.start_date = null);
        ele.restrictions.end_date
          ? (ele.restrictions.end_date = formatDate(ele.restrictions.end_date))
          : (ele.restrictions.end_date = null);
        obj = { ...obj, ...ele.restrictions };
      }
      transElesArrData.push(obj);
    }
    transData.elements = transElesArrData;
    removeId.length != 0 ? (transData.remove_id = removeId) : null;
    console.log('transData', transData);
    console.log('transData', JSON.stringify(transData));
    return transData;
  };
  const onSubmit = () => {
    const data = formatData();
    setLoading(true);
    if (defaultData.id == '') {
      data.id = Math.random().toString(36).substr(2, 9);
      addTariffFunction(JSON.stringify(data), setError, setErrorMessage).then((promiseResult) => {
        if (promiseResult.category === 'Success') {
          setSuccess(true);
          setOpen(false);
          handleClose();
          updateList({ oprType: 'add' });
          setLoading(false);
          //clear word
          debugger;
        } else {
          debugger;
          setError(true);
          setErrorMessage(promiseResult.message);
          setLoading(false);
        }
      });
    } else {
      editTariffFunction(JSON.stringify(data), setError, setErrorMessage).then((promiseResult) => {
        if (promiseResult.category === 'Success') {
          setSuccess(true);
          setOpen(false);
          handleClose();
          updateList({ oprType: 'edit' });
          setLoading(false);
        } else {
          debugger;
          setError(true);
          setErrorMessage(promiseResult.message);
          setLoading(false);
        }
      });
    }
  };

  const PageDisplay = () => {
    let stepsArr = steps;
    switch (stepsArr[page]) {
      case 'Default':
        return <Default cRef={childRef_default} defaultData={defaultData} setSteps={setSteps} steps={steps} />;
        break;
      case 'Elements':
        return (
          <Elements removeId={removeId} setRemoveId={setRemoveId} cRef={childRef_elements} defaultData={defaultData} />
        );
        break;
      case 'Reservation':
        return (
          <Reservation
            removeId={removeId}
            setRemoveId={setRemoveId}
            cRef={childRef_reservation}
            defaultData={defaultData}
          />
        );
      case 'Expires':
        return (
          <Expires removeId={removeId} setRemoveId={setRemoveId} cRef={childRef_expires} defaultData={defaultData} />
        );
      case 'Confirmation':
        return <Confirmation defaultData={defaultData} />;
      default:
        break;
    }
  };
  const nextClick = () => {
    let stepsArr = steps;
    switch (stepsArr[page]) {
      case 'Default':
        getFormData(childRef_default, 'default');
        break;
      case 'Elements':
        getFormData(childRef_elements, 'element');
        break;
      case 'Reservation':
        getFormData(childRef_reservation, 'reservation');
      case 'Expires':
        getFormData(childRef_expires, 'expires');
      case 'Confirmation':
        return <Confirmation />;
      default:
        break;
    }
  };
  // the same time have at lest two same type data && no setting  is no allowed
  const isValidArray = (arr) => {
    const typeGroups = {};

    arr.forEach((item) => {
      const type = item.price_component[0].type;
      if (!typeGroups[type]) {
        typeGroups[type] = [];
      }
      typeGroups[type].push(item);
    });
    let groups = Object.keys(typeGroups);
    let results = false; // right

    for (let index = 0; index < groups.length; index++) {
      if (typeGroups[groups[index]].length > 1) {
        for (let j = 0; j < typeGroups[groups[index]].length; j++) {
          const element = typeGroups[groups[index]][j];
          if (element.restrictions_show) {
            results = false; // right
            break;
          } else {
            results = true; //error
          }
        }
      }
    }
    return results;
  };

  const getFormData = (childRef, name) => {
    if (childRef.current == null) {
      console.log('childRef=null');
      return;
    }
    let hasError = true;
    let verifyData = childRef.current.getComponentData();
    if (verifyData == false) {
      return;
    }
    if (name === 'default') {
      hasError = false;
      setDefaultData(verifyData);
    }
    if (name === 'element') {
      let data = defaultData.elements;
      if (data) {
        data = data.filter((element) => element.eleType !== 'element');

        data = [...data, ...verifyData];
        setDefaultData({
          ...defaultData,
          elements: data,
        });
      } else {
        setDefaultData({ ...defaultData, elements: [...verifyData] });
      }
      // console.log(isValidArray(verifyData));
      hasError = isValidArray(verifyData);
      // setOpenAlert(isValidArray(verifyData));
    }
    if (name === 'reservation' || name === 'expires') {
      let data = defaultData.elements.filter((element) => element.eleType !== name);

      data = [...data, ...verifyData];
      setDefaultData({
        ...defaultData,
        elements: data,
      });
      hasError = isValidArray(verifyData);
    }
    // the same time have at lest two same type data && no setting  is no allowed
    if (hasError) {
      setOpenAlert(true);
      setTimeout(() => {
        setOpenAlert(false);
      }, 5000);
    } else {
      setOpenAlert(false);
      setPage((currPage) => currPage + 1);
    }
  };

  useEffect(() => {
    console.log(defaultData);
  }, [defaultData]);
  // useEffect(() => {
  //   console.log('removeId=>>', removeId);
  // }, [removeId]);

  return (
    <div>
      {operation == 'add' ? (
        <Button
          variant="contained"
          onClick={handleClickOpen}
          sx={{
            width: '145px',
            height: '32px',
            background: 'linear-gradient(to left, #66D0D5, #00B0B9)',
            boxShadow: 'none',
            color: '#1E1E1E',
            fontFamily: 'Arial',
            fontWeight: 700,
            fontSize: '14px',
            lineHeight: '22px',
            textAlign: 'center',
          }}
        >
          + {t('Add New Tariff')}
        </Button>
      ) : (
        ''
      )}
      <Dialog open={open} onClose={handleClose} aria-labelledby="Add New Tariff">
        <DialogTitle id="AddNewTariff">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {operation === 'add'
              ? t('Add New Tariff') + (defaultData.name ? ' (' + defaultData.name + ')' : '')
              : t('Edit Tariff') + (defaultData.name ? ' (' + defaultData.name + ')' : '')}

            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
          <Stepper activeStep={page} sx={{ pt: 1 }}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{t(label)}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </DialogTitle>
        <DialogContent>
          {unauthorized && <LoginAgain />}
          <Snackbar
            open={success}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={handleCloseSnackbar_fun}
          >
            <Alert severity="success">{t('Success')}!</Alert>
          </Snackbar>
          <Snackbar
            open={error}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={handleCloseSnackbar_fun}
          >
            <Alert severity="error">{errorMessage}</Alert>
          </Snackbar>

          <Snackbar
            open={openAlert}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert severity="warning">At least two data of the same type and not set are not allowed!</Alert>
          </Snackbar>
          {PageDisplay()}
          <br />
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <LoadingButton
              variant="outlined"
              sx={buttonL}
              onClick={() => {
                if (page === 0) {
                  handleClose();
                } else {
                  setPage((currPage) => currPage - 1);
                }
              }}
            >
              {page === 0 ? t('Cancel') : t('Back')}
            </LoadingButton>
            {page === steps.length - 1 ? (
              <LoadingButton onClick={onSubmit} loading={loading} sx={buttonR}>
                {t('Submit')}
              </LoadingButton>
            ) : (
              <LoadingButton sx={buttonR} onClick={nextClick}>
                {t('Next')}
              </LoadingButton>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
