import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
// mui
import { TextField, Box, FormControlLabel, Checkbox, FormGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// ----------------------------------------------------------------------

export default function Flat_advance({ adRef, defaultData }) {
  const { t } = useTranslation();
  const [endDateError, setEndDateError] = useState(false);
  const [day_of_week_text, setDay_of_week_text] = useState(t('Tomorrow') + ': ' + getTomorrowDate());
  const daysObj = {
    MONDAY: false,
    TUESDAY: false,
    WEDNESDAY: false,
    THURSDAY: false,
    FRIDAY: false,
    SATURDAY: false,
    SUNDAY: false,
  };
  const [checkedItems, setCheckedItems] = useState(daysObj);
  const [formData, setFormData] = useState({
    start_date: null,
    end_date: null,
    start_time: '',
    end_time: '',
    day_of_week: [],
  });
  useEffect(() => {
    if (defaultData && defaultData.restrictions_show) {
      setCheckedItems(daysObj);
      setDay_of_week_text(t('Tomorrow') + ': ' + getTomorrowDate());
      const data = defaultData.restrictions.day_of_week;
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        const arr = daysObj;
        arr[element] = true;
        setCheckedItems(arr);
      }
      setFormData(defaultData.restrictions);
      if (data.length !== 0) {
        var sentence = '';
        let trans = getDayType(data);
        if (trans == 'weekdays' || trans == 'weekends') {
          sentence += ' ' + t('sentence_on') + ' ' + t(trans);
        } else if (trans == 'Every day') {
          sentence += ' ' + t('Every day');
        } else {
          sentence += ' ' + t('sentence_on') + ' ';
          for (let index = 0; index < trans.length; index++) {
            const element = trans[index];
            sentence += t(element) + ',';
          }
        }
        setDay_of_week_text(sentence);
      }
    } else {
      setCheckedItems(daysObj);
      setDay_of_week_text(t('Tomorrow') + ': ' + getTomorrowDate());
    }
  }, [defaultData]);

  useImperativeHandle(adRef, () => ({
    getAdvanceData,
  }));
  // handleSubmit
  const getAdvanceData = (event) => {
    return formData;
    // console.log(formData); //get formData array
  };

  // day of week change
  const handleChange = (event) => {
    const name = event.target.name;
    const status = event.target.checked;
    if (status) {
      // Add the name to the array if it doesn't exist
      if (!formData.day_of_week.includes(name)) {
        setCheckedItems({
          ...checkedItems,
          [name]: true,
        });
        let arr = formData.day_of_week;
        arr.push(name);
        setFormData({
          ...formData,
          day_of_week: arr,
        });
      }
    } else {
      // Remove the name from the array if it exists
      if (formData.day_of_week.includes(name)) {
        setCheckedItems({
          ...checkedItems,
          [name]: false,
        });
        let arr = formData.day_of_week;
        let index = arr.indexOf(name);
        if (index !== -1) {
          arr.splice(index, 1);
          setFormData({
            ...formData,
            day_of_week: arr,
          });
        }
      }
    }
    if (formData.day_of_week.length === 0) {
      setDay_of_week_text(t('Tomorrow') + ': ' + getTomorrowDate());
    } else {
      // var string = t('Every') + ': ';
      // for (let index = 0; index < formData.day_of_week.length; index++) {
      //   const element = formData.day_of_week[index];
      //   string += t(element) + ',';
      // }
      // setDay_of_week_text(string);
      var sentence = '';
      let trans = getDayType(formData.day_of_week);
      if (trans == 'weekdays' || trans == 'weekends') {
        sentence += ' ' + t('sentence_on') + ' ' + t(trans);
      } else if (trans == 'Every day') {
        sentence += ' ' + t('Every day');
      } else {
        sentence += ' ' + t('sentence_on') + ' ';
        for (let index = 0; index < trans.length; index++) {
          const element = trans[index];
          sentence += t(element) + ',';
        }
      }
      setDay_of_week_text(sentence);
    }
  };
  function getDayType(daysArray) {
    // const weekdays = ['Friday', 'Monday', 'Thursday', 'Tuesday', 'Wednesday'];
    const weekdays = ['FRIDAY', 'MONDAY', 'THURSDAY', 'TUESDAY', 'WEDNESDAY'];
    //const weekends = ['Saturday', 'Sunday'];
    const weekends = ['SATURDAY', 'SUNDAY'];
    const sortedDays = [...daysArray].sort(); // Sort the days for comparison

    if (sortedDays.toString() === weekdays.toString()) {
      return 'weekdays';
    } else if (sortedDays.toString() === weekends.toString()) {
      return 'weekends';
    } else if (daysArray.length === 7) {
      return 'Every day';
    } else {
      return daysArray;
    }
  }

  function getTomorrowDate() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const dayOfWeek = daysOfWeek[tomorrow.getDay()];
    const month = months[tomorrow.getMonth()];
    const date = tomorrow.getDate();
    const year = tomorrow.getFullYear();

    return `${dayOfWeek} ${month} ${date} ${year}`;
  }
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          name="start_date"
          label={t('Start Date')}
          views={['year', 'month', 'day']}
          value={formData.start_date}
          onChange={(date) => setFormData({ ...formData, start_date: date })}
          renderInput={(params) => <TextField style={{ width: '50%' }} margin="dense" size="small" {...params} />}
        />

        <DatePicker
          name="end_date"
          label={t('End Date')}
          views={['year', 'month', 'day']}
          value={formData.end_date}
          onChange={(date) => {
            // Check if the selected end date is before the start date
            if (formData.start_date && date.isBefore(formData.start_date, 'day')) {
              // Show an error or handle the invalid date
              setEndDateError(true);
            } else {
              // Clear the error state if the selected date is valid
              setEndDateError(false);
              setFormData({ ...formData, end_date: date });
            }
          }}
          renderInput={(params) => (
            <TextField
              style={{ width: '50%' }}
              margin="dense"
              size="small"
              // style={{ marginTop: '10px' }}
              error={endDateError}
              helperText={endDateError ? t('date_before') : ''}
              {...params}
            />
          )}
        />
      </LocalizationProvider>
      <TextField
        margin="dense"
        size="small"
        label={t('Start Time')}
        type="time"
        style={{ width: '50%' }}
        value={formData.start_time}
        onChange={(t) => {
          setFormData({ ...formData, start_time: t.target.value });
        }}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}
      />
      <TextField
        margin="dense"
        size="small"
        style={{ width: '50%', float: 'right' }}
        label={t('End Time')}
        type="time"
        value={formData.end_time}
        onChange={(t) => {
          setFormData({ ...formData, end_time: t.target.value });
        }}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}
      />
      <div style={{ border: '1px solid #5b5b5b', padding: '10px', marginTop: '10px' }}>
        <Box style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <p style={{ width: '100px', marginTop: '8px' }}>{t('Day Of Week')} </p>
          <div style={{ wordBreak: 'break-all', width: '100%', textAlign: 'center' }}>{day_of_week_text}</div>
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox checked={checkedItems.MONDAY} onChange={handleChange} name="MONDAY" />}
              label={t('MONDAY')}
            />
            <FormControlLabel
              control={<Checkbox checked={checkedItems.TUESDAY} onChange={handleChange} name="TUESDAY" />}
              label={t('TUESDAY')}
            />
            <FormControlLabel
              control={<Checkbox checked={checkedItems.WEDNESDAY} onChange={handleChange} name="WEDNESDAY" />}
              label={t('WEDNESDAY')}
            />
            <FormControlLabel
              control={<Checkbox checked={checkedItems.THURSDAY} onChange={handleChange} name="THURSDAY" />}
              label={t('THURSDAY')}
            />
            <FormControlLabel
              control={<Checkbox checked={checkedItems.FRIDAY} onChange={handleChange} name="FRIDAY" />}
              label={t('FRIDAY')}
            />
            <FormControlLabel
              control={<Checkbox checked={checkedItems.SATURDAY} onChange={handleChange} name="SATURDAY" />}
              label={t('SATURDAY')}
            />
            <FormControlLabel
              control={<Checkbox checked={checkedItems.SUNDAY} onChange={handleChange} name="SUNDAY" />}
              label={t('SUNDAY')}
            />
          </FormGroup>
        </Box>
      </div>
    </div>
  );
}
