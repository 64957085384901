import React, { useEffect } from 'react';
// mui
import { Stack, Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function Confirmation({ formData, setFormData }) {
  const { t } = useTranslation();
  const column = [
    {
      id: 'name',
      label: t('EVSE'),
      width: 70,
    },
    {
      id: 'connector',
      label: t('Connector'),
      width: 120,
    },
    {
      id: 'user',
      label: t('User'),
      width: 150,
    },
    {
      id: 'transaction_id',
      label: t('Transaction Id'),
      width: 120,
    },
    {
      id: 'function',
      label: t('Function'),
      width: 120,
    },
  ];
  useEffect(() => {
    setFormData({ ...formData, checkedid: formData.checkeduser.mobile_id });
  }, []);
  return (
    <Stack direction="column" spacing={3}>
      <Typography variant="body1">
        {t('tip_reviewTransaction')}.
        {/* Please review the selected EVSE and user for Start transaction to be carried out. */}
      </Typography>
      <Table aria-labelledby="tableTitles">
        <TableHead>
          <TableRow>
            {column.map((headCell) => (
              <TableCell key={headCell.id} align="left" padding="normal" sx={{ width: headCell.width }}>
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={'1'}>
            <TableCell align="left" padding="normal">
              {formData.checked}
            </TableCell>
            <TableCell align="left" padding="normal">
              {formData.connectorName}
            </TableCell>
            <TableCell align="left" padding="normal">
              {Object.entries(formData.checkeduser).map(([name, value]) => (
                <p key={value}>
                  {name}: {value}
                </p>
              ))}
            </TableCell>
            <TableCell>1</TableCell>
            <TableCell>{t('Remote Start Transaction')}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Stack>
  );
}
