import React from 'react';
// mui
import { Container, Typography } from '@mui/material';
// components
import Page from '../../../components/Page';
import AdminTable from './AdminTable';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function Admin() {
  const { t } = useTranslation();

  return (
    <Page title={t('Admin')}>
      <Container maxWidth="false">
        <Typography
          sx={{
            fontFamily: 'Arial',
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '36px',
            color: 'texts.main',
            mb: 2,
          }}
        >
          {t('Admin Management')}
        </Typography>
        <AdminTable />
      </Container>
    </Page>
  );
}
