import React from 'react';
import PropTypes from 'prop-types';
// mui
import { Stack, Typography, Table, TableHead, TableRow, TableCell, TableBody, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function SelectEVSE({ formData, setFormData }) {
  const { t } = useTranslation();

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = formData.rows.map((n) => n.name);
      setFormData({ ...formData, checked: newSelected });
      return;
    }
    setFormData({ ...formData, checked: [] });
  };

  const handleClick = (event, name, status) => {
    const selectedIndex = formData.checked.indexOf(name);
    let newSelected = [];
    let newSelectedStatus = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(formData.checked, name);
      newSelectedStatus = newSelectedStatus.concat(formData.checkedStatus, status);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(formData.checked.slice(1));
      newSelectedStatus = newSelectedStatus.concat(formData.checkedStatus.slice(1));
    } else if (selectedIndex === formData.checked.length - 1) {
      newSelected = newSelected.concat(formData.checked.slice(0, -1));
      newSelectedStatus = newSelectedStatus.concat(formData.checkedStatus.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        formData.checked.slice(0, selectedIndex),
        formData.checked.slice(selectedIndex + 1)
      );
      newSelectedStatus = newSelectedStatus.concat(
        formData.checkedStatus.slice(0, selectedIndex),
        formData.checkedStatus.slice(selectedIndex + 1)
      );
    }
    setFormData({ ...formData, checked: newSelected, checkedStatus: newSelectedStatus });
  };

  const isSelected = (name) => formData.checked.indexOf(name) !== -1;

  return (
    <div>
      <Stack direction="column" spacing={3}>
        <Typography variant="body1">
          {t('tit_SelectEVSE')}
          {/* Select the EVSE(s) to carry out change availability. */}
        </Typography>
        <Table aria-labelledby="tableTitle">
          <EnhancedTableHead onSelectAllClick={handleSelectAllClick} />
          <TableBody>
            {formData.rows.map((row, index) => {
              const isItemSelected = isSelected(row.charge_point_id);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow hover key={row.charge_point_id} selected={isItemSelected}>
                  <TableCell component="th" id={labelId} scope="row" padding="normal" sx={{ minWidth: 50 }}>
                    {row.charge_point_id}
                  </TableCell>
                  <TableCell align="left" sx={{ minWidth: 100 }}>
                    {row.status}
                  </TableCell>
                  <TableCell padding="checkbox" align="left" sx={{ minWidth: 100 }}>
                    <Checkbox
                      disabled={row.status === 'Available' || row.status === 'Unavailable' ? false : true}
                      onClick={(event) => handleClick(event, row.charge_point_id, row.status)}
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Stack>
    </div>
  );
}

EnhancedTableHead.propTypes = {
  onSelectAllClick: PropTypes.func.isRequired,
};

function EnhancedTableHead({ onSelectAllClick }) {
  const { t } = useTranslation();
  const column = [
    {
      id: 'name',
      label: t('EVSE'),
    },
    {
      id: 'status',
      label: t('Current Status'),
    },
  ];
  return (
    <TableHead>
      <TableRow>
        {column.map((headCell) => (
          <TableCell key={headCell.id} align="left" padding="normal">
            {headCell.label}
          </TableCell>
        ))}
        <TableCell padding="checkbox">
          {/* <Checkbox
            color="primary"
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all',
            }}
          />
          Select All */}
          {t('Select')}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
