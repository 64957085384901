import React, { useState, useEffect, useRef } from 'react';
// mui
import {
  Container,
  Typography,
  Stack,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Box,
  FormControl,
  Snackbar,
  Alert,
} from '@mui/material';
// components
import Page from '../../../components/Page';
import TariffTable from './tariffTable/index';
import AddTariff from './addTariff';
import { searchTariffFunction } from '../../../api/AllAPI';
import Icon from '../../../components/customIcon/icon-search-16.svg';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

export default function Tariff() {
  const { t } = useTranslation();
  const childRef = useRef();
  const [searchWord, setSearchWord] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // const childRef = React.createRef();
  const searchHandle = (value) => {
    // console.log(value);
    setSearchWord(value);
    // updateList({ oprType: 'search' });
    // searchTariffFunction(JSON.stringify({ name: value }), setError, setErrorMessage).then((promiseResult) => {
    //   if (promiseResult.category === 'Success') {
    //     setSuccess(true);

    //   } else {
    //     debugger;
    //     setError(true);
    //     setErrorMessage(promiseResult.message);
    //   }

    // });
  };
  useEffect(() => {
    updateList({ oprType: 'search' });
  }, [searchWord]);
  const updateList = (oprType) => {
    // Check if the ref is assigned and the method exists before calling it
    if (childRef.current && childRef.current.update_List) {
      childRef.current.update_List(oprType); // Call the update_list method on the child component
    }
  };
  return (
    <Page title={t('Tariff Management')}>
      <Container maxWidth="false">
        <Snackbar
          open={success}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={5000}
          // onClose={handleCloseSnackbar}
        >
          <Alert severity="success">{t('Success')}!</Alert>
        </Snackbar>
        <Snackbar
          open={error}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          // onClose={handleCloseSnackbar}
        >
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>

        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Typography
            sx={{
              fontFamily: 'Arial',
              fontWeight: 700,
              fontSize: '24px',
              lineHeight: '36px',
              color: 'texts.main',
              mb: 2,
            }}
          >
            {t('Tariff Management')}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ pb: 1 }}>
          <FormControl fullWidth sx={{ mr: 1, width: '100%' }}>
            <InputLabel htmlFor="outlined-adornment-search">{t('Search')}</InputLabel>
            <OutlinedInput
              id="outlined-adornment-search"
              startAdornment={
                <InputAdornment position="start">
                  <Box component="img" src={Icon} sx={{ width: '16px', height: '16px' }} />
                </InputAdornment>
              }
              value={searchWord}
              label={t('Search')}
              onChange={(event) => {
                searchHandle(event.target.value);
              }}
              sx={{ height: '32px' }}
            />
          </FormControl>
          <AddTariff operation="add" updateList={updateList}></AddTariff>
        </Stack>
        <TariffTable ref={childRef} setSearchWord={setSearchWord} searchWord={searchWord} />
      </Container>
    </Page>
  );
}
