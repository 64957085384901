import React, { useState, useEffect } from 'react';
// mui
import {
  Box,
  Table,
  Stack,
  Dialog,
  Button,
  TableRow,
  MenuItem,
  TableCell,
  TableBody,
  TextField,
  TableHead,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
// components
import { fetchParameter, SubmitChangeParameter } from '../../../../../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function ChangeParameter(props) {
  const { evseName } = props;
  const { t } = useTranslation();

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [parameter, setParameter] = useState();
  const [key, setKey] = useState();
  const [new_value, setNew_value] = useState();
  const [update, setUpdate] = useState();
  const [value, setValue] = useState([]);
  const [isDisabled, setIsDisabled] = useState([]);

  const cp_id = evseName;

  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  useEffect(() => {
    fetchParameter(cp_id, setError, setErrorMessage).then((promiseResult) =>
      setParameter(promiseResult.configuration_key)
    );
  }, [update]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, index) => {
    if (event.target.value === '') {
      const newInputDisabled = Array(parameter.length).fill(false);
      setIsDisabled(newInputDisabled);
    } else {
      const newInputDisabled = Array(parameter.length).fill(true);
      newInputDisabled[index] = false;
      setIsDisabled(newInputDisabled);
    }
    const newValue = [...value];
    newValue[index] = event.target.value;
    setValue(newValue);
    setKey(event.target.name);
    setNew_value(event.target.value);
  };

  const onSave = () => {
    SubmitChangeParameter(cp_id, key, new_value, setError, setErrorMessage).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        const newInputDisabled = Array(parameter.length).fill(false);
        setIsDisabled(newInputDisabled);
        const resetValue = value.map(() => '');
        setValue(resetValue);
        setUpdate(Math.random());
        props.setSuccess(true);
      } else {
        props.setErrorMessage(promiseResult.message);
        props.setError(true);
      }
    });
  };
  const column = [
    {
      id: 'configuration',
      label: t('Configuration'),
      width: 200,
    },
    {
      id: 'currentValue',
      label: t('Current Value'),
      width: 200,
    },
    {
      id: 'newValue',
      label: t('New Value'),
      width: 200,
    },
  ];
  const renderInputs = (checked, index) => {
    return (
      <TableCell align="left" padding="normal">
        {checked.readonly === 'false' || checked.readonly === 'False' ? (
          <TextField
            size="small"
            label={t('New value')}
            name={checked.key}
            value={value[index]}
            onChange={(event) => handleChange(event, index)}
            disabled={isDisabled[index]}
          />
        ) : (
          ''
        )}
      </TableCell>
    );
  };

  return (
    <Box>
      <MenuItem onClick={handleClickOpen}>{t('Change Parameter')}</MenuItem>
      <Dialog open={open} onClose={handleClose} aria-labelledby="ChangeAvailablity" maxWidth="xl">
        <DialogTitle id="ChangeAvailablity">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('Change Parameter')}
            <Button variant="outlined" endIcon={<SaveIcon />} onClick={onSave}>
              {t('Save')}
            </Button>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={0}>
            {/* <Button variant="outlined">Resync with EVSE</Button>
            <IconButton variant="outlined" onClick={props.Update}>
              <RefreshIcon />
            </IconButton> */}
          </Stack>
          <Table>
            <TableHead>
              <TableRow>
                {column.map((headCell) => (
                  <TableCell key={headCell.id} align="left" padding="normal" sx={{ width: headCell.width }}>
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {parameter?.map((checked, index) => {
                const splitName = checked.value.split(',');
                return (
                  <TableRow key={checked.key}>
                    <TableCell align="left" padding="normal">
                      {checked.key}
                    </TableCell>
                    <TableCell align="left" padding="normal">
                      {splitName.join('\n')}
                    </TableCell>
                    {renderInputs(checked, index)}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
