import React, { useEffect, useState } from 'react';
// mui
import { Stack, Typography, Card, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function Con_limit({ defaultData, allData }) {
  const { t } = useTranslation();

  return (
    <div>
      <Card variant="outlined">
        <CardContent>
          <h3>
            {t('Minimum')} {t('Price')}
          </h3>
          <div style={{ marginTop: '10px' }}>
            <ul style={{ marginLeft: '40px' }}>
              <li>
                {defaultData.min_price.excl_vat} {allData.currency} ( {t('excl.VAT')} )
              </li>
              <li>
                {defaultData.min_price.incl_vat} {allData.currency}{' '}
                {defaultData.min_price.incl_vat !== 0 &&
                defaultData.min_price.incl_vat !== '' &&
                defaultData.min_price.incl_vat !== null ? (
                  <span>
                    ( {t('incl.VAT')}, which is {''}
                    {parseFloat(
                      (
                        ((defaultData.min_price.incl_vat - defaultData.min_price.excl_vat) /
                          defaultData.min_price.excl_vat) *
                        100
                      ).toFixed(4)
                    )}
                    % )
                  </span>
                ) : (
                  <span> ( {t('incl.VAT')} )</span>
                )}
              </li>
            </ul>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
