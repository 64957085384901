import React from 'react';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function Chart(props) {
  const theme = useTheme();
  const { yData, xData } = props;
  const flip = xData.slice().reverse();
  const sortedEnergyValues = flip.map((item) => parseFloat(item.value));
  const { t } = useTranslation();

  const chartOptions = {
    theme: {
      mode: 'dark',
    },
    chart: {
      type: 'bar',
      height: 390,
      foreColor: theme.palette.title.main,
      background: theme.palette.background.paper,
    },
    grid: {
      show: true,
      position: 'back',
      strokeDashArray: 0,
      borderColor: theme.palette.background.paper2,
    },
    fill: {
      colors: theme.palette.barchart.main,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
        columnWidth: '50%',
      },
    },
    dataLabels: {
      enabled: false,
      style: { colors: [theme.palette.texts.main] },
    },
    xaxis: {
      categories: yData,
    },
    yaxis: {
      title: {
        text: t('Energy Used (kWh)'),
      },
    },
    tooltip: {
      theme: 'dark',
      style: {
        fontSize: '12px',
        fontFamily: 'Arial',
      },
    },
  };
  const series = [
    {
      name: t('Energy Used (kWh)'),
      data: sortedEnergyValues,
      // data: yData.map((date, index) => ({ x: date, y: sortedEnergyValues[index] })),
    },
  ];

  return (
    <Box sx={{ p: 3, pb: 1 }}>
      <ReactApexChart type="bar" series={series} options={chartOptions} height={350} />
    </Box>
  );
}
