import React, { useState, useEffect } from 'react';
// mui
import { Box, Card, Stack, Collapse, IconButton, Typography, Tooltip, Snackbar, Alert } from '@mui/material';
// component
import Iconify from '../../../../components/Iconify';
import { useTranslation } from 'react-i18next';

import Time from '../addTariff/Confirmation/Time';
import Parking_time from '../addTariff/Confirmation/Parking_time';
import Energy from '../addTariff/Confirmation/Energy';
import Flat from '../addTariff/Confirmation/Flat';
import Limit_min from '../addTariff/Confirmation/limit_min';
import Limit_max from '../addTariff/Confirmation/limit_max';
import Reservation from '../addTariff/Confirmation/Reservation';
import Icon from '../../../../components/customIcon/Union.svg';
import moveIcon from '../../../../components/customIcon/icon-move-16.svg';
import DeleteIcon from '../../../../components/customIcon/icon-delete-turquoise-16.svg';
import EditTariff from '../addTariff/index';
import RowDelete from './tableRowDelete';
// ----------------------------------------------------------------------

export default function TableRow(props) {
  const { t } = useTranslation();

  const { row, updateList, setSuccess, setErrorMessage, setError } = props;
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const handleEdit = () => {
    setOpenEdit(true);
  };
  const handleAsign = () => {};
  const groups = {};
  const groups_expires = {};
  const groups_reservation = {};
  useEffect(() => {
    // console.log(row);
  }, []);
  return (
    <Card sx={{ boxShadow: 0, mb: 1, color: '#17262A', p: 1 }}>
      <Box>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
            <IconButton aria-label={t('expand row')} size="small" onClick={() => setOpen(!open)}>
              {open ? (
                <Iconify icon="akar-icons:chevron-down" width={20} height={20} />
              ) : (
                <Iconify icon="akar-icons:chevron-right" width={20} height={20} />
              )}
            </IconButton>
            <Typography
              sx={{
                fontFamily: 'Arial',
                fontWeight: 700,
                fontSize: '14px',
                lineHeight: '22px',
                color: 'texts.main',
              }}
            >
              {row.name}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <Tooltip title={t('Edit')}>
              <IconButton onClick={handleEdit} sx={{ width: '38px', height: '38px', zIndex: 'modal' }}>
                <Box component="img" src={Icon} sx={{ width: '100%', height: '100%' }} />
              </IconButton>
            </Tooltip>
            <RowDelete
              info={row}
              update_List={updateList}
              setSuccess={setSuccess}
              setErrorMessage={setErrorMessage}
              setError={setError}
            ></RowDelete>
            {/* <Tooltip title={t('Delete')}>
              <IconButton onClick={handleDelete} sx={{ width: '35px', height: '35px', zIndex: 'modal' }}>
                <Box component="img" src={DeleteIcon} sx={{ width: '100%', height: '100%' }} />
              </IconButton>
            </Tooltip> */}
            <Tooltip title={t('asign')}>
              <IconButton onClick={handleAsign} sx={{ width: '40px', height: '40px', zIndex: 'modal' }}>
                <Box component="img" src={moveIcon} sx={{ width: '100%', height: '100%' }} />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <div style={{ display: 'flex', flexWrap: 'wrap', background: '#232F34' }}>
            {row.max_price &&
            row.max_price.excl_vat !== '' &&
            row.max_price.excl_vat !== '0.00' &&
            row.max_price.excl_vat !== '0' &&
            row.max_price.excl_vat !== null ? (
              <div
                style={{
                  flex: '1 1 auto', // Distribute equally in one row, max four items
                  maxWidth: '33.33%',
                  // width: '33.33%',
                  padding: '10px',
                  boxSizing: 'border-box',
                }}
              >
                <Limit_max defaultData={row} allData={row}></Limit_max>
              </div>
            ) : (
              ''
            )}
            {row.min_price &&
            row.min_price.excl_vat !== '' &&
            row.min_price.excl_vat !== '0.00' &&
            row.min_price.excl_vat !== '0' &&
            row.min_price.excl_vat !== null ? (
              <div
                style={{
                  flex: '1 1 auto', // Distribute equally in one row, max four items
                  maxWidth: '33.33%',
                  // width: '33.33%',
                  padding: '10px',
                  boxSizing: 'border-box',
                }}
              >
                <Limit_min defaultData={row} allData={row}></Limit_min>
              </div>
            ) : (
              ''
            )}
            {row.elements && (
              <>
                {row.elements
                  .filter((ite) => ite.eleType === 'element')
                  .forEach((item) => {
                    const type = item.price_component[0].type;
                    if (!groups[type]) {
                      groups[type] = [];
                    }
                    groups[type].push(item);
                  })}
                {Object.keys(groups).map((ele, ind) => (
                  <div
                    key={ind}
                    style={{
                      flex: '1 1 auto', // Distribute equally in one row, max four items
                      maxWidth: '33.33%',
                      // width: '33.33%',
                      padding: '10px',
                      boxSizing: 'border-box',
                    }}
                  >
                    {ele === 'FLAT' && <Flat defaultData={groups[ele]} allData={row} />}
                    {ele === 'TIME' && <Time defaultData={groups[ele]} allData={row} />}
                    {ele === 'PARKING_TIME' && <Parking_time defaultData={groups[ele]} allData={row} />}
                    {ele === 'ENERGY' && <Energy defaultData={groups[ele]} allData={row} />}
                  </div>
                ))}
              </>
            )}
            {row.elements && (
              <>
                {row.elements
                  .filter((ite) => ite.eleType !== 'element')
                  .forEach((item) => {
                    if (item.price_component) {
                      const type = item.price_component[0].type;
                      if (!groups_reservation[type]) {
                        groups_reservation[type] = [];
                      }
                      groups_reservation[type].push(item);
                    }
                  })}

                <div
                  style={{
                    flex: '1 1 auto',
                    maxWidth: '33.33%',
                    padding: '10px',
                    boxSizing: 'border-box',
                  }}
                >
                  {' '}
                  {Object.keys(groups_reservation).length > 0 && (
                    <Reservation defaultData={groups_reservation} allData={row}></Reservation>
                  )}
                </div>
              </>
            )}
          </div>
          <br />
        </Collapse>
      </Box>
      {openEdit ? (
        <EditTariff operation="edit" propsData={row} setOpenEdit={setOpenEdit} updateList={updateList}></EditTariff>
      ) : (
        ''
      )}
    </Card>
  );
}
