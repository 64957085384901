import React, { useState, useEffect } from 'react';
// mui
import {
  Box,
  Stack,
  Dialog,
  Button,
  Select,
  Tooltip,
  MenuItem,
  TextField,
  IconButton,
  InputLabel,
  DialogTitle,
  FormControl,
  DialogContent,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Icon from '../../../../components/customIcon/Union.svg';
import { buttonR, buttonL } from '../../../../components/button/buttonStyle';
import CloseIcon from '../../../../components/customIcon/Close.svg';
import { SubmitUserEdit } from '../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function EditUserDialog(props) {
  const { info } = props;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(info.status);
  // const [rfid, setRfid] = useState(info.rfid);
  const [rfid, setRfid] = useState([]);
  const { t } = useTranslation();

  const mobile_id = info.mobile_id;
  const name = info.name;
  const rfidNum = info.rfid.rfid_num;
  const default_profile_type = "REGULAR";
  const whitelist = "ALWAYS";
  const id = info.id;
  const rfid_visual_number = "1";
  const rfid_issuer = "LITEON";
  const rfid_valid = true;
    
  const statusOptions = [
    {
      label: t('Accepted'),
    },
    {
      label: t('Expired'),
    },
    {
      label: t('Blocked'),
    },
  ];
  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };  
    
  const onSubmit = () => {
    setLoading(true); 
    const newRfid = [{
      rfid_num: rfid,
      visual_number: rfid_visual_number,
      whitelist: info.whitelist,
      contract_id: info.mobile_id,
      issuer: rfid_issuer,
      valid: rfid_valid,
    }]; 
      // if not key in new rfid, get the value from database. info value is from database.
      if (rfid.length === 0)
      {
        newRfid[0].rfid_num = ' ';
      }
      console.log("rfid len:",rfid.length)
      if ((rfid.length === 0) && info.rfid && info.rfid.length > 0) {
        console.log("<<----update rfid value")
        newRfid[0].rfid_num = info.rfid[0].rfid_num;
      }   
       
      // setRfid(newRfid);
      console.log("------------------------");
      console.log("RFID Number:", newRfid[0].rfid_num);
      console.log("Visual Number:", newRfid[0].visual_number);
      console.log("Whitelist:", newRfid[0].whitelist);
      console.log("Contract ID:", newRfid[0].contract_id);
      console.log("Issuer:", newRfid[0].issuer);
      console.log("Valid:", newRfid[0].valid);
      console.log("------------------------");
    // SubmitUserEdit(mobile_id, name, rfid, status, setError, setErrorMessage).then((promiseResult) => {
      SubmitUserEdit( id, status, whitelist, default_profile_type, newRfid, setError, setErrorMessage).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        props.setSuccess(true);
        props.EditUpdate(Math.random());
        handleClose();
      } else {
        props.setErrorMessage(promiseResult.message);
        props.setError(true);
      }
      setLoading(false);
    });
  };

  return (
    <Box>
      <Tooltip title={t('Edit')}>
        <IconButton onClick={handleClickOpen} sx={{ width: '35px', height: '35px', zIndex: 'fab' }}>
          <Box component="img" src={Icon} sx={{ width: '100%', height: '100%' }} />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} aria-labelledby="UserEditDialog">
        <DialogTitle id="UserEdit">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('Edit User Info')}            
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" justifyContent="flex-start" spacing={2} sx={{ width: 450 }}>
            <TextField
              disabled
              margin="dense"
              name="name"
              label={t('Name')}
              value={info.name}
              fullWidth
              variant="outlined"
            />
            <TextField
              disabled
              margin="dense"
              name="mobile_id"
              label={t('Mobile ID')}
              value={info.mobile_id}
              fullWidth
              variant="outlined"
            />
            <TextField
              margin="dense"
              name="rfid"
              label={t('RFID (Optional)')}
              //value={info.rfid && info.rfid.length > 0 && info.rfid[0].rfid_num}
              onChange={(e) => setRfid(e.target.value)}
              value={rfid}              
              fullWidth
              variant="outlined"
            />
            <FormControl fullWidth>
              <InputLabel id="status_select">{t('Status')}</InputLabel>
              <Select
                labelId="status_select"
                id="status_select"
                label={t('Status')}
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                sx={{ zIndex: 'tooltip' }}
              >
                {statusOptions.map((option) => (
                  <MenuItem key={option.label} value={option.label}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
              <Button variant="outlined" onClick={handleClose} sx={buttonL}>
                {t('Back')}
              </Button>
              <LoadingButton onClick={onSubmit} loading={loading} sx={buttonR}>
                {t('Edit')}
              </LoadingButton>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
