import axios from './axios';

const ADMIN_URL = '/admin_management/list/';
const LISTCS_URL = '/admin_management/list_charging_stations/';
const DELETEADMIN_URL = '/admin_management/delete_admin/';
const EDITADMIN_URL = '/admin_management/edit_admin/';
const ADDADMIN_URL = '/admin_management/add/';
const SESSION_URL = '/session_summary/list/';
const USER_URL = '/user_management/list/user';
const LISTCP_URL = '/user_management/list/charge_point/';
const LISTCONNECTOR_URL = '/user_management/list/connector/';
const ADDUSER_URL = '/user_management/add_dbuser/';
const RESERVE_URL = '/user_management/make_reserve/';
const CANCELRESERVE_URL = '/user_management/cancel_reserve/';
const TARIFF_URL = '/tariff_management/list/';
const TARIFFEDIT_URL = '/tariff_management/edit/';
const LISTFM_URL = '/list_firmware_directory/';
const LISTLL_URL = '/local_list/list_user/';
const LISTD_URL = '/list_diagnostic_directory/';
const LISTPARAMETER_URL = '/list_parameter/';
const LISTMT_URL = '/charger_management/move_to_list_station/';
const LISTUSER_URL = '/list_mobile_user/';
const EDITUSER_URL = '/user_management/edit/';
const UPDATE_URL = '/update_firmware/';
const UPLOAD_URL = '/upload_firmware/';
const LOCALLIST_URL = '/send_local_list/';
const DOWNLOAD_URL = '/download_diagnostic_report/';
const REQUEST_URL = '/get_diagnostic/';
const DELETEEVSE_URL = '/delete_evse/';
const CLEARCACHE_URL = '/charger_management/clear_cache/';
const SAVE_URL = '/change_parameter/';
const CHANGEAVAILABILITY_URL = '/charger_management/change_availability/';
const LISTAVAILABILITY_URL = '/charger_management/change_availability_list_charge_point/';
const MOVETO_URL = '/charger_management/move_to/';
const SELECTRESTART_URL = '/charger_management/reset/';
const SELECTSTART_URL = '/charger_management/remote_start_transaction/';
const SELECTSTOP_URL = '/charger_management/remote_stop_transaction/';
const SELECTUNLOCK_URL = '/unlock_connector/';
const DELETESTATION_URL = '/charger_management/delete_station/';
const EDITSTATION_URL = '/charger_management/edit_station/';
const ADDEVSE_URL = '/charger_management/add_evse/';
const DELETELOCATION_URL = '/charger_management/delete_location/';
const EDITLOCATION_URL = '/charger_management/edit_location/';
const ADDSTATION_URL = '/charger_management/add_station/';
const ADDLOCATION_URL = '/charger_management/add_location/';
const ALERT_URL = '/get_alerts/';
const NOTIFICATION_URL = '/get_notifications/';
const LOGIN_URL = '/login/';
const CHANGENAME_URL = '/account_settings/edit/';
const CHANGEPW_URL = '/change_password/';
const OVERVIEW_URL = '/dashboard/overview/';
const LOCATION_URL = '/dashboard/location/';
const OVERVIEWREVENUE_URL = '/revenue/overview/';
const REVENUE_URL = '/revenue/location/';
const LISTCOORDINATE_URL = '/charger_management/list_coordinate/';
const CHARGERSTATION_URL = 'charger_management/list_location/';
const EVSE_URL = '/charger_management/list_evse/';
const LISTL_URL = '/charger_management/move_to_list_location/';
const DELETEUSER_URL = '/user_management/delete/';
const OCPP_URL = '/ocpp_log/list';
const FORGOTPASSWORD_URL = '/forgotpassword/';
const TARIFFADD_URL = '/tariff_management/add';
const TARIFFLIST_URL = '/tariff_management/list';
const TARIFF_EDIT_URL = '/tariff_management/edit';
const TARIFF_DELETE_URL = '/tariff_management/delete';
const TARIFF_SEARCH_URL = '/tariff_management/search';
// const URL = 'http://192.168.39.24:3000';
// const TARIFF_DELETE_URL = '/tariff_management/edit';
// 'http://127.0.0.1:3000/'
export const searchTariffFunction = async (info, setError, setErrorMessage) => {
  try {
    const response = await axios.post(TARIFF_SEARCH_URL, info, {
      headers: { Authorization: sessionStorage.getItem('access_token') },
    });
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const DeleteTariffFunction = async (tariff_id, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      TARIFF_DELETE_URL,
      { tariff_id },
      {
        headers: { Authorization: sessionStorage.getItem('access_token') },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const editTariffFunction = async (data, setError, setErrorMessage) => {
  try {
    const response = await axios.post(TARIFF_EDIT_URL, data, {
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
      },
    });
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};
export const addTariffFunction = async (data, setError, setErrorMessage) => {
  try {
    const response = await axios.post(TARIFFADD_URL, data, {
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
      },
    });
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};
export const tariffListFunction = async (data, setErrorMessage, setError) => {
  try {
    const response = await axios.post(TARIFFLIST_URL, data, {
      headers: { Authorization: sessionStorage.getItem('access_token') },
    });
    console.log(response.data)
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

const handleFetchError = (err, setErrorMessage, setError, setUnauthorized) => {
  console.log(`Error: ${err.message}`);
  setError(true);
  setErrorMessage(err.message);
  if (err.response.status === 401) {
    setUnauthorized(true);
  }
  if (err.response.status === 422) {
    setUnauthorized(true);
  }
  return err;
};

const handleSetError = (err, setErrorMessage, setError) => {
  console.log(`Error: ${err.message}`);
  setError(true);
  setErrorMessage(err.message);
  return err;
};

const handleFetchSuccess = async (url, value1, value2) => {
  const response = await axios.post(
    url,
    { value1, value2 },
    {
      headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
    }
  );
  return response.data.data;
};

export const fetchAdmin = (setErrorMessage, setError, setUnauthorized) => {
  try {
    return handleFetchSuccess(ADMIN_URL);
  } catch (err) {
    return handleFetchError(err, setErrorMessage, setError, setUnauthorized);
  }
};

export const fetchCS = async (setErrorMessage, setError, setUnauthorized) => {
  try {
    return handleFetchSuccess(LISTCS_URL);
  } catch (err) {
    return handleFetchError(err, setErrorMessage, setError, setUnauthorized);
  }
};

export const fetchSessionLog = async (page, rowsPerPage, setUnauthorized, setErrorMessage, setError) => {
  const data = {
    page_number: page + 1,
    page_size: rowsPerPage
  };
  try {
    const response = await axios.post(SESSION_URL, data, {
      headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
    });
    console.log(response.data)
    return response.data.data.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.message);
    setError(true);
    if (err.response.status === 401) {
      setUnauthorized(true);
    }
    if (err.response.status === 422) {
      setUnauthorized(true);
    }
    return err;
  }
};

export const fetchSessionListLog = async (data, setErrorMessage, setError) => {
  try {
    const response = await axios.post(SESSION_URL, data, {
      headers: { Authorization: sessionStorage.getItem('access_token') },
    });
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const fetchUser = async (setErrorMessage, setError, setUnauthorized) => {
  try {
    return handleFetchSuccess(USER_URL);
  } catch (err) {
    return handleFetchError(err, setErrorMessage, setError, setUnauthorized);
  }
};

export const fetchUserList = async (data, setErrorMessage, setError) => {
  try {
    const response = await axios.post(USER_URL, data, {
      headers: { Authorization: sessionStorage.getItem('access_token') },
    });
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const fetchUserCP = async (charging_station, setError, setErrorMessage) => {
  try {
    return handleFetchSuccess(LISTCP_URL, charging_station);
  } catch (err) {
    return handleSetError(err, setErrorMessage, setError);
  }
};

export const fetchUserConnector = async (connectors, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      LISTCONNECTOR_URL,
      { charge_point: connectors },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data.data;
  } catch (err) {
    return handleSetError(err, setErrorMessage, setError);
  }
};

export const fetchTariffLocation = async (setUnauthorized, setErrorMessage, setError) => {
  try {
    return handleFetchSuccess(TARIFF_URL);
  } catch (err) {
    return handleFetchError(err, setErrorMessage, setError, setUnauthorized);
  }
};

export const fetchFILE = async (setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      LISTFM_URL,
      {},
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const fetchEVSE = async (cp_id, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      LISTLL_URL,
      { cp_id },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const fetchDiagnosticReport = async (cp_id, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      LISTD_URL,
      { cp_id },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const fetchParameter = async (cp_id, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      LISTPARAMETER_URL,
      { cp_id },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const fetchCP = async (location, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      LISTMT_URL,
      { location },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const fetchUserStart = async (setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      LISTUSER_URL,
      {},
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const fetchEVSECS = async (charging_station, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      LISTAVAILABILITY_URL,
      { charging_station },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const SubmitAdminDeleteDialog = async (email, setError, setErrorMessage, setLoading) => {
  try {
    const response = await axios.post(
      DELETEADMIN_URL,
      { email },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.message);
    setError(true);
    setLoading(false);
    return err;
  }
};

export const SubmitAdminEditDialog = async (
  email,
  name,
  employee_id,
  charging_station_id,
  id,
  setError,
  setErrorMessage
) => {
  try {
    const response = await axios.post(
      EDITADMIN_URL,
      { email, name, employee_id, charging_station_id, id },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const SubmitAdminAddDialog = async (
  name,
  employee_id,
  email,
  password,
  charging_stations_id,
  setError,
  setErrorMessage
) => {
  try {
    const response = await axios.post(
      ADDADMIN_URL,
      { name, employee_id, email, password, charging_stations_id },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const SubmitUserAddDialog = async (name, email, rfid, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      ADDUSER_URL,
      { name, email, rfid },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const SubmitUserReserve = async (charge_point_id, mobile_id, connector_id, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      RESERVE_URL,
      { charge_point_id, mobile_id, connector_id },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const SubmitUserEdit = async (id, status, whitelist, default_profile_type, rfid, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      EDITUSER_URL,
      //{ mobile_id, name, rfid, status },
      {id, status, whitelist, default_profile_type, rfid},
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const SubmitUserCancel = async (mobile_id, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      CANCELRESERVE_URL,
      { mobile_id },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const submitAC = async (id, ac_tariff, ac_currency_code, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      TARIFFEDIT_URL,
      { id, ac_tariff, ac_currency_code },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const submitDC = async (id, dc_tariff, dc_currency_code, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      TARIFFEDIT_URL,
      { id, dc_tariff, dc_currency_code },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const SubmitFirmwareUpdate = async (cp_id, firmware_id, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      UPDATE_URL,
      { cp_id, firmware_id },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const SubmitUpload = async (file, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      UPLOAD_URL,
      { file },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
        },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const SubmitLocalList = async (cp_id, user_list, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      LOCALLIST_URL,
      { cp_id, user_list },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const SubmitDownload = async (diagnostic_report_id, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      DOWNLOAD_URL,
      { diagnostic_report_id },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
        responseType: 'blob',
      }
    );

    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const SubmitRequest = async (cp_id, start_date, end_date, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      REQUEST_URL,
      { cp_id, start_date, end_date },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const SubmitDeleteEVSE = async (cp_id, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      DELETEEVSE_URL,
      { cp_id },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const SubmitClearCache = async (evse_list_id, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      CLEARCACHE_URL,
      { evse_list_id },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );

    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const SubmitChangeParameter = async (cp_id, key, new_value, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      SAVE_URL,
      { cp_id, key, new_value },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const SubmitChangeAvailabilityCP = async (status, cp, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      CHANGEAVAILABILITY_URL,
      { status, cp },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );

    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const SubmitChangeAvailabilityCS = async (formData, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      CHANGEAVAILABILITY_URL,
      { formData },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const SubmitMoveTo = async (new_cs_id, old_cs_id, cp_id, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      MOVETO_URL,
      { new_cs_id, old_cs_id, cp_id },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const SubmitRestart = async (evse_list_id, reset_type, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      SELECTRESTART_URL,
      { evse_list_id, reset_type },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const SubmitStart = async (mobile_id, charge_point_id, connector_id, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      SELECTSTART_URL,
      { mobile_id, charge_point_id, connector_id },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );

    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const SubmitStop = async (mobile_id, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      SELECTSTOP_URL,
      { mobile_id },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const SubmitUnlock = async (cp_id, connector_id, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      SELECTUNLOCK_URL,
      { cp_id, connector_id },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const SubmitDeleteStation = async (cs_id, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      DELETESTATION_URL,
      { cs_id },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );

    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const SubmitEditStation = async (name, cs_id, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      EDITSTATION_URL,
      { name, cs_id },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );

    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const SubmitAddCS = async (cp_id, cs_id, connector, capability, parking_restrictions, ocpi_physical_reference, floor_level, ocppVersion, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      ADDEVSE_URL,
      { cp_id, cs_id, connector, capability, parking_restrictions, ocpi_physical_reference, floor_level, ocppVersion },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const SubmitDeleteLocation = async (location, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      DELETELOCATION_URL,
      { location },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const SubmitEditLocation = async (location, location_name, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      EDITLOCATION_URL,
      { location, location_name },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const SubmitAddCL = async (
  location,
  name,
  address,
  city,
  country,
  postal_code,
  parking_type,
  facilities,
  operatingHours,
  startTime,
  stopTime,
  setError,
  setErrorMessage
) => {
  try {
    const response = await axios.post(
      ADDSTATION_URL,
      {
        location,
        name,
        address,
        city,
        country,
        postal_code,
        parking_type,
        facilities,
        operatingHours,
        startTime,
        stopTime,
      },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );

    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const SubmitAddLocation = async (location, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      ADDLOCATION_URL,
      { location },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const fetchAlert = async () => {
  try {
    const response = await axios.post(
      ALERT_URL,
      {},
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    return err;
  }
};

export const fetchNotification = async () => {
  try {
    const response = await axios.post(
      NOTIFICATION_URL,
      {},
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    return err;
  }
};

export const SubmitLogin = async (email, password, setErrMsg) => {
  try {
    const response = await axios.post(LOGIN_URL, { 
      AuthFlow: "USER_PASSWORD_AUTH",
      ClientId: "4epp27tbtnveabojmsg6hn3v2e",
      AuthParameters: {
        "USERNAME": email,
        "PASSWORD": password
      },
     });
    return response.data;
  } catch (err) {
    if (!err?.response) {
      setErrMsg('No Server Response');
    } else if (err.response?.status === 400) {
      setErrMsg('Error 400');
    } else if (err.response?.status === 401) {
      setErrMsg('Error 401');
    } else {
      setErrMsg('Else Error');
    }
    return err;
  }
};

export const SubmitChangeName = async (name, setErrorMessage, setError) => {
  try {
    const response = await axios.post(
      CHANGENAME_URL,
      { name },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const SubmitChangePassword = async (password, old_password, confirm_password, setErrorMessage, setError) => {
  try {
    const response = await axios.post(
      CHANGEPW_URL,
      { password, old_password, confirm_password },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const fetchSummaryOverview = async (setError, setErrorMessage, setUnauthorized) => {
  try {
    const response = await axios.post(
      OVERVIEW_URL,
      {},
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setError(true);
    setErrorMessage(err.message);
    if (err.response.status === 401) {
      setUnauthorized(true);
    }
    if (err.response.status === 422) {
      setUnauthorized(true);
    }
    return err;
  }
};

export const fetchSummaryLocation = async (location, setError, setErrorMessage, setUnauthorized) => {
  try {
    const response = await axios.post(
      LOCATION_URL,
      { location },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      setUnauthorized(true);
    }
    if (err.response.status === 422) {
      setUnauthorized(true);
    }
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const fetchRevenueOverview = async (setError, setErrorMessage, setUnauthorized) => {
  try {
    const response = await axios.post(
      OVERVIEWREVENUE_URL,
      {},
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      setUnauthorized(true);
    }
    if (err.response.status === 422) {
      setUnauthorized(true);
    }
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const fetchRevenueLocation = async (location, setError, setErrorMessage, setUnauthorized) => {
  try {
    const response = await axios.post(
      REVENUE_URL,
      { location },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      setUnauthorized(true);
    }
    if (err.response.status === 422) {
      setUnauthorized(true);
    }
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const fetchCoordinate = async () => {
  try {
    const response = await axios.post(
      LISTCOORDINATE_URL,
      {},
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    return err;
  }
};

export const fetchChargerStation = async (setError, setErrorMessage, setUnauthorized) => {
  try {
    const response = await axios.post(
      CHARGERSTATION_URL,
      {},
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.message);
    setError(true);
    if (err.response.status === 401) {
      setUnauthorized(true);
    }
    if (err.response.status === 422) {
      setUnauthorized(true);
    }
    return err;
  }
};

export const fetchEVSECSS = async (cs_name, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      EVSE_URL,
      { cs_name },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const fetchL = async (setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      LISTL_URL,
      {},
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const SubmitDeleteUser = async (mobile_id, setError, setErrorMessage) => {
  try {
    const response = await axios.post(
      DELETEUSER_URL,
      { mobile_id },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrorMessage(err.message);
    setError(true);
    return err;
  }
};

export const fetchOCPPLog = async (setError, setErrorMessage, setUnauthorized) => {
  try {
    const response = await axios.post(
      OCPP_URL,
      {},
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data.data;
  } catch (err) {
    console.log(`Error: ${err.message}`);
    setErrorMessage(err.message);
    setError(true);
    if (err.response.status === 401) {
      setUnauthorized(true);
    }
    if (err.response.status === 422) {
      setUnauthorized(true);
    }
    return err;
  }
};

export const SubmitForgetPassword = async (email, setErrMsg) => {
  try {
    const response = await axios.post(
      FORGOTPASSWORD_URL,
      { email },
      {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err.message);
    setErrMsg(err.message);
    return err;
  }
};
