import React, { useState, useImperativeHandle } from 'react';
// mui
import { Stack, Typography, FormControl, FormHelperText, InputLabel, Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function SelectUnlockPage({ formData, setFormData, rows, cRef }) {
  const [error_select, setError_select] = useState(false);
  const [helperText_select, setHelperText_select] = useState('');
  const { t } = useTranslation();

  useImperativeHandle(cRef, () => ({
    handleVerification,
  }));

  const handleChange = (event) => {
    setFormData({ ...formData, connectorName: `Connector ${event.target.value}`, connectorId: event.target.value });
  };
  //
  const handleVerification = () => {
    if (!formData.connectorId) {
      setError_select(true);
      setHelperText_select('required fields');
    } else {
      setError_select(false);
      setHelperText_select('');
    }
  };
  return (
    <Stack direction="column" spacing={3}>
      <Typography variant="body1" fullWidth>
        {t('tit_selectedStart', { checked: formData.checked })}
        {/* {formData.checked} is selected for Start to be carried out. Please confirm the connector selected if there is
        more than 1 connector on the EVSE. */}
      </Typography>
      <FormControl fullWidth error={error_select}>
        <InputLabel id="Connector selected">Connector selected</InputLabel>
        <Select id="select-choice" value={formData.connectorId} label="Connector selected" onChange={handleChange}>
          {rows.map((rows) => (
            <MenuItem key={rows.value} value={rows.value}>
              {rows.name}
            </MenuItem>
          ))}
          {/* <MenuItem key={'1'} value={'1'}>
            Connector 1
          </MenuItem>
          <MenuItem key={'2'} value={'2'}>
            Connector 2
          </MenuItem> */}
        </Select>
        <FormHelperText>{helperText_select}</FormHelperText>
      </FormControl>
    </Stack>
  );
}
