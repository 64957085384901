import axios from './OCTTaxios';

const DELETE_CHARGER_POINT = 'delete_charger_point/';
const GET_CONFIGURATION = 'get_configuration/';
const GET_DIAGNOSTICS = 'get_diagnostics/';
const CHANGE_CONFIGURATION = 'change_configuration/';
const CHANGE_AVAILABILITY = 'change_availability/';
const REMOTE_START = 'remote_start/';
const REMOTE_STOP = 'remote_stop/';
const SEND_RESET = 'send_reset/';
const UNLOCK_CONNECTOR = 'unlock_connector/';
const GET_LOCAL_LIST = 'get_local_list/';
const SEND_LOCAL_LIST = 'send_local_list/';
const UPDATE_FIRMWARE = 'update_firmware/';
const RESERVE_NOW = 'reserve_now/';
const CANCEL_RESERVATION = 'cancel_reservation/';
const TRIGGER_MESSAGE = 'trigger_message/';
const SET_TX_PROFILE = 'set_tx_profile/';
const CLEAR_TX_PROFILE = 'clear_tx_profile/';
const CLEAR_CACHE = 'clear_cache/';
const SEND_DATA_TRANSFER = 'send_data_transfer/';
const GET_COMPOSITE_SCHEDULE = 'get_composite_schedule/';
const CERTIFICATE_SIGNED = 'certificate_signed/';
const INSTALL_CERTIFICATE = 'install_certificate/';
const GET_INSTALLED_CERTIFICATE_IDS = 'get_installed_certificate_ids/';
const DELETE_CERTIFICATE = 'delete_certificate/';
const EXTENDED_TRIGGER_MESSAGE = 'extended_trigger_message/';
const GET_LOG = 'get_log/';
const SIGNED_UPDATE_FIRMWARE = 'signed_update_firmware/';

export const deleteChargerPoint = async (data) => {
  try {
    const response = await axios.post(DELETE_CHARGER_POINT, data);
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getConfiguration = async (data) => {
  try {
    const response = await axios.post(GET_CONFIGURATION, data);
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getDiagnostics = async (data) => {
  try {
    const response = await axios.post(GET_DIAGNOSTICS, data);
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const changeConfiguration = async (data) => {
  try {
    const response = await axios.post(CHANGE_CONFIGURATION, data);
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const changeAvailability = async (data) => {
  try {
    const response = await axios.post(CHANGE_AVAILABILITY, data);
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const remoteStart = async (data) => {
  try {
    const response = await axios.post(REMOTE_START, data);
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const remoteStop = async (data) => {
  try {
    const response = await axios.post(REMOTE_STOP, data);
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const sendReset = async (data) => {
  try {
    const response = await axios.post(SEND_RESET, data);
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const unlockConnector = async (data) => {
  try {
    const response = await axios.post(UNLOCK_CONNECTOR, data);
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getLocalList = async (data) => {
  try {
    const response = await axios.post(GET_LOCAL_LIST, data);
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const sendLocalList = async (data) => {
  try {
    const response = await axios.post(SEND_LOCAL_LIST, data);
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const updateFirmware = async (data) => {
  try {
    const response = await axios.post(UPDATE_FIRMWARE, data);
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const reserveNow = async (data) => {
  try {
    const response = await axios.post(RESERVE_NOW, data);
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const cancelReservation = async (data) => {
  try {
    const response = await axios.post(CANCEL_RESERVATION, data);
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const triggerMessage = async (data) => {
  try {
    const response = await axios.post(TRIGGER_MESSAGE, data);
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const setTxProfile = async (data) => {
  try {
    const response = await axios.post(SET_TX_PROFILE, data);
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const clearTxProfile = async (data) => {
  try {
    const response = await axios.post(CLEAR_TX_PROFILE, data);
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const clearCache = async (data) => {
  try {
    const response = await axios.post(CLEAR_CACHE, data);
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const sendDataTransfer = async (data) => {
  try {
    const response = await axios.post(SEND_DATA_TRANSFER, data);
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getCompositeSchedule = async (data) => {
  try {
    const response = await axios.post(GET_COMPOSITE_SCHEDULE, data);
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const certificateSigned = async (data) => {
  try {
    const response = await axios.post(CERTIFICATE_SIGNED, data);
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const installCertificate = async (data) => {
  try {
    const response = await axios.post(INSTALL_CERTIFICATE, data);
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getInstalledCertificateIds = async (data) => {
  try {
    const response = await axios.post(GET_INSTALLED_CERTIFICATE_IDS, data);
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const deleteCertificate = async (data) => {
  try {
    const response = await axios.post(DELETE_CERTIFICATE, data);
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const extendedTriggerMessage = async (data) => {
  try {
    const response = await axios.post(EXTENDED_TRIGGER_MESSAGE, data);
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getLog = async (data) => {
  try {
    const response = await axios.post(GET_LOG, data);
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const signedUpdateFirmware = async (data) => {
  try {
    const response = await axios.post(SIGNED_UPDATE_FIRMWARE, data);
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};
