import PropTypes from 'prop-types';
// @mui
import { Box } from '@mui/material';
import liteon from './customIcon/logo-liteon-csms-xsmall.svg';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const logo = <Box aria-label="Logo" component="img" src={liteon} sx={{ pl: '29px' }} />;

  return <>{logo}</>;
}
