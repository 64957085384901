import React, { useState, useEffect } from 'react';
// mui
import { TextField, Stack, Box, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function SelectPeriod({ formData, setFormData, evseName }) {
  const { t } = useTranslation();

  const handleChange1 = (newValue) => {
    const formattedDate = format(newValue.$d, 'yyyy-MM-dd');
    setFormData({ ...formData, dateStart: formattedDate });
  };

  const handleChange2 = (newValue) => {
    const formattedDate = format(newValue.$d, 'yyyy-MM-dd');
    setFormData({ ...formData, dateEnd: formattedDate });
  };

  return (
    <Box>
      <Typography>
        {t('tit_selected_DiagnosticsReport', { name: evseName })}
        {/* {evseName} is selected to retrieve Diagnostics Report. */}
      </Typography>
      <br />
      <Typography>
        {t('tit_Select_period')}
        {/* Select period to retrieve the Diagnostics Report. */}
      </Typography>
      <br />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack spacing={2} direction="row" alignItems="flex-end">
          <DesktopDatePicker
            inputFormat="YYYY/MM/DD"
            value={formData.dateStart}
            onChange={handleChange1}
            renderInput={(params) => <TextField {...params} size="small" sx={{ width: 'auto' }} />}
          />
          <Typography>to</Typography>
          <DesktopDatePicker
            inputFormat="YYYY/MM/DD"
            value={formData.dateEnd}
            onChange={handleChange2}
            renderInput={(params) => <TextField {...params} size="small" sx={{ width: 'auto' }} />}
          />
        </Stack>
      </LocalizationProvider>
    </Box>
  );
}
