import { useState, useEffect } from 'react';
// form
import { useForm, Controller } from 'react-hook-form';
// mui
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Stack,
  Dialog,
  Avatar,
  Tooltip,
  TextField,
  IconButton,
  DialogTitle,
  Autocomplete,
  DialogContent,
} from '@mui/material';
// components
import LoginAgain from '../../../components/LoginAgain';
import { FormProvider } from '../../../components/hook-form';
import Icon from '../../../components/customIcon/Union.svg';
import { buttonR, buttonL } from '../../../components/button/buttonStyle';
import CloseIcon from '../../../components/customIcon/Close.svg';
import { fetchCS, SubmitAdminEditDialog } from '../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function AdminEditDialog(props) {
  const { t } = useTranslation();
  const { info } = props;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(info.name);
  const [employee_id, setEmployee_id] = useState(info.employee_id);
  const [charging_stations, setCharging_station_id] = useState(info.charging_station_name);
  const [all_charging_station, setAll_charging_station] = useState([]);
  const [unauthorized, setUnauthorized] = useState(false);
  const [id] = useState(Number(info.id));
  const email = info.email;

  useEffect(() => {
    fetchCS(setError, setErrorMessage, setUnauthorized).then((promiseResult) => setAll_charging_station(promiseResult));
  }, []);

  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  const handleClickOpen = () => {
    setOpen(true);
    setError(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    setLoading(true);
    const intId = Number(id);
    const charging_station_id = charging_stations.map(station => station.charging_station)
    SubmitAdminEditDialog(email, name, employee_id, charging_station_id, intId, setError, setErrorMessage).then(
      (promiseResult) => {
        if (promiseResult.category === 'Success') {
          props.setSuccess(true);
          props.EditUpdate(Math.random());
          handleClose();
        } else {
          props.setErrorMessage(promiseResult.message);
          props.setError(true);
        }
        setLoading(false);
      }
    );
  };

  // submit form
  const methods = useForm({
    reValidateMode: 'onBlur',
  });

  const { control } = methods;

  return (
    <Box>
      <Tooltip title={t('Edit')}>
        <IconButton onClick={handleClickOpen} sx={{ width: '35px', height: '35px', zIndex: 'modal' }}>
          <Box component="img" src={Icon} sx={{ width: '100%', height: '100%' }} />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} aria-labelledby="EditAdminDialog">
        {unauthorized && <LoginAgain />}
        <DialogTitle id="EditAdminDialog">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('Edit Admin')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="10vh">
            <IconButton color="primary" aria-label={t('upload picture')} component="label">
              <input hidden accept="image/*" type="file" />
              <Avatar
                alignItems="center"
                justifyContent="center"
                src="/broken-image.jpg"
                Alt="Profile Picture"
                sx={{ m: 4, width: 80, height: 80 }}
              />
            </IconButton>
          </Box>

          <FormProvider methods={methods}>
            <Stack spacing={3} direction={{ xs: 'column' }} width={500}>
              <TextField
                name="name"
                margin="dense"
                label={t('Admin Name')}
                onChange={(e) => setName(e.target.value)}
                value={name}
                fullWidth
                variant="outlined"
              />
              <TextField
                name="employee_id"
                margin="dense"
                label={t('Employee ID')}
                onChange={(e) => setEmployee_id(e.target.value)}
                value={employee_id}
                fullWidth
                variant="outlined"
              />
              <TextField
                name="id"
                margin="dense"
                label={t('ID')}
                value={id}
                fullWidth
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
              <Controller
                control={control}
                name="charging_station_name"
                render={({ field: { ref, onChange, ...field } }) => (
                  <Autocomplete
                    multiple
                    defaultValue={charging_stations}
                    options={all_charging_station}
                    getOptionLabel={(option) => option.charging_station_name}
                    onChange={(_, data) => onChange(setCharging_station_id(data))}
                    renderInput={(params) => (
                      <TextField
                        {...field}
                        {...params}
                        fullWidth
                        inputRef={ref}
                        variant="outlined"
                        label={t('Charging Station(s)')}
                      />
                    )}
                  />
                )}
              />
              <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <LoadingButton variant="outlined" onClick={handleClose} sx={buttonL}>
                  {t('Cancel')}
                </LoadingButton>
                <LoadingButton onClick={onSubmit} loading={loading} sx={buttonR}>
                  {t('Edit')}
                </LoadingButton>
              </Stack>
            </Stack>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
