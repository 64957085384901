import React, { useState, useEffect } from 'react';
// mui
import {
  Box,
  Stack,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Tooltip,
  MenuItem,
  Checkbox,
  FormControl,
  FormControlLabel,
  Select,
  InputLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Icon from '../../../../../../../components/customIcon/icon-add-24.svg';
import { buttonR, buttonL } from '../../../../../../../components/button/buttonStyle';
import CloseIcon from '../../../../../../../components/customIcon/Close.svg';
import { SubmitAddCS } from '../../../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function AddNewEVSE(props) {
  const { stationName, stationid } = props;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [evse, setEvse] = useState();
  const [evse_id, setEvse_id] = useState();
  const { t } = useTranslation();
  const [evseConnected, setEvseConnected] = useState(true);
  const [selectedOcppVersion, setSelectedOcppVersion] = useState(true);

  const [formData, setFormData] = useState({
    evse: '',
    ocpp_id: '',
    power_type: '',
    max_voltage: '',
    max_amperage: '',
    max_electric_power: '',
    connector_type: '',
    connector_format: '',
    capability: '',
    parking_restrictions: '',
    ocpi_physical_reference: '',
    floor_level: '',
    tariff: '',
  });
  const [errors, setErrors] = useState({
    evse: '',
    ocpp_id: '',
    power_type: '',
    max_voltage: '',
    max_amperage: '',
  });

  const powerTypeArr = [
    { label: t('AC') + '_' + t('1-Phase'), value: 'AC_1_PHASE' },
    { label: t('AC') + '_' + t('2-Phase'), value: 'AC_2_PHASE' },
    { label: t('AC') + '_' + t('2-Phase-Split'), value: 'AC_2_PHASE_SPLIT' },
    { label: t('AC') + '_' + t('3-Phase'), value: 'AC_3_PHASE' },
    { label: t('DC'), value: 'DC' },
  ];

  const connectorTypeArr = [
    { label: t('CHADEMO'), value: 'CHADEMO' },
    { label: t('CHAOJI'), value: 'CHAOJI' },
    { label: t('GBT') + '_' + t('AC'), value: 'GBT_AC' },
    { label: t('GBT') + '_' + t('DC'), value: 'GBT_DC' },
    { label: t('IEC') + '_' + t('62196') + '_' + t('T1'), value: 'IEC_62196_T1' },
    { label: t('IEC') + '_' + t('62196') + '_' + t('T1') + '_' + t('COMBO'), value: 'IEC_62196_T1_COMBO' },
    { label: t('IEC') + '_' + t('62196') + '_' + t('T2'), value: 'IEC_62196_T2' },
    { label: t('IEC') + '_' + t('62196') + '_' + t('T2') + '_' + t('COMBO'), value: 'IEC_62196_T2_COMBO' },
    { label: t('PANTOGRAPH') + '_' + t('BOTTOM') + '_' + t('UP'), value: 'PANTOGRAPH_BOTTOM_UP' },
    { label: t('PANTOGRAPH') + '_' + t('BOTTOM') + '_' + t('DOWN'), value: 'PANTOGRAPH_BOTTOM_DOWN' },
    { label: t('TESLA') + '_' + t('R'), value: 'TESLA_R' },
    { label: t('TESLA') + '_' + t('S'), value: 'TESLA_S' },
  ];

  const connectorFormatArr = [
    { label: t('SOCKET'), value: 'SOCKET' },
    { label: t('CABLE'), value: 'CABLE' },
  ];

  const capabilityArr = [
    { label: t('CHARGING') + '_' + t('PROFILE') + '_' + t('CAPABLE'), value: 'CHARGING_PROFILE_CAPABLE' },
    { label: t('CHARGING') + '_' + t('PREFERENCES') + '_' + t('CAPABLE'), value: 'CHARGING_PREFERENCES_CAPABLE' },
    { label: t('CHIP') + '_' + t('CARD') + '_' + t('SUPPORT'), value: 'CHIP_CARD_SUPPORT' },
    { label: t('CONTACTLESS') + '_' + t('CARD') + '_' + t('SUPPORT'), value: 'CONTACTLESS_CARD_SUPPORT' },
    { label: t('CREDIT') + '_' + t('CARD') + '_' + t('PAYABLE'), value: 'CREDIT_CARD_PAYABLE' },
    { label: t('DEBIT') + '_' + t('CARD') + '_' + t('PAYABLE'), value: 'DEBIT_CARD_PAYABLE' },
    { label: t('PED') + '_' + t('TERMINAL'), value: 'PED_TERMINAL' },
    { label: t('REMOTE') + '_' + t('START') + '_' + t('STOP') + '_' + t('CAPABLE'), value: 'REMOTE_START_STOP_CAPABLE' },
    { label: t('RESERVABLE'), value: 'RESERVABLE' },
    { label: t('RFID') + '_' + t('READER'), value: 'RFID_READER' },
    { label: t('START') + '_' + t('SESSION') + '_' + t('CONNECTOR') + '_' + t('REQUIRED'), value: 'START_SESSION_CONNECTOR_REQUIRED' },
    { label: t('TOKEN') + '_' + t('GROUP') + '_' + t('CAPABLE'), value: 'TOKEN_GROUP_CAPABLE' },
    { label: t('UNLOCK') + '_' + t('CAPABLE'), value: 'UNLOCK_CAPABLE' },
  ];

  const parkingRestrictionsArr = [
    { label: t('EV') + '_' + t('ONLY'), value: 'EV_ONLY' },
    { label: t('PLUGGED'), value: 'PLUGGED' },
    { label: t('DISABLED'), value: 'DISABLED' },
    { label: t('CUSTOMERS'), value: 'CUSTOMERS' },
    { label: t('MOTORCYCLES'), value: 'MOTORCYCLES' },
  ];

  const ocppVersion = [
    { label: t('ocpp1.6'), value: 'ocpp1.6' },
    { label: t('ocpp2.0.1'), value: 'ocpp2.0.1' },
  ]
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };


  const cs_name = stationid;
  useEffect(() => {
    setFormData({
      evse: '',
      ocpp_id: '',
    });
    setErrors({
      evse: '',
      ocpp_id: '',
    });
  }, []);
  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFormData({
      evse: '',
      ocpp_id: '',
    });
    setErrors({
      evse: '',
      ocpp_id: '',
    });
    setEvse_id('');
    setEvse('');
    setOpen(false);
  };

  const onSubmit = () => {
    // Prepare the payload
    const payload = {
      cp_id: formData.evse,
      cs_id: cs_name,
      connector:[
        {
          connector_type: formData.connector_type,
          connector_format: formData.connector_format,
          power_type: formData.power_type,
          max_voltage: parseInt(formData.max_voltage),
          max_amperage: parseInt(formData.max_amperage),
          max_electric_power: parseInt(formData.max_electric_power),
          tariff: Array.isArray(formData.tariff) ? formData.tariff : [formData.tariff],
        }
      ],
      capability: Array.isArray(formData.capability) ? formData.capability : [formData.capability],
      parking_restrictions: formData.parking_restrictions,
      ocpi_physical_reference: formData.ocpi_physical_reference,
      floor_level: formData.floor_level,
      ocppVersion: formData.ocppVersion,
    }
    setLoading(false);
    // not empty verify
    let hasError = false;

    for (const key in formData) {
      const er = errors;
      if (formData.hasOwnProperty(key) && formData[key] === '') {
        er[key] = t('required fields');
        setErrors({ ...errors, [key]: t('required fields') });
        hasError = true;
      }
    }
    // do not submit
    if (hasError) {
      return;
    }    
    setLoading(true);

    const cp_id = payload.cp_id;
    const cs_id = payload.cs_id;
    const connector = payload.connector;
    const capability = payload.capability;
    const parking_restrictions = payload.parking_restrictions;
    const ocpi_physical_reference = payload.ocpi_physical_reference;
    const floor_level = payload.floor_level;
    const ocppVersion = payload.ocppVersion;

    SubmitAddCS(cp_id, cs_id, connector, capability, parking_restrictions, ocpi_physical_reference, floor_level, ocppVersion, setError, setErrorMessage).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        props.setSuccess(true);
        props.Close(false);
        handleClose();
        setEvse_id();
        setEvse();
      } else {
        props.setErrorMessage(promiseResult.message);
        props.setError(true);
      }
      setLoading(false);
    });
  };

  return (
    <Box>
      <Tooltip title={t('Add')}>
        <IconButton onClick={handleClickOpen} sx={{ width: '38px', height: '38px', zIndex: 'modal' }}>
          <Box component="img" src={Icon} sx={{ width: '100%', height: '100%' }} />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} aria-labelledby="AddNewUserDialog">
        <DialogTitle id="AddNewUserDialog">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('EVSE')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" justifyContent="flex-start" spacing={2} sx={{ width: 450 }}>
            <TextField
              margin="dense"
              name="evse"
              label={
                <>
                  <span style={{ color: 'red' }}>* </span>
                  {t('Name of EVSE')}
                </>
              }
              onChange={(e) => {
                setFormData({ ...formData, evse: e.target.value });
                handleChange(e);
              }}
              value={formData.evse}
              error={!!errors.evse}
              helperText={errors.evse}
              fullWidth
              variant="outlined"
            />
            <TextField
              margin="dense"
              name="ocpp_id"
              label={
                <>
                  <span style={{ color: 'red' }}>* </span>
                  {t('OCPP EVSE ID')}
                </>
              }
              onChange={(e) => {
                setFormData({ ...formData, ocpp_id: e.target.value });
                handleChange(e);
              }}
              error={!!errors.ocpp_id}
              helperText={errors.ocpp_id}
              value={formData.ocpp_id}
              fullWidth
              variant="outlined"
            />
            <TextField
              margin="dense"
              type="number"
              name="max_voltage"
              label={
                <>
                  <span style={{ color: 'red' }}>* </span>
                  {t('Max Voltage')}
                </>
              }
              onChange={(e) => {
                setFormData({ ...formData, max_voltage: e.target.value });
                handleChange(e);
              }}
              error={!!errors.max_voltage}
              helperText={errors.max_voltage}
              value={formData.max_voltage}
              fullWidth
              variant="outlined"
            />
            <TextField
              margin="dense"
              type="number"
              name="max_amperage"
              label={
                <>
                  <span style={{ color: 'red' }}>* </span>
                  {t('Max Amperage')}
                </>
              }
              onChange={(e) => {
                setFormData({ ...formData, max_amperage: e.target.value });
                handleChange(e);
              }}
              error={!!errors.max_amperage}
              helperText={errors.max_amperage}
              value={formData.max_amperage}
              fullWidth
              variant="outlined"
            />

            <TextField
              margin="dense"
              type="number"
              name="max_electric_power"
              label={t('Max Electric Power')}
              onChange={(e) => {
                setFormData({ ...formData, max_electric_power: e.target.value });
                handleChange(e);
              }}
              // error={!!errors.max_electric_power}
              // helperText={errors.max_electric_power}
              value={formData.max_electric_power}
              fullWidth
              variant="outlined"
            />

            <TextField
              margin="dense"
              name="ocpi_physical_reference"
              label={t('OCPI Physical Reference')}
              onChange={(e) => {
                setFormData({ ...formData, ocpi_physical_reference: e.target.value });
                handleChange(e);
              }}
              value={formData.ocpi_physical_reference}
              fullWidth
              variant="outlined"
            />

            <TextField
              margin="dense"
              name="floor_level"
              label={t('Floor Level')}
              onChange={(e) => {
                setFormData({ ...formData, floor_level: e.target.value });
                handleChange(e);
              }}
              value={formData.floor_level}
              fullWidth
              variant="outlined"
            />

            <TextField
              margin="dense"
              select
              fullWidth
              name="capability"
              label={
                <>
                  <span style={{ color: 'red' }}>* </span>
                  {t('Capability')}
                </>
              }
              value={formData.capability}
              onChange={(e) => {
                setFormData({ ...formData, capability: e.target.value });
                handleChange(e);
              }}
              size="small"
              error={!!errors.capability}
              helperText={errors.capability}
            >
              {capabilityArr.map((data, index) => {
                return (
                  <MenuItem key={data.label} value={data.value}>
                    {data.label}
                  </MenuItem>
                );
              })}
            </TextField>

            <TextField
              margin="dense"
              select
              fullWidth
              name="parking_restrictions"
              label={t('Parking Restrictions')}
              value={formData.parking_restrictions}
              onChange={(e) => {
                setFormData({ ...formData, parking_restrictions: e.target.value });
                handleChange(e);
              }}
              size="small"
            >
              {parkingRestrictionsArr.map((data, index) => {
                return (
                  <MenuItem key={data.label} value={data.value}>
                    {data.label}
                  </MenuItem>
                );
              })}
            </TextField>

            <TextField
              margin="dense"
              select
              fullWidth
              name="power_type"
              label={
                <>
                  <span style={{ color: 'red' }}>* </span>
                  {t('Power Type')}
                </>
              }
              value={formData.power_type}
              onChange={(e) => {
                setFormData({ ...formData, power_type: e.target.value });
                handleChange(e);
              }}
              size="small"
              error={!!errors.power_type}
              helperText={errors.power_type}
            >
              {powerTypeArr.map((data, index) => {
                return (
                  <MenuItem key={data.label} value={data.value}>
                    {data.label}
                  </MenuItem>
                );
              })}
            </TextField>

            <TextField
              margin="dense"
              select
              fullWidth
              name="connector_type"
              label={
                <>
                  <span style={{ color: 'red' }}>* </span>
                  {t('Connector Type')}
                </>
              }
              value={formData.connector_type}
              onChange={(e) => {
                setFormData({ ...formData, connector_type: e.target.value });
                handleChange(e);
              }}
              size="small"
              error={!!errors.connector_type}
              helperText={errors.connector_type}
            >
              {connectorTypeArr.map((data, index) => {
                return (
                  <MenuItem key={data.label} value={data.value}>
                    {data.label}
                  </MenuItem>
                );
              })}
            </TextField>

            <TextField
              margin="dense"
              select
              fullWidth
              name="connector_format"
              label={
                <>
                  <span style={{ color: 'red' }}>* </span>
                  {t('Connector Format')}
                </>
              }
              value={formData.connector_format}
              onChange={(e) => {
                setFormData({ ...formData, connector_format: e.target.value });
                handleChange(e);
              }}
              size="small"
              error={!!errors.connector_format}
              helperText={errors.connector_format}
            >
              {connectorFormatArr.map((data, index) => {
                return (
                  <MenuItem key={data.label} value={data.value}>
                    {data.label}
                  </MenuItem>
                );
              })}
            </TextField>

            <TextField
              margin="dense"
              type="number"
              name="tariff"
              label={
                <>
                  <span style={{ color: 'red' }}>* </span>
                  {t('Connector Tariff ID')}
                </>
              }
              onChange={(e) => {
                setFormData({ ...formData, tariff: e.target.value });
                handleChange(e);
              }}
              error={!!errors.tariff}
              helperText={errors.tariff}
              value={formData.tariff}
              fullWidth
              variant="outlined"
            />
            
            <FormControlLabel
              control={
                <Checkbox
                  checked={evseConnected}
                  onChange={(e) => setEvseConnected(e.target.checked)}
                  name="evseConnected"
                  colour="primary"
                />
              }
              label="Is EVSE Connected Right Now?"
            />
            {!evseConnected && (
              <FormControl variant="outlined" fullWidth margon="dense">
                <InputLabel id="ocpp-version-label">Your EVSE's OCPP Version</InputLabel>
                <Select
                  labelId='ocpp-version-label'
                  id="ocpp-version"
                  value={selectedOcppVersion}
                  onChange={(e) => {
                    setSelectedOcppVersion(e.target.value);
                    setFormData({...formData, ocppVersion: e.target.value});
                  }}
                  label="Your EVSE's OCPP Version"
                >
                  {ocppVersion.map((version) => (
                    <MenuItem key={version.value} value={version.value}>
                      {version.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
              <LoadingButton variant="outlined" onClick={handleClose} sx={buttonL}>
                {t('Cancel')}
              </LoadingButton>
              <LoadingButton onClick={onSubmit} loading={loading} sx={buttonR}>
                {t('Add')}
              </LoadingButton>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
