import React, { useEffect, useState } from 'react';
import { styled, alpha, useTheme } from '@mui/material/styles';
// mui
import {
  Box,
  Alert,
  Table,
  Button,
  Snackbar,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  InputLabel,
  IconButton,
  FormControl,
  TableFooter,
  OutlinedInput,
  InputAdornment,
  TablePagination,
  LinearProgress,
  tableCellClasses,
} from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
// component
import useRefreshToken from '../../../hooks/useRefreshToken';
import LoginAgain from '../../../components/LoginAgain';
import Icon from '../../../components/customIcon/icon-search-16.svg';
import Export from '../../../components/customIcon/icon-export-18.svg';
import { utils, writeFile } from 'xlsx';
import { fetchSessionLog } from '../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function SessionTable() {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [sessionRows, setSessionRows] = useState([]);
  const [unauthorized, setUnauthorized] = useState(false);
  const columns = [
    { field: 'id', headerName: t('No.'), width: 30 },
    { field: 'user_id', headerName: t('Mobile ID'), width: 100 },
    {
      field: 'name',
      headerName: t('Name'),
      width: 110,
    },
    {
      field: 'charging_station',
      headerName: t('Charging Station'),
      width: 130,
    },
    {
      field: 'evse_id',
      headerName: t('EVSE ID'),
      width: 80,
    },
    {
      field: 'start',
      headerName: t('Start'),
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value),
      width: 210,
    },
    {
      field: 'stop',
      headerName: t('Stop'),
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value),
      width: 210,
    },
    {
      field: 'duration',
      headerName: t('Duration'),
      width: 80,
    },
    {
      field: 'energy',
      headerName: t('Energy Charged'),
      width: 130,
    },
    {
      field: 'cost',
      headerName: t('Total Cost'),
      width: 100,
    },
  ];
  useEffect(() => {
    setLoading(true);
    fetchSessionLog(page, rowsPerPage, setUnauthorized, setErrorMessage, setError).then((promiseResult) => {setSessionRows(promiseResult);}).finally(() => {setLoading(false);});
  }, [page, rowsPerPage]);

  const filteredItems = getFilteredItems(query, sessionRows);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value, 10);
    setPage(0);
  };

  const handleExport = () => {
    const reordered = filteredItems.map((item) => ({
      No: item.id,
      MobileID: item.user_id,
      Name: item.name,
      ChargingStation: item.charging_station,
      EvseID: item.evse_id,
      Start: item.start,
      Stop: item.stop,
      Duration: item.duration,
      EnergyCharged: item.energy,
      TotalCost: item.cost,
    }));
    const wb = utils.book_new(),
      ws = utils.json_to_sheet(reordered);

    utils.book_append_sheet(wb, ws, 'MySheet1');
    writeFile(wb, 'MyExcel.xlsx');
  };

  // const refresh = useRefreshToken();

  return (
    <Box style={{ height: 660, width: '100%' }}>
      {unauthorized && <LoginAgain />}
      <Snackbar open={error} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>

      {loading && (
        <LinearProgress
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
          }}
        />
      )}
      
      <FormControl fullWidth sx={{ mr: 1, mb: 1, width: '100%' }}>
        <InputLabel htmlFor="outlined-adornment-search">{t('Search')}</InputLabel>
        <OutlinedInput
          id="outlined-adornment-search"
          startAdornment={
            <InputAdornment position="start">
              <Box component="img" src={Icon} sx={{ width: '16px', height: '16px' }} />
            </InputAdornment>
          }
          label={t('Search')}
          onChange={(event) => {
            setQuery(event.target.value);
          }}
          sx={{ height: '32px' }}
        />
      </FormControl>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{ color: '#F4F4F4', fontFamily: 'Arial', fontWeight: 700, fontSize: '14px', lineHeight: '22px' }}
              colSpan={3}
            >
              {t('Total_sessions', { total: sessionRows.length })}
              {/* Total Sessions: {sessionRows.length} */}
              <Button
                startIcon={<Box component="img" src={Export} sx={{ width: '16px', height: '16px', ml: 2 }} />}
                onClick={handleExport}
              >
                {t('Export')}
              </Button>
            </TableCell>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, { label: t('All'), value: -1 }]}
              count={filteredItems.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage={t('rows per page')}
              // SelectProps={{
              //   inputProps: {
              //     'aria-label': t('rows per page'),
              //   },
              //   native: true,
              // }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              // ActionsComponent={TablePaginationActions}
              ActionsComponent={(props) => <TablePaginationActions t={t} {...props} />}
              sx={{ color: '#F4F4F4', fontFamily: 'Arial', fontWeight: 400, fontSize: '14px', lineHeight: '22px' }}
            />
          </TableRow>
          <TableRow>
            {columns.map((column) => (
              <StyledTableCell align="left" key={column.headerName} sx={{ width: column.width }}>
                {column.headerName}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : filteredItems
          ).map((row, index) => (
            <StyledTableRow align="left" key={row.id} hover>
              <StyledTableCell sx={sx}>{index + 1}</StyledTableCell>
              <StyledTableCell sx={sx}>{row.user_id}</StyledTableCell>
              <StyledTableCell sx={sx}>{row.name}</StyledTableCell>
              <StyledTableCell sx={sx}>{row.charging_station}</StyledTableCell>
              <StyledTableCell sx={sx}>{row.evse_id}</StyledTableCell>
              <StyledTableCell sx={sx}>{row.start}</StyledTableCell>
              <StyledTableCell sx={sx}>{row.stop}</StyledTableCell>
              <StyledTableCell sx={sx}>{row.duration}</StyledTableCell>
              <StyledTableCell sx={sx}>{row.energy} kWh</StyledTableCell>
              <StyledTableCell sx={sx}>SGD ${row.cost}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, { label: t('All'), value: -1 }]}
              count={filteredItems.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage={t('rows per page')}
              // SelectProps={{
              //   inputProps: {
              //     'aria-label': t('rows per page'),
              //   },
              //   native: true,
              // }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              // ActionsComponent={TablePaginationActions}
              ActionsComponent={(props) => <TablePaginationActions t={t} {...props} />}
              sx={{ color: '#F4F4F4', fontFamily: 'Arial', fontWeight: 400, fontSize: '14px', lineHeight: '22px' }}
            />
          </TableRow>
        </TableFooter>
      </Table>
      {/* <button onClick={() => refresh()}>Refresh</button> */}
    </Box>
  );
}

function TablePaginationActions({ t, ...props }) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label={t('first page')}>
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label={t('previous page')}>
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('next page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('last page')}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const getFilteredItems = (query, sessionRows) => {
  if (!Array.isArray(sessionRows)){
    console.error("SessionRows is not an array:", sessionRows);
    return [];
  }
  if (!query) {
    return sessionRows;
  }
  const search = sessionRows.filter((session) => {
    const userId = session.user_id.toLowerCase().includes(query);
    const UserId = session.user_id.includes(query);
    const name = session.name.toLowerCase().includes(query);
    const Name = session.name.includes(query);
    const chargingStation = session.charging_station.toLowerCase().includes(query);
    const ChargingStation = session.charging_station.includes(query);
    const evseId = session.evse_id.toLowerCase().includes(query);
    const EvseId = session.evse_id.includes(query);
    const start = session.start.toLowerCase().includes(query);
    const Start = session.start.includes(query);
    const stop = session.stop.toLowerCase().includes(query);
    const Stop = session.stop.includes(query);
    const duration = session.duration.toLowerCase().includes(query);
    const Duration = session.duration.includes(query);
    const energy = session.energy.toLowerCase().includes(query);
    const Energy = session.energy.includes(query);
    const cost = session.cost.toLowerCase().includes(query);
    const Cost = session.cost.includes(query);
    return (
      Name ||
      name ||
      userId ||
      UserId ||
      chargingStation ||
      ChargingStation ||
      evseId ||
      EvseId ||
      start ||
      Start ||
      stop ||
      Stop ||
      duration ||
      Duration ||
      energy ||
      Energy ||
      cost ||
      Cost
    );
  });
  return search;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#17262A',
    color: '#F4F4F4',
    fontFamily: 'Arial',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '22px',
  },
  [`&.${tableCellClasses.body}`]: {
    color: '#F4F4F4',
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: alpha('#344955', 0.2),
  },
  '&:nth-of-type(even)': {
    backgroundColor: alpha('#344955', 0.2),
  },
}));

const sx = { pt: '3.5px', pb: '3.5px', height: '45px' };
