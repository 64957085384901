import React, { useEffect, useState } from 'react';
// mui
import { Stack, Typography, Card, CardContent, CardHeader, ListItem, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function Con_reservation({ defaultData, allData }) {
  const [formData, setFormData] = useState([]);
  const [objKeys, setObjKeys] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    // includes Reservation and expires
    if (defaultData) {
      setFormData(defaultData);
      // console.log(defaultData);
      setObjKeys(Object.keys(defaultData));
    }
  }, [defaultData]);
  function getDayType(daysArray) {
    // const weekdays = ['Friday', 'Monday', 'Thursday', 'Tuesday', 'Wednesday'];
    const weekdays = ['FRIDAY', 'MONDAY', 'THURSDAY', 'TUESDAY', 'WEDNESDAY'];
    //const weekends = ['Saturday', 'Sunday'];
    const weekends = ['SATURDAY', 'SUNDAY'];

    const sortedDays = [...daysArray].sort(); // Sort the days for comparison

    if (sortedDays.toString() === weekdays.toString()) {
      return 'weekdays';
    } else if (sortedDays.toString() === weekends.toString()) {
      return 'weekends';
    } else if (daysArray.length === 7) {
      return 'Every day';
    } else {
      return daysArray;
    }
  }
  return (
    <div>
      <Card variant="outlined">
        <CardContent>
          <h3>{t('Reservation')}</h3>
          {objKeys.map((obj, index) => (
            <div key={index}>
              {formData[obj].map((ele, ind) => {
                let sentence = '';

                if (ele.restrictions_show) {
                  if (ele.restrictions.day_of_week.length !== 0) {
                    let trans = getDayType(ele.restrictions.day_of_week);
                    if (trans == 'weekdays' || trans == 'weekends') {
                      sentence += ' ' + t('sentence_on') + ' ' + t(trans);
                    } else if (trans == 'Every day') {
                      sentence += ' ' + t('Every day');
                    } else {
                      sentence += ' ' + t('sentence_on') + ' ';
                      for (let index = 0; index < trans.length; index++) {
                        const element = trans[index];
                        sentence += t(element) + ',';
                      }
                    }
                  }
                  const start = ele.restrictions.start_time;
                  const end = ele.restrictions.end_time;

                  if (start && end && start !== '' && end !== '') {
                    sentence += ` ${t('between_and', { start, end })}`;
                  }
                }

                return (
                  <div key={ind}>
                    {sentence && (
                      <ul style={{ marginLeft: '20px' }}>
                        <li>{sentence}</li>
                      </ul>
                    )}
                    <ul style={{ marginLeft: '40px' }}>
                      <li key={ind}>
                        {ele.price_component[0].price} {allData.currency}{' '}
                        {ele.eleType === 'reservation'
                          ? obj === 'FLAT'
                            ? 'reservation fee (excl. VAT)'
                            : ' per hour (excl.VAT)'
                          : obj === 'FLAT'
                          ? 'reservation expiration fee (excl. VAT) (billed when a reservation expires and is not followed by a charging session)'
                          : 'per hour (excl.VAT) (billed when a reservation expires and is not followed by a charging session)'}
                      </li>
                    </ul>
                  </div>
                );
              })}
            </div>
          ))}

          {objKeys.map((obj, index) => (
            <React.Fragment key={index}>
              {obj === 'TIME' && formData.TIME ? (
                <div key={index}>
                  <ul style={{ marginLeft: '40px' }}>
                    <li>
                      {formData.TIME[0].price_component[0].vat} % {t('VAT')}
                    </li>
                    <li>
                      Billed per {parseFloat((formData.TIME[0].price_component[0].step_size / 60).toFixed(3))} min (
                      {formData.TIME[0].price_component[0].step_size} {t('seconds')})
                    </li>
                  </ul>
                </div>
              ) : obj === 'FLAT' && !formData.TIME ? (
                <div key={index}>
                  <ul style={{ marginLeft: '40px' }}>
                    <li>
                      {formData.FLAT[0].price_component[0].vat} % {t('VAT')}
                    </li>
                  </ul>
                </div>
              ) : (
                ''
              )}
            </React.Fragment>
          ))}
        </CardContent>
      </Card>
    </div>
  );
}
