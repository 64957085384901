import React, { useState, useEffect } from 'react';
// mui
import {
  Box,
  Step,
  Stack,
  Dialog,
  Stepper,
  MenuItem,
  StepLabel,
  IconButton,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import SelectEVSE from './SelectEVSE';
import ChangeAvailabilityPage from './ChangeAvailabilityPage';
import Confirmation from './Confirmation';
import { buttonR, buttonL } from '../../../../../../../../components/button/buttonStyle';
import CloseIcon from '../../../../../../../../components/customIcon/Close.svg';
import { fetchEVSECS, SubmitChangeAvailabilityCS } from '../../../../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function ChangeAvailability(props) {
  const { t } = useTranslation();

  const { stationID } = props;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [formData, setFormData] = useState({
    rows: [],
    checked: [],
    checkedStatus: [],
    status: '',
    when: 'ASAP',
    DateTime: false,
    valueDateTime: new Date(),
  });

  const charging_station = stationID;

  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  useEffect(() => {
    fetchEVSECS(charging_station, setError, setErrorMessage).then((promiseResult) =>
      setFormData({ ...formData, rows: promiseResult })
    );
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    setLoading(true);
    SubmitChangeAvailabilityCS(formData, setError, setErrorMessage).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        props.CAUpdate(Math.random());
        props.handleCloseMain();
        props.setSuccess(true);
        handleClose();
      } else {
        props.setErrorMessage(promiseResult.message);
        props.setError(true);
      }
      setLoading(false);
    });
  };

  const steps = [t('Select EVSE'), t('Change Availability'), t('Confirmation')];

  return (
    <div>
      <MenuItem onClick={handleClickOpen}>{t('Change Availability')}</MenuItem>
      <Dialog open={open} onClose={handleClose} aria-labelledby="ChangeAvailablity">
        <DialogTitle id="ChangeAvailablity">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('Change Availability')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
          <Stepper activeStep={page} sx={{ pt: 1 }}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{t(label)}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </DialogTitle>
        <DialogContent>
          <PageDisplay page={page} formData={formData} setFormData={setFormData} />
          <br />
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <LoadingButton
              variant="outlined"
              sx={buttonL}
              onClick={() => {
                if (page === 0) {
                  handleClose();
                } else {
                  setPage((currPage) => currPage - 1);
                }
              }}
            >
              {page === 0 ? t('Cancel') : t('Back')}
            </LoadingButton>
            {page === steps.length - 1 ? (
              <LoadingButton onClick={onSubmit} loading={loading} sx={buttonR}>
                {t('Submit')}
              </LoadingButton>
            ) : (
              <LoadingButton
                sx={buttonR}
                onClick={() => {
                  setPage((currPage) => currPage + 1);
                }}
              >
                {t('Next')}
              </LoadingButton>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const PageDisplay = ({ page, formData, setFormData }) => {
  if (page === 0) {
    return <SelectEVSE formData={formData} setFormData={setFormData} />;
  }
  if (page === 1) {
    return <ChangeAvailabilityPage formData={formData} setFormData={setFormData} />;
  }
  return <Confirmation formData={formData} setFormData={setFormData} />;
};
