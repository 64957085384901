import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
// mui
import { Box, Paper, Popper, Typography } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
// component
import LoginAgain from '../../../components/LoginAgain';
import { fetchOCPPLog } from '../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function LogTable() {
  const [pageSize, setPageSize] = useState(10);
  const [ocppRows, setOCPPRows] = useState([]);
  const [unauthorized, setUnauthorized] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();
  const columns = [
    { field: 'id', headerName: t('ID'), width: 60 },
    { field: 'msg_id', headerName: t('Message ID'), width: 120 },
    { field: 'type', headerName: t('Message Type'), width: 150, renderCell: renderCellExpand },
    { field: 'station', headerName: t('Station ID'), width: 120 },
    { field: 'location_name', headerName: t('Location'), width: 150, renderCell: renderCellExpand },
    { field: 'evse_id', headerName: t('EVSE ID'), width: 120 },
    {
      field: 'date',
      headerName: t('Date & Time'),
      width: 200,
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value),
    },
  ];

  useEffect(() => {
    fetchOCPPLog(setError, setErrorMessage, setUnauthorized).then((promiseResult) =>
      setOCPPRows(promiseResult.ocpp_data)
    );
    console.log(error);
    console.log(errorMessage);
  }, []);

  return (
    <Box sx={{ height: 660, width: '100%' }}>
      {unauthorized && <LoginAgain />}
      <DataGrid
        rows={ocppRows}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 25, 50, 100]}
        pagination
        align="center"
        components={{ Toolbar: GridToolbar }}
      />
    </Box>
  );
}

const GridCellExpand = function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = useRef(null);
  const cellDiv = useRef(null);
  const cellValue = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showFullCell, setShowFullCell] = useState(false);
  const [showPopper, setShowPopper] = useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: 'center',
        lineHeight: '24px',
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: '100%',
          width: '100%',
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <Box ref={cellValue} sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {value}
      </Box>
      {showPopper && (
        <Popper open={showFullCell && anchorEl !== null} anchorEl={anchorEl} style={{ width, marginLeft: -17 }}>
          <Paper elevation={1} style={{ minHeight: wrapper.current.offsetHeight - 3 }}>
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
};

GridCellExpand.propTypes = {
  value: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

function isOverflown(element) {
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

renderCellExpand.propTypes = {
  applyValue: PropTypes.func.isRequired,
  focusElementRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]),
  /**
   * The column of the row that the current cell belongs to.
   */
  colDef: PropTypes.object.isRequired,
  /**
   * The cell value.
   * If the column has `valueGetter`, use `params.row` to directly access the fields.
   */
  value: PropTypes.any,
};

function renderCellExpand(params) {
  return <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />;
}
