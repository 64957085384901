import React, { useState, useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
// mui
import {
  Box,
  Stack,
  Button,
  Dialog,
  Tooltip,
  MenuItem,
  TextField,
  IconButton,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../../../../../components/Iconify';
import { buttonR, buttonL } from '../../../../../../../components/button/buttonStyle';
import CloseIcon from '../../../../../../../components/customIcon/Close.svg';
import { SubmitEditStation } from '../../../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function EditChargingStation(props) {
  const { t } = useTranslation();
  const { stationData, locationName, url } = props;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [name, setName] = useState(stationData.name);

  const cs_id = stationData.cs_id;

  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    setLoading(true);
    SubmitEditStation(name, cs_id, setError, setErrorMessage).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        props.EditUpdate(Math.random());
        props.setSuccess(true);
        props.EditClose(false);
        props.closeAll();
        handleClose();
      } else {
        props.setErrorMessage(promiseResult.message);
        props.setError(true);
      }
      setLoading(false);
    });
  };

  return (
    <Box>
      <MenuItem onClick={handleClickOpen}>{t('Edit Info')}</MenuItem>
      <Dialog open={open} onClose={handleClose} aria-labelledby="EditChargingStationDialog">
        <DialogTitle id="EditChargingStation">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('edit_stationName', { locName: locationName })}
            {/* Edit Charging Station ({locationName}) */}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" justifyContent="flex-start" spacing={2} sx={{ width: 450 }}>
            <TextField
              margin="dense"
              name="name"
              label={t('name_station')} // "Name of Charging Station"
              onChange={(e) => setName(e.target.value)}
              value={name}
              fullWidth
              variant="outlined"
            />
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
              <TextField
                disabled
                margin="dense"
                name="url"
                label={t('URL_station')} //"URL for Connection of Charging Station"
                value={url}
                fullWidth
                variant="outlined"
              />
              <CopyToClipboard text={url} onCopy={() => setCopied(true)}>
                <Tooltip title={t('Copy')}>
                  <Button variant="outlined" sx={{ height: 52, borderColor: '#00B0B9', color: '#00B0B9' }}>
                    <Iconify icon="material-symbols:content-copy-rounded" width={22} height={22} />
                  </Button>
                </Tooltip>
              </CopyToClipboard>
            </Stack>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
              <LoadingButton variant="outlined" onClick={handleClose} sx={buttonL}>
                {t('Cancel')}
              </LoadingButton>
              <LoadingButton onClick={onSubmit} loading={loading} sx={buttonR}>
                {t('Save')}
              </LoadingButton>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
