import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// mui
import { Card, CardContent, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { useTranslation } from 'react-i18next';

function ChangeEmailForm() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(t('verify_emailAddress')).required(t('veify_email')),
  });

  const defaultValues = {
    email: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = () => {
    navigate('/pages/verificationform', { replace: true });
  };

  const handleClick = async () => {
    navigate('/pages/userprofile', { replace: true });
  };

  return (
    <Card sx={{ width: 500 }}>
      <CardContent>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h5" gutterBottom>
            {t('tip_enterEnter')}
            {/* Enter new Email to receive verification code: */}
          </Typography>
          <RHFTextField name="email" label="Email address" />
          <LoadingButton onClick={handleClick}>Cancel</LoadingButton>
          <LoadingButton type="submit" loading={isSubmitting}>
            {t('Confirm')}
          </LoadingButton>
        </FormProvider>
      </CardContent>
    </Card>
  );
}

export default ChangeEmailForm;
