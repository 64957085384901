import React, { useEffect, useState } from 'react';
import { styled, alpha, useTheme } from '@mui/material/styles';
// mui
import {
  Box,
  Alert,
  Table,
  Stack,
  Button,
  Snackbar,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Typography,
  InputLabel,
  IconButton,
  FormControl,
  TableFooter,
  OutlinedInput,
  InputAdornment,
  TablePagination,
  tableCellClasses,
} from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
// components
import AddNewAdminDialog from './AdminAddDialog';
import AdminEditDialog from './AdminEditDialog';
import AdminDelete from './AdminDelete';
import useRefreshToken from '../../../hooks/useRefreshToken';
import LoginAgain from '../../../components/LoginAgain';
import Icon from '../../../components/customIcon/icon-search-16.svg';
import Export from '../../../components/customIcon/icon-export-18.svg';
import { utils, writeFile } from 'xlsx';
import { fetchAdmin } from '../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function AdminTable() {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [admin, setAdmin] = useState([]);
  const [update, setUpdate] = useState();
  const [unauthorized, setUnauthorized] = useState(false);
  const refresh = useRefreshToken();
  const { t } = useTranslation();
  const columns = [
    { field: 'id', headerName: t('No.'), width: 50 },
    { field: 'name', headerName: t('Admin Name'), width: 200 },
    { field: 'employee_id', headerName: t('Employee ID'), width: 150 },
    { field: 'email', headerName: t('Email Address'), width: 200 },
    { field: 'charging_station_name', headerName: t('Managed Location(s)'), width: 200 },
    { field: 'action', headerName: t('Actions'), width: 200 },
  ];
  useEffect(() => {
    fetchAdmin(setErrorMessage, setError, setUnauthorized).then((promiseResult) => setAdmin(promiseResult));
  }, [update]);

  const filteredItems = getFilteredItems(query, admin);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setError(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value, 10);
    setPage(0);
  };

  const handleExport = () => {
    const reordered = filteredItems.map((item) => ({
      No: item.id,
      AdminName: item.name,
      EmployeeID: item.employee_id,
      Email: item.email,
      Location: item.charging_station_name.charging_station_name,
    }));
    const wb = utils.book_new(),
      ws = utils.json_to_sheet(reordered);

    utils.book_append_sheet(wb, ws, 'MySheet1');
    writeFile(wb, 'MyExcel.xlsx');
  };

  return (
    <Box sx={{ height: 660, width: '100%' }}>
      {unauthorized && <LoginAgain />}
      <Snackbar open={success} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert severity="success">{t('Success')}!</Alert>
      </Snackbar>
      <Snackbar open={error} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ pb: 1 }}>
        <FormControl fullWidth sx={{ mr: 1, width: '100%' }}>
          <InputLabel htmlFor="outlined-adornment-search">{t('Search')}</InputLabel>
          <OutlinedInput
            id="outlined-adornment-search"
            startAdornment={
              <InputAdornment position="start">
                <Box component="img" src={Icon} sx={{ width: '16px', height: '16px' }} />
              </InputAdornment>
            }
            label="Search"
            onChange={(event) => {
              setQuery(event.target.value);
            }}
            sx={{ height: '32px' }}
          />
        </FormControl>
        <AddNewAdminDialog AddUpdate={(childData) => setUpdate(childData)} />
      </Stack>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{ color: '#F4F4F4', fontFamily: 'Arial', fontWeight: 700, fontSize: '14px', lineHeight: '22px' }}
              colSpan={2}
            >
              {t('Total_admin', { length: admin.length })}
              {/* Total Admin: {admin.length} */}
              <Button
                startIcon={<Box component="img" src={Export} sx={{ width: '16px', height: '16px', ml: 2 }} />}
                onClick={handleExport}
              >
                {t('Export')}
              </Button>
            </TableCell>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, { label: t('All'), value: -1 }]}
              count={filteredItems.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage={t('rows per page')}
              // SelectProps={{
              //   inputProps: {
              //     'aria-label': t('rows per page'),
              //   },
              //   native: true,
              // }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              // ActionsComponent={TablePaginationActions}
              ActionsComponent={(props) => <TablePaginationActions t={t} {...props} />}
              sx={{ color: '#F4F4F4', fontFamily: 'Arial', fontWeight: 400, fontSize: '14px', lineHeight: '22px' }}
            />
          </TableRow>
          <TableRow>
            {columns.map((column) => (
              <StyledTableCell align="left" key={column.headerName} sx={{ width: column.width }}>
                {column.headerName}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : filteredItems
          ).map((row) => (
            <StyledTableRow align="left" key={row.id} hover>
              <StyledTableCell sx={sx}>{row.id}</StyledTableCell>
              <StyledTableCell sx={sx}>{row.name}</StyledTableCell>
              <StyledTableCell sx={sx}>{row.employee_id}</StyledTableCell>
              <StyledTableCell sx={sx}>{row.email}</StyledTableCell>
              <StyledTableCell sx={sx}>
                {row.charging_station_name
                  .map((row) => row.charging_station_name)
                  .map((value, index) => (
                    <Typography variant="inline" key={value}>
                      {value},{' '}
                    </Typography>
                  ))}
              </StyledTableCell>
              <StyledTableCell sx={sx}>
                <Stack direction="row" justifyContent="left" alignItems="center" spacing={0.5}>
                  <AdminEditDialog
                    key={row.id}
                    info={row}
                    EditUpdate={(childData) => setUpdate(childData)}
                    setSuccess={(childData) => setSuccess(childData)}
                    setErrorMessage={(childData) => setErrorMessage(childData)}
                    setError={(childData) => setError(childData)}
                  />
                  <AdminDelete
                    key={row.name}
                    info={row}
                    DeleteUpdate={(childData) => setUpdate(childData)}
                    setSuccess={(childData) => setSuccess(childData)}
                    setErrorMessage={(childData) => setErrorMessage(childData)}
                    setError={(childData) => setError(childData)}
                  />
                </Stack>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, { label: t('All'), value: -1 }]}
              count={filteredItems.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage={t('rows per page')}
              // SelectProps={{
              //   inputProps: {
              //     'aria-label': t('rows per page'),
              //   },
              //   native: true,
              // }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              // ActionsComponent={TablePaginationActions}
              ActionsComponent={(props) => <TablePaginationActions t={t} {...props} />}
              sx={{ color: '#F4F4F4', fontFamily: 'Arial', fontWeight: 400, fontSize: '14px', lineHeight: '22px' }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Box>
  );
}

function TablePaginationActions({ t, ...props }) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label={t('first page')}>
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label={t('previous page')}>
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('next page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('last page')}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const getFilteredItems = (query, admin) => {
  if (!query) {
    return admin;
  }
  const search = admin.filter((admin) => {
    const name = admin.name.toLowerCase().includes(query);
    const Name = admin.name.includes(query);
    const employeeId = admin.employee_id.toLowerCase().includes(query);
    const EmployeeId = admin.employee_id.includes(query);
    const email = admin.email.toLowerCase().includes(query);
    const Email = admin.email.includes(query);
    const location = admin.email.toLowerCase().includes(query);
    const Location = admin.email.includes(query);
    return Name || name || employeeId || EmployeeId || email || Email || location || Location;
  });
  return search;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#17262A',
    color: '#F4F4F4',
    fontFamily: 'Arial',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '22px',
  },
  [`&.${tableCellClasses.body}`]: {
    color: '#F4F4F4',
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: alpha('#344955', 0.2),
  },
  '&:nth-of-type(even)': {
    backgroundColor: alpha('#344955', 0.2),
  },
}));

const sx = { pt: '3.5px', pb: '3.5px' };
