import React, { useState, useEffect } from 'react';
// mui
import {
  Box,
  Grid,
  List,
  Paper,
  Button,
  ListItem,
  Checkbox,
  InputLabel,
  FormControl,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import Icon from '../../../../../../../../../components/customIcon/icon-search-16.svg';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function Tags({ formData, setFormData }) {
  const [userlist, setUserlist] = useState([]);
  const [checked, setChecked] = useState([]);
  const leftChecked = intersection(checked, userlist);
  const rightChecked = intersection(checked, formData.inserteduser);
  const [query, setQuery] = useState('');
  const filteredItems = getFilteredItems(query, userlist);
  const { t } = useTranslation();

  useEffect(() => {
    const user = formData.searchuser.filter(
      (obj) => !formData.inserteduser.some((item) => item.mobile_id === obj.mobile_id)
    );
    setUserlist(user);
  }, [query]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    setFormData({ ...formData, inserteduser: formData.inserteduser.concat(leftChecked) });
    setUserlist(not(filteredItems, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setUserlist(filteredItems.concat(rightChecked));
    setFormData({ ...formData, inserteduser: not(formData.inserteduser, rightChecked) });
    setChecked(not(checked, rightChecked));
  };

  const customList = (items) => (
    <Paper sx={{ width: 'auto', minWidth: 350, height: 300, overflow: 'auto', background: '#232F34' }}>
      <List dense component="div" role="list">
        {items?.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`Name: ${value.name}, Mobile ID: ${value.mobile_id}`} />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <FormControl fullWidth sx={{ width: '96%', mt: 3, ml: 2 }}>
        <InputLabel htmlFor="outlined-adornment-search">{t('Search')}</InputLabel>
        <OutlinedInput
          id="outlined-adornment-search"
          startAdornment={
            <InputAdornment position="start">
              <Box component="img" src={Icon} sx={{ width: '16px', height: '16px' }} />
            </InputAdornment>
          }
          label={t('Search')}
          onChange={(event) => {
            setQuery(event.target.value);
          }}
          sx={{ height: '32px' }}
        />
      </FormControl>
      <Grid item>{customList(filteredItems)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label={t('move selected right')}
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label={t('move selected left')}
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(formData.inserteduser)}</Grid>
    </Grid>
  );
}

const getFilteredItems = (query, userlist) => {
  if (!query) {
    return userlist;
  }
  const search = userlist.filter((user) => {
    const mobileId = user.mobile_id.toLowerCase().includes(query);
    const MobileId = user.mobile_id.includes(query);
    // const name = user.name.toLowerCase().includes(query);
    // const Name = user.name.includes(query);
    return MobileId || mobileId;
  });
  return search;
};

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}
