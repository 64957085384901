import React from 'react';
// mui
import { Box, Table, TableHead, TableBody, TableRow, TableCell, FormControlLabel, Checkbox } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function SelectFile({ formData, setFormData, list }) {
  const { t } = useTranslation();

  const handleCheckboxSelection = (option) => {
    setFormData({ ...formData, selectedOption: option });
  };

  const column = [
    {
      id: 'title',
      label: t('Uploaded firmware files'),
      align: 'left',
      width: 180,
    },
    // {
    //   id: 'option',
    //   label: 'Option',
    //   align: 'center',
    //   width: 120,
    // },
    {
      id: 'select',
      label: t('Select'),
      align: 'center',
      width: 90,
    },
  ];
  return (
    <Box>
      <Table aria-labelledby="tableTitles">
        <TableHead>
          <TableRow>
            {column.map((headCell) => (
              <TableCell key={headCell.id} align={headCell.align} padding="normal" sx={{ width: headCell.width }}>
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {list?.map((data) => (
            <TableRow hover key={data}>
              <TableCell align="left">{data}</TableCell>
              {/* <TableCell align="center">
                <IconButton aria-label={t("delete")}>
                  <DeleteIcon />
                </IconButton>
                <IconButton aria-label={t("edit")}>
                  <EditIcon />
                </IconButton>
              </TableCell> */}
              <TableCell align="right" sx={{ pr: 4 }}>
                <FormControlLabel
                  key={data}
                  control={
                    <Checkbox
                      checked={data === formData.selectedOption}
                      onChange={() => handleCheckboxSelection(data)}
                    />
                  }
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}
