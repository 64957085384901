import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
// mui
import { TextField, MenuItem, Switch, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Flat_advance from '../components/Flat_advance';
import ParkingTime_advance from '../components/ParkingTime_advance';
import Time_advance from '../components/Time_advance';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

// ----------------------------------------------------------------------

export default function Elements({ cRef, defaultData, handleRemoveForm, index }) {
  const { t } = useTranslation();
  const childRef_advance = useRef();
  const [errors, setErrors] = useState({
    step_size: '',
    type: '',
    price: '',
  });
  const [formData, setFormData] = useState({
    price_component: [
      {
        step_size: '',
        type: 'FLAT',
        vat: '0.00',
        price: '',
      },
    ],
    restrictions: { reservation: 'RESERVATION' },
    restrictions_show: false,
    eleType: 'reservation',
  });
  useEffect(() => {
    if (defaultData) {
      setFormData(defaultData);
    }
  }, [defaultData]);

  useImperativeHandle(cRef, () => ({
    getComponentData,
  }));
  // handleSubmit
  const getComponentData = (event) => {
    let hasError = false;
    let fd = formData.price_component[0];
    for (const key in fd) {
      const er = errors;
      if (fd.hasOwnProperty(key) && fd[key] === '' && er.hasOwnProperty(key)) {
        er[key] = t('required fields');
        setErrors({ ...errors, [key]: t('required fields') });
        hasError = true;
      }
    }
    formData.price_component[0].tariff_dimension_type = formData.price_component[0].type;
    // do not submit
    if (hasError) {
      return false;
    } else {
      if (formData.restrictions_show) {
        let advanceData = childRef_advance.current.getAdvanceData();
        advanceData.reservation = 'RESERVATION';
        formData.restrictions = advanceData;
      }
      return formData;
    }
    // console.log(formData); //get formData array
    // console.log(getDataHandler()); //electricity price array
  };
  const handleSwitch = (event) => {
    const val = event.target.checked;
    setFormData({ ...formData, restrictions_show: val });
    // setRestrictions(val);
  };
  const handleChange = (event) => {
    const val = event.target.value;
    const updatedPriceComponent = [
      {
        ...formData.price_component[0], // Keep other properties as they are
        type: val,
        // step_size: newStepSize,
        // price: newPrice,
      },
    ];
    setFormData({
      ...formData,
      price_component: updatedPriceComponent,
      restrictions_show: false,
    });

    setErrors({ ...errors, type: '' });
  };
  const remove = () => {
    handleRemoveForm(index, formData.id ? formData.id : null);
  };
  return (
    <div>
      <div style={{ float: 'right' }}>
        <RemoveCircleOutlineIcon style={{ cursor: 'pointer' }} title={t('Remove')} onClick={remove} />
      </div>
      <form onSubmit={getComponentData} style={{ background: '#232F34', padding: '10px', marginTop: '10px' }}>
        <TextField
          margin="dense"
          select
          fullWidth
          name="type"
          label={
            <>
              <span style={{ color: 'red' }}>* </span>
              {t('Type')}
            </>
          }
          value={formData.price_component[0].type}
          onChange={handleChange}
          size="small"
          error={!!errors.type}
          helperText={errors.type}
        >
          <MenuItem key="FLAT" value="FLAT">
            {t('Flat')}
          </MenuItem>
          <MenuItem key="TIME" value="TIME">
            {t('Time_type')}
          </MenuItem>
          {/* <MenuItem key="PARKING TIME" value="PARKING_TIME">
            {t('Parking Time')}
          </MenuItem>
          <MenuItem key="ENERGY" value="ENERGY">
            {t('Energy')}
          </MenuItem> */}
        </TextField>

        <TextField
          margin="dense"
          name="price"
          label={
            <>
              <span style={{ color: 'red' }}>* </span>
              {t('Price')}
            </>
          }
          value={formData.price_component[0].price}
          fullWidth
          onChange={(e) => {
            setFormData((prevFormData) => ({
              ...prevFormData,
              price_component: [
                {
                  ...prevFormData.price_component[0],
                  price: e.target.value,
                },
                ...prevFormData.price_component.slice(1), // Keep the rest of the array unchanged
              ],
            }));
            setErrors({ ...errors, price: '' });
          }}
          size="small"
          type="number"
          error={!!errors.price}
          helperText={errors.price}
        />
        <TextField
          margin="dense"
          name="vat"
          label={t('Tax')}
          fullWidth
          value={formData.price_component[0].vat}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          onChange={(e) => {
            setFormData((prevFormData) => ({
              ...prevFormData,
              price_component: [
                {
                  ...prevFormData.price_component[0],
                  vat: e.target.value,
                },
                ...prevFormData.price_component.slice(1), // Keep the rest of the array unchanged
              ],
            }));
            setErrors({ ...errors, vat: '' });
          }}
          size="small"
          type="number"
        />
        <TextField
          // disabled={formData.price_component[0].type === 'FLAT' ? true : false}
          margin="dense"
          name="step_size"
          fullWidth
          size="small"
          type="number"
          InputProps={{
            endAdornment:
              formData.price_component[0].type == 'FLAT'
                ? ''
                : formData.price_component[0].type == 'ENERGY'
                ? 'Wh'
                : 'sec',
          }}
          label={
            <>
              <span style={{ color: 'red' }}>* </span>
              {t('Step Size')}
            </>
          }
          value={formData.price_component[0].step_size}
          onChange={(e) => {
            setFormData((prevFormData) => ({
              ...prevFormData,
              price_component: [
                {
                  ...prevFormData.price_component[0],
                  step_size: e.target.value,
                },
                ...prevFormData.price_component.slice(1), // Keep the rest of the array unchanged
              ],
            }));
            setErrors({ ...errors, step_size: '' });
          }}
          error={!!errors.step_size}
          helperText={errors.step_size}
        />

        <div style={{ padding: '0' }}>
          <span style={{ marginTop: '5px' }}>{t('Advance Setting')}</span>
          <Switch
            checked={formData.restrictions_show}
            onChange={handleSwitch}
            // name="reservationExpires_show"
          />
        </div>
        <div style={{ padding: '0', display: formData.restrictions_show ? 'block' : 'none' }}>
          {formData.price_component[0].type === 'FLAT' ? (
            <Flat_advance adRef={childRef_advance} defaultData={defaultData} />
          ) : formData.price_component[0].type === 'PARKING_TIME' ? (
            <ParkingTime_advance adRef={childRef_advance} defaultData={defaultData} />
          ) : (
            <Time_advance adRef={childRef_advance} defaultData={defaultData} />
          )}
        </div>
      </form>
    </div>
  );
}
