import React, { useState, useEffect } from 'react';
// mui
import { Box, Card, Stack, Collapse, IconButton, Typography, Divider } from '@mui/material';
// component
import StationEVSE from './CP';
import StationChargingStationAddDialog from './adddialog/index';
import StationChargingStationEditDialog from './editdialog/index';
import StationChargingStationMenu from './menu/index';
import down from '../../../../../../components/customIcon/icon-expandedarrow-24.svg';
import right from '../../../../../../components/customIcon/icon-collapsedarrow-24.svg';
import { fetchEVSECSS } from '../../../../../../api/AllAPI';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

export default function StationTable(props) {
  const { dataforstation, locationName, url, updated } = props;
  const { t } = useTranslation();
  return (
    <Box>
      {dataforstation.map((station) => (
        <StationRow
          key={station.name}
          station={station}
          locationName={locationName}
          url={url}
          Close={(childData) => props.Close(childData)}
          setSuccess={(childData) => props.setSuccess(childData)}
          setErrorMessage={(childData) => props.setErrorMessage(childData)}
          setError={(childData) => props.setError(childData)}
          updated={updated}
          delayUpdate={(childData) => props.delayUpdate(childData)}
          Update={(childData) => props.Update(childData)}
        />
      ))}
    </Box>
  );
}

function StationRow(props) {
  const { station, locationName, url, updated } = props;
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();
  useEffect(() => {
    props.setError(error);
    props.setErrorMessage(errorMessage);
  }, [error]);

  const cs_name = station.name;

  useEffect(() => {
    fetchEVSECSS(cs_name, setError, setErrorMessage).then((promiseResult) => setData(promiseResult));
  }, []);

  const handleButton = () => {
    if (sessionStorage.getItem('role') === 'SuperAdmin') {
      return (
        <StationChargingStationAddDialog
          stationName={station.name}
          stationid={station.cs_id}
          Close={(childData) => props.Close(childData)}
          setSuccess={(childData) => props.setSuccess(childData)}
          setErrorMessage={(childData) => props.setErrorMessage(childData)}
          setError={(childData) => props.setError(childData)}
        />
      );
    }
    return;
  };

  return (
    <Card sx={{ mt: '15px', ml: '12px', mr: '5px', p: 1, boxShadow: 0, background: '#232F34' }}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
          <IconButton aria-label={t('expand row')} size="small" onClick={() => setOpen(!open)}>
            {open ? (
              <Box component="img" src={down} sx={{ width: '30px', height: '30px' }} />
            ) : (
              <Box component="img" src={right} sx={{ width: '30px', height: '30px' }} />
            )}
          </IconButton>
          <Typography
            sx={{
              fontFamily: 'Arial',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '22px',
              color: 'texts.main',
            }}
          >
            {station.name} <br />
            {station.address}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          {handleButton()}
          <StationChargingStationEditDialog
            locationName={locationName}
            evseData={station}
            stationData={station}
            Update={(childData) => props.Update(childData)}
            url={url}
            Close={(childData) => props.Close(childData)}
            setSuccess={(childData) => props.setSuccess(childData)}
            setErrorMessage={(childData) => props.setErrorMessage(childData)}
            setError={(childData) => props.setError(childData)}
          />
          <StationChargingStationMenu
            stationID={station.cs_id}
            setSuccess={(childData) => props.setSuccess(childData)}
            setErrorMessage={(childData) => props.setErrorMessage(childData)}
            setError={(childData) => props.setError(childData)}
            CAUpdate={(childData) => props.Update(childData)}
          />
        </Stack>
      </Stack>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ margin: 1 }}>
          <Divider />
          {data && (
            <StationEVSE
              dataforevse={data.evse}
              idfromdata={data}
              ogLocation={locationName}
              setSuccess={(childData) => props.setSuccess(childData)}
              setError={(childData) => props.setError(childData)}
              setErrorMessage={(childData) => props.setErrorMessage(childData)}
              updated={updated}
              Close={(childData) => props.Close(childData)}
              Update={(childData) => props.Update(childData)}
              delayUpdate={(childData) => props.delayUpdate(childData)}
            />
          )}
        </Box>
      </Collapse>
    </Card>
  );
}
