import React from 'react';
// mui
import {
  Stack,
  Radio,
  Select,
  MenuItem,
  TextField,
  RadioGroup,
  InputLabel,
  Typography,
  FormControl,
  FormControlLabel,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function ChangeAvailablityPage({ formData, setFormData }) {
  const { t } = useTranslation();

  const handleAvailability = (event) => {
    setFormData({ ...formData, status: event.target.value });
  };

  const handleChange = (event) => {
    setFormData({ ...formData, when: event.target.value });
  };

  const handleDate = (newValue) => {
    setFormData({ ...formData, valueDateTime: newValue });
  };

  return (
    <div>
      <Stack direction="column" spacing={3}>
        <Typography variant="body1">
          {t('selected_EVSE', { checkedleng: formData.checked.length })}
          {/* {formData.checked.length} EVSE(s) selected. */}
        </Typography>
        <FormControl fullWidth>
          <InputLabel id="select-choice">{t('Availability state')}</InputLabel>
          <Select
            id="select-choice"
            value={formData.status}
            label={t('Availability state')}
            onChange={handleAvailability}
          >
            <MenuItem value={'Available'}>{t('Available')}</MenuItem>
            <MenuItem value={'Unavailable'}>{t('Unavailable')}</MenuItem>
          </Select>
        </FormControl>
        <Typography variant="body1">
          {t('tit_ongoingCharging')}
          {/* There might be ongoing charging sessions. Changes will be applied after charging sessions ends. */}
        </Typography>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="timing-group-label"
            name="timing-group"
            value={formData.when}
            onChange={handleChange}
          >
            <FormControlLabel value="ASAP" control={<Radio />} label={t('As soon as possible')} />
            {/* <FormControlLabel value="SDAT" control={<Radio />} label="Specific date and time" /> */}
          </RadioGroup>
        </FormControl>
        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            disabled={formData.when === 'SDAT' ? false : true}
            label="Date&Time picker"
            value={formData.valueDateTime}
            onChange={handleDate}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider> */}
      </Stack>
      <br />
    </div>
  );
}
