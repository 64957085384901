export const buttonR = {
  background: 'linear-gradient(to left, #66D0D5, #00B0B9)',
  boxShadow: 'none',
  color: '#1E1E1E',
  fontFamily: 'Arial',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '22px',
  textAlign: 'center',
  height: '32px',
  width: '135px',
};

export const buttonL = {
  boxShadow: 'none',
  fontFamily: 'Arial',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '22px',
  textAlign: 'center',
  height: '32px',
  width: '135px',
};
