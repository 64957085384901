import React from 'react';
// mui
import { Stack, Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function Confirmation({ formData, evseName }) {
  const { t } = useTranslation();
  const column = [
    {
      id: 'name',
      label: t('Selected EVSE'),
      width: 110,
    },
    {
      id: 'connector',
      label: t('Connector'),
      width: 120,
    },
    {
      id: 'function',
      label: t('Function'),
      width: 110,
    },
  ];
  return (
    <Stack direction="column" spacing={3}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography variant="body1">{t('Select Connector')}</Typography>
        <Typography variant="body1">-----</Typography>
        <Typography variant="h6">{t('Confirmation')}</Typography>
      </Stack>
      <Typography variant="body1">
        {t('review_Unlock')}:{/* Please review the selected connector for Unlock to be carried out: */}
      </Typography>
      <Table aria-labelledby="tableTitles">
        <TableHead>
          <TableRow>
            {column.map((headCell) => (
              <TableCell key={headCell.id} align="left" padding="normal" sx={{ width: headCell.width }}>
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="left" padding="normal">
              {evseName}
            </TableCell>
            <TableCell>{formData.connectorName}</TableCell>
            <TableCell>{t('Unlock Connector')}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Stack>
  );
}
