import React, { useState } from 'react';
// mui
import { Container, Typography } from '@mui/material';
// components
import Page from '../../../components/Page';
import {
  deleteChargerPoint,
  getConfiguration,
  getDiagnostics,
  changeConfiguration,
  changeAvailability,
  remoteStart,
  remoteStop,
  sendReset,
  unlockConnector,
  getLocalList,
  sendLocalList,
  updateFirmware,
  reserveNow,
  cancelReservation,
  triggerMessage,
  setTxProfile,
  clearTxProfile,
  clearCache,
  sendDataTransfer,
  getCompositeSchedule,
  certificateSigned,
  installCertificate,
  getInstalledCertificateIds,
  deleteCertificate,
  extendedTriggerMessage,
  getLog,
  signedUpdateFirmware,
} from '../../../api/OCTT/OCTTAPI';

// ----------------------------------------------------------------------

export default function OCTT() {
  const connectIdValue = { connector_id: 1 };
  const unlockConnector1 = (index) => {
    console.log(connectIdValue);
    unlockConnector(connectIdValue).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const hardValue = { type: 'Hard' };
  const sendResetHard = (index) => {
    console.log(hardValue);
    sendReset(hardValue).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const emptyArray = {};
  const getEmptyLL = (index) => {
    console.log(emptyArray);
    getLocalList(emptyArray).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const idTag = {
    id_tag: '12345',
    connector_id: 1,
    charging_profile: null,
  };
  const remoteStartIdTag = (index) => {
    console.log(idTag);
    remoteStart(idTag).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const meterValue = {
    key: 'MeterValueSampleInterval',
    value: '1',
  };
  const changeConfigurationMeterValue = (index) => {
    console.log(meterValue);
    changeConfiguration(meterValue).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const listVersion = {
    list_version: 1,
    update_type: 'Full',
    local_authorization_list: [{ id_tag: '12345' }],
  };
  const sendLLVersionFull = (index) => {
    console.log(listVersion);
    sendLocalList(listVersion).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const firmware = {
    location: 'https://www.google.co.in',
    retrieve_date: '2020-06-19T09:10:00Z',
    retries: null,
    retry_interval: null,
  };
  const updatedFirmwareLocation = (index) => {
    console.log(firmware);
    updateFirmware(firmware).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const locationUrl = {
    location: 'URL',
    retries: 0,
    retry_interval: 0,
  };
  const getDiagnosticsUrl = (index) => {
    console.log(locationUrl);
    getDiagnostics(locationUrl).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const connectorId1 = {
    connector_id: 1,
    expiry_date: '2023-08-01T06:29:00Z',
    id_tag: '12345',
    reservation_id: '1',
    parent_id_tag: null,
  };
  const reserveConnector1 = (index) => {
    console.log(connectorId1);
    reserveNow(connectorId1).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const [formData1, setFormData1] = useState({
    charge_point_id: 'LOS4',
  });
  const TC_CS_V16_002 = (index) => {
    console.log(formData1);
    deleteChargerPoint(formData1).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const [formData2, setFormData2] = useState({
    charge_point_id: 'LOS4',
    key: null,
  });
  const TC_CS_V16_002_1 = (index) => {
    console.log(formData2);
    getConfiguration(formData2).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const [formData3, setFormData3] = useState({
    charge_point_id: 'LOS4',
    key: 'MeterValueSampleInterval',
    value: '10',
  });
  const TC_CS_V16_002_2 = (index) => {
    console.log(formData3);
    changeConfiguration(formData3).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const [formData8, setFormData8] = useState({
    transaction_id: 557,
  });
  const TC_CS_V16_012_2 = (index) => {
    const value = { transaction_id: parseInt(formData8.transaction_id, 10) };
    console.log(value);
    remoteStop(value).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData10 = { type: 'Soft' };
  const TC_CS_V16_014 = (index) => {
    console.log(formData10);
    sendReset(formData10).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData11 = { key: ['AuthorizeRemoteTxRequests'] };
  const TC_CS_V16_015_1 = (index) => {
    console.log(formData11);
    getConfiguration(formData11).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData19 = { key: null };
  const TC_CS_V16_019_1 = (index) => {
    console.log(formData19);
    getConfiguration(formData19).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData20 = { key: ['SupportedFeatureProfiles'] };
  const TC_CS_V16_019_2 = (index) => {
    console.log(formData20);
    getConfiguration(formData20).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData22 = {
    id_tag: 'VALID',
    connector_id: null,
    charging_profile: null,
  };
  const TC_CS_V16_026 = (index) => {
    console.log(formData22);
    remoteStart(formData22).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const [formData23, setFormData23] = useState({
    transaction_id: 8,
  });
  const TC_CS_V16_028 = (index) => {
    const value = { transaction_id: parseInt(formData23.transaction_id, 10) };
    console.log(value);
    remoteStop(value).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData32 = {
    list_version: 1,
    update_type: 'Full',
    local_authorization_list: [{ id_tag: '12345' }],
  };
  const TC_CS_V16_043_1 = (index) => {
    console.log(formData32);
    sendLocalList(formData32).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData36 = {
    list_version: 1,
    update_type: 'Differential',
    local_authorization_list: [{ id_tag: '12345' }],
  };
  const TC_CS_V16_043_5_2 = (index) => {
    console.log(formData36);
    sendLocalList(formData36).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData46 = {
    connector_id: 1,
    type: 'Inoperative',
  };
  const TC_CS_V16_048_3_1 = (index) => {
    console.log(formData46);
    changeAvailability(formData46).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData49 = {
    connector_id: 0,
    expiry_date: '2023-08-01T06:29:00Z',
    id_tag: '12345',
    reservation_id: '1',
    parent_id_tag: null,
  };
  const TC_CS_V16_049 = (index) => {
    console.log(formData49);
    reserveNow(formData49).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData51 = {
    connector_id: 1,
    expiry_date: '2023-08-01T06:29:00Z',
    id_tag: '12345',
    reservation_id: '1',
    parent_id_tag: null,
  };
  const TC_CS_V16_051_2 = (index) => {
    console.log(formData51);
    cancelReservation(formData51).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData53 = {
    connector_id: 1,
    expiry_date: '2023-08-01T06:29:00Z',
    id_tag: '12345',
    reservation_id: '2',
    parent_id_tag: null,
  };
  const TC_CS_V16_052_2 = (index) => {
    console.log(formData53);
    cancelReservation(formData53).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData54 = {
    connector_id: 1,
    expiry_date: '2023-08-01T06:29:00Z',
    id_tag: '12345',
    reservation_id: '1',
    parent_id_tag: 'PTAG',
  };
  const TC_CS_V16_053 = (index) => {
    console.log(formData54);
    reserveNow(formData54).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData55 = {
    requested_message: 'MeterValues',
    connector_id: null,
  };
  const TC_CS_V16_054_1 = (index) => {
    console.log(formData55);
    triggerMessage(formData55).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData56 = {
    requested_message: 'Heartbeat',
    connector_id: null,
  };
  const TC_CS_V16_054_2 = (index) => {
    console.log(formData56);
    triggerMessage(formData56).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData57 = {
    requested_message: 'StatusNotification',
    connector_id: null,
  };
  const TC_CS_V16_054_3 = (index) => {
    console.log(formData57);
    triggerMessage(formData57).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData58 = {
    requested_message: 'DiagnosticsStatusNotification',
    connector_id: null,
  };
  const TC_CS_V16_054_4 = (index) => {
    console.log(formData58);
    triggerMessage(formData58).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData59 = {
    requested_message: 'FirmwareStatusNotification',
    connector_id: null,
  };
  const TC_CS_V16_054_5 = (index) => {
    console.log(formData59);
    triggerMessage(formData59).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData60 = {
    requested_message: 'BootNotification',
    connector_id: null,
  };
  const TC_CS_V16_054_6 = (index) => {
    console.log(formData60);
    triggerMessage(formData60).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData61 = {
    requested_message: 'MeterValues',
    connector_id: 1,
  };
  const TC_CS_V16_055 = (index) => {
    console.log(formData61);
    triggerMessage(formData61).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData62 = {
    connector_id: 1,
    cs_charging_profiles: {
      charging_profile_id: 1,
      charging_profile_purpose: 'TxDefaultProfile',
      charging_profile_kind: 'Absolute',
      stack_level: 1,
      charging_schedule: {
        duration: 300,
        charging_rate_unit: 'W',
        charging_schedule_period: [
          {
            start_period: 1,
            limit: 0.1,
          },
        ],
      },
    },
  };
  const TC_CS_V16_056 = (index) => {
    console.log(formData62);
    setTxProfile(formData62).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const [formData63, setFormData63] = useState({
    connector_id: 1,
    cs_charging_profiles: {
      charging_profile_id: 1,
      charging_profile_purpose: 'TxProfile',
      charging_profile_kind: 'Absolute',
      stack_level: 1,
      transaction_id: 575,
      charging_schedule: {
        duration: 300,
        charging_rate_unit: 'W',
        charging_schedule_period: [
          {
            start_period: 1,
            limit: 0.1,
          },
        ],
      },
    },
  });
  const TC_CS_V16_057 = (index) => {
    const newValue = { ...formData63 };
    newValue.cs_charging_profiles.transaction_id = parseInt(formData63.cs_charging_profiles.transaction_id, 10);
    console.log(newValue);
    setTxProfile(newValue).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData64 = {
    id_tag: '12345',
    connector_id: 1,
    charging_profile: {
      charging_profile_id: 1,
      charging_profile_purpose: 'TxProfile',
      charging_profile_kind: 'Absolute',
      stack_level: 1,
      transaction_id: 533,
      charging_schedule: {
        duration: 300,
        charging_rate_unit: 'W',
        charging_schedule_period: [
          {
            start_period: 1,
            limit: 0.1,
          },
        ],
      },
    },
  };
  const TC_CS_V16_059 = (index) => {
    console.log(formData64);
    remoteStart(formData64).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const TC_CS_V16_061 = (index) => {
    console.log(emptyArray);
    clearCache(emptyArray).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData66 = {
    vendor_id: 'LOS',
    message_id: null,
    data: null,
  };
  const TC_CS_V16_062 = (index) => {
    console.log(formData66);
    sendDataTransfer(formData66).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData67 = {
    connector_id: 1,
    cs_charging_profiles: {
      charging_profile_id: 1,
      charging_profile_purpose: 'TxProfile',
      charging_profile_kind: 'Absolute',
      stack_level: 1,
      transaction_id: 533,
      charging_schedule: {
        duration: 300,
        charging_rate_unit: 'W',
        charging_schedule_period: [
          {
            start_period: 1,
            limit: 0.1,
          },
        ],
      },
    },
  };
  const TC_CS_V16_066_1 = (index) => {
    console.log(formData67);
    setTxProfile(formData67).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData68 = {
    connector_id: 0,
    duration: 300,
    charging_rate_unit: 'W',
  };
  const TC_CS_V16_066_2 = (index) => {
    console.log(formData68);
    getCompositeSchedule(formData68).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData69 = {
    id: null,
    connector_id: null,
    charging_profile_purpose: null,
    stack_level: null,
  };
  const TC_CS_V16_067 = (index) => {
    console.log(formData69);
    clearTxProfile(formData69).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData70 = {
    charge_point_id: 'LOS2',
    key: 'AuthorizationKey',
    value: '4F43415F4F4354545F61646D696E5F74657374',
  };
  const TC_CS_V16_073 = (index) => {
    console.log(formData70);
    changeConfiguration(formData70).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData71 = {
    requested_message: 'SignChargePointCertificate',
    connector_id: null,
  };
  const TC_CS_V16_074_1 = (index) => {
    console.log(formData71);
    extendedTriggerMessage(formData71).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData72 = {
    certificate_chain:
      '-----BEGIN CERTIFICATE-----\nMIIDdTCCAl2gAwIBAgIUeJesuPfkGx0sbYRq46JdaQ1AVqQwDQYJKoZIhvcNAQEL\nBQAwYjELMAkGA1UEBhMCSU4xFTATBgNVBAgTDFV0dGFyUHJhZGVzaDEOMAwGA1UE\nBxMFTm9pZGExDjAMBgNVBAoTBVZFTklEMQswCQYDVQQLEwJJVDEPMA0GA1UEAxMG\nR0xURVNUMB4XDTIzMDgwMzAxNDkwOVoXDTI0MDgwMjAxNDkwOVowYjELMAkGA1UE\nBhMCSU4xFTATBgNVBAgTDFV0dGFyUHJhZGVzaDEOMAwGA1UEBxMFTm9pZGExDjAM\nBgNVBAoTBVZFTklEMQswCQYDVQQLEwJJVDEPMA0GA1UEAxMGR0xURVNUMIIBIjAN\nBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA3TiwPcIEXCkX3ZXZY913d4ComVMm\neFOEavB6eJ5D1ciCo3KtRKZY0ANKXANLvuciL7Uw7749o+DJypG0e2M7i2BkLFm5\nm5/paxmPqKLNXzJp5Zr3cbB39V0xs3eEHIf+nezSjwhzf+3Pxw+xerQirLz/XFyN\nB2Ibd33UcsZMf387BtI6WpJWLtPw4Hzr+mU5/AylnDyGJDWM/mCDy1JQ8sWcwboa\ndwWE0CvotVoXgkRy9HdhxOJ4HbQb6u+xmLgVqycyPcXWDUhmP8gPUfK/Wy/GvrIC\nPRFcPpBbHBsNOv0zJpT1qj+F8Y7lJl4WUfwgDrH9sCPO+Zo+5s87lZEatQIDAQAB\noyMwITAMBgNVHRMBAf8EAjAAMBEGA1UdEQQKMAiCBkdMVEVTVDANBgkqhkiG9w0B\nAQsFAAOCAQEAkaxGYY+LuApLKlCizwt/m7AtTdkpqAJTbos2J3Wbtoq7Jsff5tvl\nCF3hnppr0O9fRRq3OIiRwdv0SUmkvNmaMVEh2AsilKsEDYfx71jKkax1YLOPe7Zb\ny1RBDzhargi8xdocdkh0PjKTjmzL/s5suDzwHBtorH6cQHVrqqONUpDilKQPNUiZ\ntJDmmP4CpUFmCSCMH/wZZN0wIZdcEXB8n5wAz5hSGbSkWCHIpKSjC7/zMcDX3afs\naE4QMdnhnei8JiM9SX0NJjonWmolfM3eCX/2GgvrIEYSn1igBvPbpVtNWQjoEgZc\nxhSR2C7UQf0zwfmmUU/NDTZ1D9/aZlc2wg==\n-----END CERTIFICATE-----\n',
  };
  const TC_CS_V16_074_2 = (index) => {
    console.log(formData72);
    certificateSigned(formData72).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData73 = {
    certificate_type: 'CentralSystemRootCertificate',
    certificate:
      '-----BEGIN CERTIFICATE-----\nMIIDcTCCAlmgAwIBAgIBATANBgkqhkiG9w0BAQsFADBZMRUwEwYDVQQDDAxPQ1RU\nIFJvb3QgQ0ExHTAbBgNVBAoMFE9wZW4gQ2hhcmdlIEFsbGlhbmNlMQswCQYDVQQG\nEwJOTDEUMBIGCgmSJomT8ixkARkWBE9DVFQwIBcNMjMwMTE5MTYwNTI3WhgPMjA2\nMzAxMDkxNjA1MjdaMFkxFTATBgNVBAMMDE9DVFQgUm9vdCBDQTEdMBsGA1UECgwU\nT3BlbiBDaGFyZ2UgQWxsaWFuY2UxCzAJBgNVBAYTAk5MMRQwEgYKCZImiZPyLGQB\nGRYET0NUVDCCASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEBAJogNiaZRbLY\np7QEgpWIJhGPeeSbDCQhW4ddlle8EJhQLThs/FHMiUSns8C27VFgB+5oRbJePvee\n2QHEGwgKnD2k91GOQlNf/ozeSIh4Xs8Za7xvGFmgRKB+V4O5bxb0zdAoIpCa6IBU\nn7r4l2B1xIDLSCw/4iHGUbi2Xn68RrjbwhLyZqtFi330Dar7CQ1X0+l4Dr8WiLq6\n6Ttzuehfh6X9NPWz7YZ+0w2yGpy03mMmq28ZfDYRGJUXo9sI1+D+NxdJawIeJNJy\nloZyyn8r7zYn7E9u1o2qvooY/guXB+hOOuBMaO0GmaLaKU/gkgda7A1440b0UD/0\ny14ZlP+U8KkCAwEAAaNCMEAwDwYDVR0TAQH/BAUwAwEB/zAOBgNVHQ8BAf8EBAMC\nAQYwHQYDVR0OBBYEFEIrcaC/rdvm9l5DIkUrgMNQQzM6MA0GCSqGSIb3DQEBCwUA\nA4IBAQBHoI/lOdBIqF4FRMHgn8lYFFzqHvCBklIttqbhOavcUwfspr0muuDXHjdt\nHzCfy3BhFADDc7NlhMWGTyeP4rHGOOkxl0vhtKHMOepEvrX5I+Yk/uWR8CHU7AAA\noIMi/7HHB7RTjJ7WFj1JSLZ8taHaQbGmTeEWdVmFF6hiqIN4XPFV4stLWH+aBSAG\nKfAVBAtwyPPrCl7lsCtL/uAjNThTO263kfNyOJCBzmmpASeLDJReca5ruqN5d7ob\nQt5KNu9WZir3k2eiTMlKdC1mFENVs4QXqCQiCg8reHnamVdBb6rIFNfpWPqo3F0h\nBQZpak/V4YyNs1GIznnSc1pjGD+I\n-----END CERTIFICATE-----',
  };
  const TC_CS_V16_075_1 = (index) => {
    console.log(formData73);
    installCertificate(formData73).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData74 = {
    certificate_type: 'CentralSystemRootCertificate',
  };
  const TC_CS_V16_075_2 = (index) => {
    console.log(formData74);
    getInstalledCertificateIds(formData74).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData75 = {
    certificate_hash_data: {
      hash_algorithm: 'SHA256',
      issuer_name_hash: 'de61235ee2958b67c7a47469cccb7ccbf77501d57af72c437ca092467fa00cac',
      issuer_key_hash: '1292cf3be39983c7b5254332d8674ba6a96268eebc14d598b56e0e4c090bc5c3',
      serial_number: '1',
    },
  };
  const TC_CS_V16_076_1 = (index) => {
    console.log(formData75);
    deleteCertificate(formData75).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData76 = {
    certificate_type: 'CentralSystemRootCertificate',
  };
  const TC_CS_V16_076_2 = (index) => {
    console.log(formData76);
    getInstalledCertificateIds(formData76).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData77 = {
    requested_message: 'SignChargePointCertificate',
    connector_id: null,
  };
  const TC_CS_V16_077_1 = (index) => {
    console.log(formData77);
    extendedTriggerMessage(formData77).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData78 = {
    certificate_chain:
      '-----BEGIN CERTIFICATE-----\nMIIDdTCCAl2gAwIBAgIUeJesuPfkGx0sbYRq46JdaQ1AVqQwDQYJKoZIhvcNAQEL\nBQAwYjELMAkGA1UEBhMCSU4xFTATBgNVBAgTDFV0dGFyUHJhZGVzaDEOMAwGA1UE\nBxMFTm9pZGExDjAMBgNVBAoTBVZFTklEMQswCQYDVQQLEwJJVDEPMA0GA1UEAxMG\nR0xURVNUMB4XDTIzMDgwMzAxNDkwOVoXDTI0MDgwMjAxNDkwOVowYjELMAkGA1UE\nBhMCSU4xFTATBgNVBAgTDFV0dGFyUHJhZGVzaDEOMAwGA1UEBxMFTm9pZGExDjAM\nBgNVBAoTBVZFTklEMQswCQYDVQQLEwJJVDEPMA0GA1UEAxMGR0xURVNUMIIBIjAN\nBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA3TiwPcIEXCkX3ZXZY913d4ComVMm\neFOEavB6eJ5D1ciCo3KtRKZY0ANKXANLvuciL7Uw7749o+DJypG0e2M7i2BkLFm5\nm5/paxmPqKLNXzJp5Zr3cbB39V0xs3eEHIf+nezSjwhzf+3Pxw+xerQirLz/XFyN\nB2Ibd33UcsZMf387BtI6WpJWLtPw4Hzr+mU5/AylnDyGJDWM/mCDy1JQ8sWcwboa\ndwWE0CvotVoXgkRy9HdhxOJ4HbQb6u+xmLgVqycyPcXWDUhmP8gPUfK/Wy/GvrIC\nPRFcPpBbHBsNOv0zJpT1qj+F8Y7lJl4WUfwgDrH9sCPO+Zo+5s87lZEatQIDAQAB\noyMwITAMBgNVHRMBAf8EAjAAMBEGA1UdEQQKMAiCBkdMVEVTVDANBgkqhkiG9w0B\nAQsFAAOCAQEAkaxGYY+LuApLKlCizwt/m7AtTdkpqAJTbos2J3Wbtoq7Jsff5tvl\nCF3hnppr0O9fRRq3OIiRwdv0SUmkvNmaMVEh2AsilKsEDYfx71jKkax1YLOPe7Zb\ny1RBDzhargi8xdocdkh0PjKTjmzL/s5suDzwHBtorH6cQHVrqqONUpDilKQPNUiZ\ntJDmmP4CpUFmCSCMH/wZZN0wIZdcEXB8n5wAz5hSGbSkWCHIpKSjC7/zMcDX3afs\naE4QMdnhnei8JiM9SX0NJjonWmolfM3eCX/2GgvrIEYSn1igBvPbpVtNWQjoEgZc\nxhSR2C7UQf0zwfmmUU/NDTZ1D9/aZlc2wg==\n-----END CERTIFICATE-----\n',
  };
  const TC_CS_V16_077_2 = (index) => {
    console.log(formData78);
    certificateSigned(formData78).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData79 = {
    certificate_type: 'CentralSystemRootCertificate',
    certificate:
      '-----BEGIN CERTIFICATE-----\nMIIDcTCCAlmgAwIBAgIBATANBgkqhkiG9w0BAQsFADBZMRUwEwYDVQQDDAxPQ1RU\nIFJvb3QgQ0ExHTAbBgNVBAoMFE9wZW4gQ2hhcmdlIEFsbGlhbmNlMQswCQYDVQQG\nEwJOTDEUMBIGCgmSJomT8ixkARkWBE9DVFQwIBcNMjMwMTE5MTYwNTI3WhgPMjA2\nMzAxMDkxNjA1MjdaMFkxFTATBgNVBAMMDE9DVFQgUm9vdCBDQTEdMBsGA1UECgwU\nT3BlbiBDaGFyZ2UgQWxsaWFuY2UxCzAJBgNVBAYTAk5MMRQwEgYKCZImiZPyLGQB\nGRYET0NUVDCCASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEBAJogNiaZRbLY\np7QEgpWIJhGPeeSbDCQhW4ddlle8EJhQLThs/FHMiUSns8C27VFgB+5oRbJePvee\n2QHEGwgKnD2k91GOQlNf/ozeSIh4Xs8Za7xvGFmgRKB+V4O5bxb0zdAoIpCa6IBU\nn7r4l2B1xIDLSCw/4iHGUbi2Xn68RrjbwhLyZqtFi330Dar7CQ1X0+l4Dr8WiLq6\n6Ttzuehfh6X9NPWz7YZ+0w2yGpy03mMmq28ZfDYRGJUXo9sI1+D+NxdJawIeJNJy\nloZyyn8r7zYn7E9u1o2qvooY/guXB+hOOuBMaO0GmaLaKU/gkgda7A1440b0UD/0\ny14ZlP+U8KkCAwEAAaNCMEAwDwYDVR0TAQH/BAUwAwEB/zAOBgNVHQ8BAf8EBAMC\nAQYwHQYDVR0OBBYEFEIrcaC/rdvm9l5DIkUrgMNQQzM6MA0GCSqGSIb3DQEBCwUA\nA4IBAQBHoI/lOdBIqF4FRMHgn8lYFFzqHvCBklIttqbhOavcUwfspr0muuDXHjdt\nHzCfy3BhFADDc7NlhMWGTyeP4rHGOOkxl0vhtKHMOepEvrX5I+Yk/uWR8CHU7AAA\noIMi/7HHB7RTjJ7WFj1JSLZ8taHaQbGmTeEWdVmFF6hiqIN4XPFV4stLWH+aBSAG\nKfAVBAtwyPPrCl7lsCtL/uAjNThTO263kfNyOJCBzmmpASeLDJReca5ruqN5d7ob\nQt5KNu9WZir3k2eiTMlKdC1mFENVs4QXqCQiCg8reHnamVdBb6rIFNfpWPqo3F0h\nBQZpak/V4YyNs1GIznnSc1pjGD+I\n-----END CERTIFICATE-----',
  };
  const TC_CS_V16_078 = (index) => {
    console.log(formData79);
    installCertificate(formData79).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData80 = {
    log: { remote_location: 'https://www.google.co.in' },
    log_type: 'SecurityLog',
    request_id: 1,
    retries: null,
    retry_interval: null,
  };
  const TC_CS_V16_079 = (index) => {
    console.log(formData80);
    getLog(formData80).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const formData81 = {
    request_id: 1,
    firmware: {
      location: '',
      retrieve_date_time: '2023-07-14T07:12:01.399591Z',
      signing_certificate:
        '-----BEGIN CERTIFICATE-----\nMIICUTCCAfugAwIBAgIBADANBgkqhkiG9w0BAQQFADBXMQswCQYDVQQGEwJDTjEL\nMAkGA1UECBMCUE4xCzAJBgNVBAcTAkNOMQswCQYDVQQKEwJPTjELMAkGA1UECxMC\nVU4xFDASBgNVBAMTC0hlcm9uZyBZYW5nMB4XDTA1MDcxNTIxMTk0N1oXDTA1MDgx\nNDIxMTk0N1owVzELMAkGA1UEBhMCQ04xCzAJBgNVBAgTAlBOMQswCQYDVQQHEwJD\nTjELMAkGA1UEChMCT04xCzAJBgNVBAsTAlVOMRQwEgYDVQQDEwtIZXJvbmcgWWFu\nZzBcMA0GCSqGSIb3DQEBAQUAA0sAMEgCQQCp5hnG7ogBhtlynpOS21cBewKE/B7j\nV14qeyslnr26xZUsSVko36ZnhiaO/zbMOoRcKK9vEcgMtcLFuQTWDl3RAgMBAAGj\ngbEwga4wHQYDVR0OBBYEFFXI70krXeQDxZgbaCQoR4jUDncEMH8GA1UdIwR4MHaA\nFFXI70krXeQDxZgbaCQoR4jUDncEoVukWTBXMQswCQYDVQQGEwJDTjELMAkGA1UE\nCBMCUE4xCzAJBgNVBAcTAkNOMQswCQYDVQQKEwJPTjELMAkGA1UECxMCVU4xFDAS\nBgNVBAMTC0hlcm9uZyBZYW5nggEAMAwGA1UdEwQFMAMBAf8wDQYJKoZIhvcNAQEE\nBQADQQA/ugzBrjjK9jcWnDVfGHlk3icNRq0oV7Ri32z/+HQX67aRfgZu7KWdI+Ju\nWm7DCfrPNGVwFWUQOmsPue9rZBgO\n-----END CERTIFICATE-----',
      signature: 'U2lnbmF0dXJl',
    },
    retries: null,
    retry_interval: null,
  };
  const TC_CS_V16_080 = (index) => {
    console.log(formData81);
    signedUpdateFirmware(formData81).then((promiseResult) => {
      console.log(promiseResult);
      if (promiseResult.status === 200) {
        const updatedSuccessStates = [...successStates];
        updatedSuccessStates[index] = true;
        setSuccessStates(updatedSuccessStates);
      }
    });
  };
  const [successStates, setSuccessStates] = useState(new Array(80).fill(false));

  return (
    <Page title={'octt'}>
      <Container maxWidth="false">
        <Typography
          sx={{
            fontFamily: 'Arial',
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '36px',
            color: 'texts.main',
            mb: 2,
          }}
        >
          OCTT
        </Typography>
        TC_CS_V16_002 (optional if not change ws ip to a new id){' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_002('1')} isSuccess={successStates[1]} />
        <br />
        <FormInput label="charge_point_id" formData={formData1} setFormData={setFormData1} />
        <br />
        TC_CS_V16_002_1 charge_point_id never register before{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_002_1('2')} isSuccess={successStates[2]} />
        <br />
        <FormInput label="charge_point_id" formData={formData2} setFormData={setFormData2} />
        <br />
        TC_CS_V16_002_2 charge_point_id never register before{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_002_2('3')} isSuccess={successStates[3]} />
        <br />
        <FormInput label="charge_point_id" formData={formData3} setFormData={setFormData3} />
        <br />
        TC_CS_V16_010 <ButtonInput name="Send" onClick={() => remoteStartIdTag('4')} isSuccess={successStates[4]} />
        <br />
        <br />
        TC_CS_V16_011_1 <ButtonInput name="Send" onClick={() => remoteStartIdTag('5')} isSuccess={successStates[5]} />
        <br />
        <br />
        TC_CS_V16_011_2 <ButtonInput name="Send" onClick={() => remoteStartIdTag('6')} isSuccess={successStates[6]} />
        <br />
        <br />
        TC_CS_V16_012 <ButtonInput
          name="Step 1"
          onClick={() => remoteStartIdTag('7')}
          isSuccess={successStates[7]}
        />{' '}
        <ButtonInput name="Step 2" onClick={() => TC_CS_V16_012_2('8')} isSuccess={successStates[8]} />
        <br />
        transaction_id:{' '}
        <input
          value={formData8.transaction_id}
          onChange={(event) => setFormData8({ ...formData8, transaction_id: event.target.value })}
        />
        <br />
        <br />
        TC_CS_V16_013 <ButtonInput name="Send" onClick={() => sendResetHard('9')} isSuccess={successStates[9]} />
        <br />
        <br />
        TC_CS_V16_014 <ButtonInput name="Send" onClick={() => TC_CS_V16_014('10')} isSuccess={successStates[10]} />
        <br />
        <br />
        TC_CS_V16_015 <ButtonInput
          name="Step 1"
          onClick={() => TC_CS_V16_015_1('11')}
          isSuccess={successStates[11]}
        />{' '}
        <ButtonInput name="Step 2" onClick={() => changeConfigurationMeterValue('12')} isSuccess={successStates[12]} />{' '}
        <ButtonInput name="Step 3" onClick={() => remoteStartIdTag('13')} isSuccess={successStates[13]} />{' '}
        <ButtonInput name="Step 4" onClick={() => sendResetHard('14')} isSuccess={successStates[14]} />
        <br />
        <br />
        TC_CS_V16_017_1 <ButtonInput name="Send" onClick={() => unlockConnector1('15')} isSuccess={successStates[15]} />
        <br />
        <br />
        TC_CS_V16_017_2 <ButtonInput name="Send" onClick={() => unlockConnector1('16')} isSuccess={successStates[16]} />
        <br />
        <br />
        TC_CS_V16_018_1{' '}
        <ButtonInput name="Step 1" onClick={() => remoteStartIdTag('17')} isSuccess={successStates[17]} />{' '}
        <ButtonInput name="Step 2" onClick={() => unlockConnector1('18')} isSuccess={successStates[18]} />
        <br />
        <br />
        TC_CS_V16_019_1 <ButtonInput name="Send" onClick={() => TC_CS_V16_019_1('19')} isSuccess={successStates[19]} />
        <br />
        <br />
        TC_CS_V16_019_2 <ButtonInput name="Send" onClick={() => TC_CS_V16_019_2('20')} isSuccess={successStates[20]} />
        <br />
        <br />
        TC_CS_V16_021{' '}
        <ButtonInput name="Send" onClick={() => changeConfigurationMeterValue('21')} isSuccess={successStates[21]} />
        <br />
        <br />
        TC_CS_V16_023_1 (Change IdTag 2 to ZYDX23)
        <br />
        <br />
        TC_CS_V16_023_2 (Change IdTag 2 to 66666)
        <br />
        <br />
        TC_CS_V16_023_3 (Change IdTag 2 to 55555)
        <br />
        <br />
        TC_CS_V16_026 <ButtonInput name="Send" onClick={() => TC_CS_V16_026('22')} isSuccess={successStates[22]} />
        <br />
        <br />
        TC_CS_V16_028 everytime test incremental 1{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_028('23')} isSuccess={successStates[23]} />
        <br />
        transaction_id:{' '}
        <input
          value={formData23.transaction_id}
          onChange={(event) => setFormData23({ ...formData23, transaction_id: event.target.value })}
        />
        <br />
        <br />
        TC_CS_V16_030 <ButtonInput name="Send" onClick={() => unlockConnector1('24')} isSuccess={successStates[24]} />
        <br />
        <br />
        TC_CS_V16_031 <ButtonInput name="Send" onClick={() => unlockConnector1('25')} isSuccess={successStates[25]} />
        <br />
        <br />
        TC_CS_V16_032_1 <ButtonInput name="Send" onClick={() => remoteStartIdTag('26')} isSuccess={successStates[26]} />
        <br />
        <br />
        TC_CS_V16_037_3 (Change IdTag 2 to 55555)
        <br />
        <br />
        TC_CS_V16_040_1{' '}
        <ButtonInput name="Send" onClick={() => changeConfigurationMeterValue('27')} isSuccess={successStates[27]} />
        <br />
        <br />
        TC_CS_V16_040_2{' '}
        <ButtonInput name="Send" onClick={() => changeConfigurationMeterValue('28')} isSuccess={successStates[28]} />
        <br />
        <br />
        TC_CS_V16_042_1{' '}
        <ButtonInput
          name="Step 1"
          onClick={() => changeConfigurationMeterValue('29')}
          isSuccess={successStates[29]}
        />{' '}
        <ButtonInput name="Step 2" onClick={() => getEmptyLL('30')} isSuccess={successStates[30]} />
        <br />
        <br />
        TC_CS_V16_042_2 <ButtonInput name="Send" onClick={() => getEmptyLL('31')} isSuccess={successStates[31]} />
        <br />
        <br />
        TC_CS_V16_043_1 <ButtonInput name="Send" onClick={() => TC_CS_V16_043_1('32')} isSuccess={successStates[32]} />
        <br />
        <br />
        TC_CS_V16_043_3{' '}
        <ButtonInput name="Send" onClick={() => sendLLVersionFull('33')} isSuccess={successStates[33]} />
        <br />
        <br />
        TC_CS_V16_043_4{' '}
        <ButtonInput name="Send" onClick={() => sendLLVersionFull('34')} isSuccess={successStates[34]} />
        <br />
        <br />
        TC_CS_V16_043_5 <ButtonInput
          name="Step 1"
          onClick={() => getEmptyLL('35')}
          isSuccess={successStates[35]}
        />{' '}
        <ButtonInput name="Step 2" onClick={() => TC_CS_V16_043_5_2('36')} isSuccess={successStates[36]} />
        <br />
        <br />
        TC_CS_V16_044_1{' '}
        <ButtonInput name="Send" onClick={() => updatedFirmwareLocation('37')} isSuccess={successStates[37]} />
        <br />
        <br />
        TC_CS_V16_044_2{' '}
        <ButtonInput name="Send" onClick={() => updatedFirmwareLocation('38')} isSuccess={successStates[38]} />
        <br />
        <br />
        TC_CS_V16_044_3{' '}
        <ButtonInput name="Send" onClick={() => updatedFirmwareLocation('39')} isSuccess={successStates[39]} />
        <br />
        <br />
        TC_CS_V16_045_1{' '}
        <ButtonInput name="Send" onClick={() => getDiagnosticsUrl('40')} isSuccess={successStates[40]} />
        <br />
        <br />
        TC_CS_V16_045_2{' '}
        <ButtonInput name="Send" onClick={() => getDiagnosticsUrl('41')} isSuccess={successStates[41]} />
        <br />
        <br />
        TC_CS_V16_046 <ButtonInput name="Send" onClick={() => reserveConnector1('42')} isSuccess={successStates[42]} />
        <br />
        <br />
        TC_CS_V16_047 <ButtonInput name="Send" onClick={() => reserveConnector1('43')} isSuccess={successStates[43]} />
        <br />
        <br />
        TC_CS_V16_048_1{' '}
        <ButtonInput name="Send" onClick={() => reserveConnector1('44')} isSuccess={successStates[44]} />
        <br />
        <br />
        TC_CS_V16_048_2{' '}
        <ButtonInput name="Send" onClick={() => reserveConnector1('45')} isSuccess={successStates[45]} />
        <br />
        <br />
        TC_CS_V16_048_3{' '}
        <ButtonInput name="Step 1" onClick={() => TC_CS_V16_048_3_1('46')} isSuccess={successStates[46]} />{' '}
        <ButtonInput name="Step 2" onClick={() => reserveConnector1('47')} isSuccess={successStates[47]} />
        <br />
        <br />
        TC_CS_V16_048_4{' '}
        <ButtonInput name="Send" onClick={() => reserveConnector1('48')} isSuccess={successStates[48]} />
        <br />
        <br />
        TC_CS_V16_049 <ButtonInput name="Send" onClick={() => TC_CS_V16_049('49')} isSuccess={successStates[49]} />
        <br />
        <br />
        TC_CS_V16_051{' '}
        <ButtonInput name="Step 1" onClick={() => reserveConnector1('50')} isSuccess={successStates[50]} />{' '}
        <ButtonInput name="Step 2" onClick={() => TC_CS_V16_051_2('51')} isSuccess={successStates[51]} />
        <br />
        <br />
        TC_CS_V16_052{' '}
        <ButtonInput name="Step 1" onClick={() => reserveConnector1('52')} isSuccess={successStates[52]} />{' '}
        <ButtonInput name="Step 2" onClick={() => TC_CS_V16_052_2('53')} isSuccess={successStates[53]} />
        <br />
        <br />
        TC_CS_V16_053 <ButtonInput name="Send" onClick={() => TC_CS_V16_053('54')} isSuccess={successStates[54]} />
        <br />
        <br />
        TC_CS_V16_054 <ButtonInput
          name="Step 1"
          onClick={() => TC_CS_V16_054_1('55')}
          isSuccess={successStates[55]}
        />{' '}
        <ButtonInput name="Step 2" onClick={() => TC_CS_V16_054_2('56')} isSuccess={successStates[56]} />{' '}
        <ButtonInput name="Step 3" onClick={() => TC_CS_V16_054_3('57')} isSuccess={successStates[57]} />{' '}
        <ButtonInput name="Step 4" onClick={() => TC_CS_V16_054_4('58')} isSuccess={successStates[58]} />{' '}
        <ButtonInput name="Step 5" onClick={() => TC_CS_V16_054_5('59')} isSuccess={successStates[59]} />{' '}
        <ButtonInput name="Step 6" onClick={() => TC_CS_V16_054_6('60')} isSuccess={successStates[60]} />
        <br />
        <br />
        TC_CS_V16_055 <ButtonInput name="Send" onClick={() => TC_CS_V16_055('61')} isSuccess={successStates[61]} />
        <br />
        <br />
        TC_CS_V16_056 <ButtonInput name="Send" onClick={() => TC_CS_V16_056('62')} isSuccess={successStates[62]} />
        <br />
        <br />
        TC_CS_V16_057 latest transaction_id from database{' '}
        <ButtonInput name="Send" onClick={() => TC_CS_V16_057('63')} isSuccess={successStates[63]} />
        <br />
        transaction_id:{' '}
        <input
          value={formData63.cs_charging_profiles.transaction_id}
          onChange={(event) => {
            const newFormData = { ...formData63 };
            newFormData.cs_charging_profiles.transaction_id = event.target.value;
            setFormData63(newFormData);
          }}
        />
        <br />
        <br />
        TC_CS_V16_059 <ButtonInput name="Send" onClick={() => TC_CS_V16_059('64')} isSuccess={successStates[64]} />
        <br />
        <br />
        TC_CS_V16_061 <ButtonInput name="Send" onClick={() => TC_CS_V16_061('65')} isSuccess={successStates[65]} />
        <br />
        <br />
        TC_CS_V16_062 <ButtonInput name="Send" onClick={() => TC_CS_V16_062('66')} isSuccess={successStates[66]} />
        <br />
        <br />
        TC_CS_V16_066 <ButtonInput
          name="Step 1"
          onClick={() => TC_CS_V16_066_1('67')}
          isSuccess={successStates[67]}
        />{' '}
        <ButtonInput name="Step 2" onClick={() => TC_CS_V16_066_2('68')} isSuccess={successStates[68]} />
        <br />
        <br />
        TC_CS_V16_067 <ButtonInput name="Send" onClick={() => TC_CS_V16_067('69')} isSuccess={successStates[69]} />
        <br />
        <br />
        TC_CS_V16_073 <ButtonInput name="Send" onClick={() => TC_CS_V16_073('70')} isSuccess={successStates[70]} />
        <br />
        <br />
        TC_CS_V16_074 <ButtonInput
          name="Step 1"
          onClick={() => TC_CS_V16_074_1('3')}
          isSuccess={successStates[3]}
        />{' '}
        <ButtonInput name="Step 2" onClick={() => TC_CS_V16_074_2('71')} isSuccess={successStates[71]} />
        <br />
        <br />
        TC_CS_V16_075 <ButtonInput
          name="Step 1"
          onClick={() => TC_CS_V16_075_1('72')}
          isSuccess={successStates[72]}
        />{' '}
        <ButtonInput name="Step 2" onClick={() => TC_CS_V16_075_2('73')} isSuccess={successStates[73]} />
        <br />
        <br />
        TC_CS_V16_076 <ButtonInput
          name="Step 1"
          onClick={() => TC_CS_V16_076_1('74')}
          isSuccess={successStates[74]}
        />{' '}
        <ButtonInput name="Step 2" onClick={() => TC_CS_V16_076_2('75')} isSuccess={successStates[75]} />
        <br />
        <br />
        TC_CS_V16_077 <ButtonInput
          name="Step 1"
          onClick={() => TC_CS_V16_077_1('76')}
          isSuccess={successStates[76]}
        />{' '}
        <ButtonInput name="Step 2" onClick={() => TC_CS_V16_077_2('77')} isSuccess={successStates[77]} />
        <br />
        <br />
        TC_CS_V16_078 <ButtonInput name="Send" onClick={() => TC_CS_V16_078('78')} isSuccess={successStates[78]} />
        <br />
        <br />
        TC_CS_V16_079 <ButtonInput name="Send" onClick={() => TC_CS_V16_079('79')} isSuccess={successStates[79]} />
        <br />
        <br />
        TC_CS_V16_080 <ButtonInput name="Send" onClick={() => TC_CS_V16_080('80')} isSuccess={successStates[80]} />
        <br />
      </Container>
    </Page>
  );
}

const ButtonInput = ({ name, onClick, isSuccess }) => {
  const buttonStyles = {
    backgroundColor: isSuccess ? 'green' : 'white',
    fontSize: '18px',
    padding: '7px 15px',
  };
  return (
    <>
      <button style={buttonStyles} onClick={onClick}>
        {name}
      </button>
    </>
  );
};

const FormInput = ({ label, formData, setFormData }) => {
  return (
    <>
      {label}:{' '}
      <input value={formData[label]} onChange={(event) => setFormData({ ...formData, [label]: event.target.value })} />
      <br />
    </>
  );
};
