import React, { useState } from 'react';
// mui
import {
  Box,
  Alert,
  Stack,
  Button,
  Dialog,
  Snackbar,
  TextField,
  IconButton,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { buttonR, buttonL } from '../../../components/button/buttonStyle';
import CloseIcon from '../../../components/customIcon/Close.svg';
import { SubmitUserAddDialog } from '../../../api/AllAPI';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function AddNewUserDialog(props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState();
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [rfid, setRfid] = useState(' ');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setError(false);
  };

  const onSubmit = () => {
    setLoading(true);
    console.log("<<rfid:",rfid);
    
    SubmitUserAddDialog(name, email, rfid, setError, setErrorMessage).then((promiseResult) => {
      if (promiseResult.category === 'Success') {
        setSuccess(true);
        props.AddUpdate(Math.random());
        handleClose();
        setName();
        setEmail();
        setRfid();
      } else {
        setErrorMessage(promiseResult.message);
        setError(true);
      }
      setLoading(false);
    });
  };

  return (
    <Box>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        sx={{
          width: '141px',
          height: '32px',
          background: 'linear-gradient(to left, #66D0D5, #00B0B9)',
          boxShadow: 'none',
          color: '#1E1E1E',
          fontFamily: 'Arial',
          fontWeight: 700,
          fontSize: '14px',
          lineHeight: '22px',
          textAlign: 'center',
        }}
      >
        + {t('Add New User')}
        <Snackbar open={success} autoHideDuration={5000} onClose={handleCloseSnackbar}>
          <Alert severity="success">Success!</Alert>
        </Snackbar>
        <Snackbar open={error} autoHideDuration={5000} onClose={handleCloseSnackbar}>
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="AddNewUserDialog" sx={{ zIndex: 2000 }}>
        <DialogTitle id="AddNewUserDialog">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {t('Add New User')}
            <IconButton aria-label={t('close')} onClick={handleClose}>
              <Box component="img" src={CloseIcon} sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" justifyContent="flex-start" spacing={2} sx={{ width: 450 }}>
            <TextField
              margin="dense"
              name="name"
              label={t('Name')}
              onChange={(e) => setName(e.target.value)}
              value={name}
              fullWidth
              variant="outlined"
            />
            <TextField
              name="email"
              margin="dense"
              label={t('Email address')}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              fullWidth
              variant="outlined"
            />
            <TextField
              margin="dense"
              id="rfid"
              label={t('RFID (Optional)')}
              onChange={(e) => setRfid(e.target.value)}
              value={rfid}
              fullWidth
              variant="outlined"
            />
            {/* <TextField
                id="status"
                select
                name="status"
                label="Status"
                fullWidth
                SelectProps={{ native: true }}
                variant="outlined"
              >
                {statusOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField> */}
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
              <Button variant="outlined" onClick={handleClose} sx={buttonL}>
                {t('Back')}
              </Button>
              <LoadingButton loading={loading} onClick={onSubmit} sx={buttonR}>
                {t('Add')}
              </LoadingButton>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
