import axios from '../api/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
  const { setAuth, auth } = useAuth();
  const refresh = async () => {
    const response = await axios.post(
      '/refresh_access_token/',
      {},
      {
        headers: { Authorization: `Bearer ${auth.refresh_token}` },
      }
    );
    setAuth((prev) => {
      console.log(JSON.stringify(prev));
      console.log(response.data.access_token);
      return { ...prev, access_token: response.data.access_token };
    });
    return response.data.access_token;
  };
  return refresh;
};

export default useRefreshToken;
