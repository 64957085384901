import React, { useEffect, useState } from 'react';
// mui
import { Stack, Typography, Card, CardContent, CardHeader, ListItem, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Time from './Time';
import Parking_time from './Parking_time';
import Energy from './Energy';
import Flat from './Flat';
import Reservation from './Reservation';
import Limit_min from './limit_min';
import Limit_max from './limit_max';
// ----------------------------------------------------------------------

export default function Confirmation({ defaultData }) {
  const [allData, setAllData] = useState({});
  const [groups, setGroups] = useState({});
  const [groupsArr, setGroupsArr] = useState([]);
  const [groups_reservation, setGroups_reservation] = useState({});
  const [groupsArr_reservation, setGroupsArr_reservation] = useState([]);
  const [groups_expires, setGroups_expires] = useState({});
  const [groupsArr_expires, setGroupsArr_expires] = useState([]);
  const [haslimit, sethaslimit] = useState(false);
  const [limitData, setlimitData] = useState({});
  const { t } = useTranslation();

  const processDataByType = (data, typeKey) => {
    const typeGroups = {};

    data.forEach((item) => {
      if (item.price_component) {
        const type = item.price_component[0].type;
        if (!typeGroups[type]) {
          typeGroups[type] = [];
        }
        typeGroups[type].push(item);
      }
    });

    const setGroupsFunc = `setGroups_${typeKey}`;
    const setGroupsArrFunc = `setGroupsArr_${typeKey}`;

    return {
      [setGroupsFunc]: typeGroups,
      [setGroupsArrFunc]: Object.keys(typeGroups),
    };
  };

  useEffect(() => {
    if (defaultData.elements) {
      setAllData(defaultData);

      const transData_element = defaultData.elements.filter((item) => item.eleType === 'element');
      const transData_reservation = defaultData.elements.filter((item) => item.eleType !== 'element');
      //   const transData_expires = defaultData.elements.filter((item) => item.eleType === 'expires');
      const elementData = processDataByType(transData_element, 'element');
      const reservationData = processDataByType(transData_reservation, 'reservation');
      //   const expiresData = processDataByType(transData_expires, 'expires');

      setGroups(elementData.setGroups_element);
      setGroupsArr(elementData.setGroupsArr_element);

      setGroups_reservation(reservationData.setGroups_reservation);
      setGroupsArr_reservation(reservationData.setGroupsArr_reservation);

      //   setGroups_expires(expiresData.setGroups_expires);
      //   setGroupsArr_expires(expiresData.setGroupsArr_expires);

      setlimitData({ max_price: defaultData.max_price, min_price: defaultData.min_price });
    }
  }, [defaultData.elements]);
  return (
    <div>
      {limitData.max_price &&
      limitData.max_price.excl_vat !== '' &&
      limitData.max_price.excl_vat !== '0.00' &&
      limitData.max_price.excl_vat !== 0 &&
      limitData.max_price.excl_vat !== null ? (
        <Limit_max defaultData={limitData} allData={allData}></Limit_max>
      ) : (
        ''
      )}
      {limitData.min_price &&
      limitData.min_price.excl_vat !== '' &&
      limitData.min_price.excl_vat !== '0.00' &&
      limitData.min_price.excl_vat !== 0 &&
      limitData.min_price.excl_vat !== null ? (
        <Limit_min defaultData={limitData} allData={allData}></Limit_min>
      ) : (
        ''
      )}

      {groupsArr.map((ele, ind) => {
        switch (ele) {
          case 'FLAT':
            return <Flat key={ind} defaultData={groups[ele]} allData={allData} />;
          case 'TIME':
            return <Time key={ind} defaultData={groups[ele]} allData={allData} />;
          case 'PARKING_TIME':
            return <Parking_time key={ind} defaultData={groups[ele]} allData={allData} />;
          case 'ENERGY':
            return <Energy key={ind} defaultData={groups[ele]} allData={allData} />;
          default:
            return null;
        }
      })}
      {groupsArr_reservation.length > 0 ? (
        <Reservation defaultData={groups_reservation} allData={allData}></Reservation>
      ) : (
        ''
      )}

      {/* {groupsArr_reservation.map((ele, ind) => {
        switch (ele) {
          case 'FLAT':
            return <Flat key={ind} defaultData={groups_reservation[ele]} allData={allData} />;
          case 'TIME':
            return <Time key={ind} defaultData={groups_reservation[ele]} allData={allData} />;
          default:
            return null;
        }
      })} */}
      {/* {groupsArr_expires.map((ele, ind) => {
        switch (ele) {
          case 'FLAT':
            return <Flat key={ind} defaultData={groups_expires[ele]} allData={allData} />;
          case 'TIME':
            return <Time key={ind} defaultData={groups_expires[ele]} allData={allData} />;
          default:
            return null;
        }
      })} */}
    </div>
  );
}
