// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'Home',
    path: '/pages/summary',
    icon: getIcon('ant-design:home-filled'),
  },
  {
    title: 'User Profile',
    path: '/pages/userprofile',
    icon: getIcon('bxs:user'),
  },
  // {
  //   title: 'Notifications & Alerts',
  //   path: '/pages/notification&alerts',
  //   icon: getIcon('clarity:notification-solid-badged'),
  // },
  {
    title: 'Change Password',
    path: '/pages/changepassword',
    icon: getIcon('teenyicons:password-solid'),
  },
  // {
  //   title: 'Change Email',
  //   path: '/pages/changeemail',
  //   icon: getIcon('teenyicons:password-solid'),
  // },
  // {
  //   title: 'Verification Form',
  //   path: '/pages/verificationform',
  //   icon: getIcon('teenyicons:password-solid'),
  // },
];

export default navConfig;
