import React from 'react';
// mui
import {
  Stack,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function SelectEVSE({ formData, setFormData, connector }) {
  const handleChange = (event) => {
    setFormData({ ...formData, selectedConnector: event.target.value });
  };
  const { t } = useTranslation();

  const column = [
    {
      id: 'name',
      label: t('Selected EVSE'),
    },
    {
      id: 'Connector',
      label: t('Connector'),
    },
    {
      id: 'status',
      label: t('Current Status'),
    },
  ];

  return (
    <div>
      <Stack direction="column" spacing={3}>
        <Table aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              {column.map((headCell) => (
                <TableCell key={headCell.id} align="left" padding="normal">
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {formData.rows.map((row) => {
              return (
                <TableRow hover key={row.name}>
                  <TableCell component="th" scope="row" padding="normal" sx={{ minWidth: 50 }}>
                    {formData.name}
                  </TableCell>
                  <TableCell align="left" sx={{ minWidth: 100 }}>
                    {`Connector ${row.connector_number}`}
                  </TableCell>
                  <TableCell align="left" sx={{ minWidth: 100 }}>
                    {row.status}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <FormControl fullWidth>
          <InputLabel id="Connector selected">{t('Connector selected')}</InputLabel>
          <Select
            id="select-choice"
            value={formData.selectedConnector}
            label={t('Connector selected')}
            onChange={handleChange}
          >
            {connector.map((rows) => (
              <MenuItem key={rows.value} value={rows.value}>
                {rows.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </div>
  );
}
