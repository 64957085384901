import React, { useEffect, useState } from 'react';
// mui
import { Stack, Typography, Card, CardContent, CardHeader, ListItem, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function Con_time({ defaultData, allData }) {
  const [formData, setFormData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (defaultData) {
      setFormData(defaultData);
      //   console.log(defaultData);
      //   console.log(allData);
    }
  }, [defaultData]);
  function getDayType(daysArray) {
    // const weekdays = ['Friday', 'Monday', 'Thursday', 'Tuesday', 'Wednesday'];
    const weekdays = ['FRIDAY', 'MONDAY', 'THURSDAY', 'TUESDAY', 'WEDNESDAY'];
    //const weekends = ['Saturday', 'Sunday'];
    const weekends = ['SATURDAY', 'SUNDAY'];

    const sortedDays = [...daysArray].sort(); // Sort the days for comparison

    if (sortedDays.toString() === weekdays.toString()) {
      return 'weekdays';
    } else if (sortedDays.toString() === weekends.toString()) {
      return 'weekends';
    } else if (daysArray.length === 7) {
      return 'Every day';
    } else {
      return daysArray;
    }
  }
  return (
    <div>
      {/* Flat start or transaction fee */}
      <Card variant="outlined">
        <CardContent>
          <h3>{t('Charging Time')}</h3>
          {formData.map((ele, ind) => {
            const content = ele.price_component[0];
            let sentence = '';

            if (ele.restrictions_show) {
              const typeMenu = {
                min_power: t('charging_more'),
                max_power: t('charging_less'),
                min_current: t('charging_more'),
                max_current: t('charging_less'),
                min_kwh: '',
                max_kwh: 'First',
              };

              const restrictionKeys = Object.keys(ele.restrictions);

              restrictionKeys.forEach((restrictionKey) => {
                if (typeMenu[restrictionKey] || typeMenu[restrictionKey] == '') {
                  const value = ele.restrictions[restrictionKey];
                  sentence += `${typeMenu[restrictionKey]} ${value} ${
                    restrictionKey === 'min_kwh'
                      ? 'kWh onwards'
                      : restrictionKey === 'min_current' || restrictionKey === 'max_current'
                      ? 'A'
                      : 'kWh'
                  } `;
                }
              });
              if (ele.restrictions.day_of_week.length !== 0) {
                let trans = getDayType(ele.restrictions.day_of_week);
                if (trans == 'weekdays' || trans == 'weekends') {
                  sentence += ' ' + t('sentence_on') + ' ' + t(trans);
                } else if (trans == 'Every day') {
                  sentence += ' ' + t('Every day');
                } else {
                  sentence += ' ' + t('sentence_on') + ' ';
                  for (let index = 0; index < trans.length; index++) {
                    const element = trans[index];
                    sentence += t(element) + ',';
                  }
                }
              }
              if (ele.restrictions.start_time !== null && ele.restrictions.end_time != null) {
                // sentence += ' between ' + ele.restrictions.start_time + ' and ' + ele.restrictions.end_time;
                sentence +=
                  ' ' + t('between_and', { start: ele.restrictions.start_time, end: ele.restrictions.end_time });
              }
            }

            return (
              <div key={ind} style={{ marginTop: '10px' }}>
                <ul style={{ marginLeft: '20px' }}>{sentence && <li>{sentence}</li>}</ul>
                <ul style={{ marginLeft: '40px' }}>
                  <li>
                    {content.price} {allData.currency} {t('per_hour')}
                    {/* {content.price} {allData.currency} per hour (excl.VAT) */}
                  </li>
                  <li>
                    {content.vat} % {t('VAT')}
                  </li>
                  <li>
                    Billed per {parseFloat((content.step_size / 60).toFixed(3))} min ({content.step_size} seconds)
                  </li>
                </ul>
              </div>
            );
          })}
        </CardContent>
      </Card>
    </div>
  );
}
