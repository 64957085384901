import React, { useEffect } from 'react';
// mui
import { Stack, Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function Confirmation({ formData, evseName }) {
  const { t } = useTranslation();
  const column1 = [
    {
      id: 'name',
      label: t('Selected EVSE'),
      width: 80,
    },
    {
      id: 'function',
      label: t('Function'),
      width: 100,
    },
    {
      id: 'period',
      label: t('Period'),
      width: 160,
    },
  ];
  return (
    <Stack direction="column" spacing={3}>
      <Typography variant="body1">
        {t('review_selectedDateRange')}
        {/* Please review the selected date range for request of Diagnostic Report to be carried out: */}
      </Typography>
      <Table aria-labelledby="tableTitles">
        <TableHead>
          <TableRow>
            {column1.map((headCell) => (
              <TableCell key={headCell.id} align="left" padding="normal" sx={{ width: headCell.width }}>
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow hover>
            <TableCell align="left" padding="normal">
              {evseName}
            </TableCell>
            <TableCell align="left" padding="normal">
              {t('Diagnostic Report')}
            </TableCell>
            <TableCell>
              {formData.dateStart} to {formData.dateEnd}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Stack>
  );
}
