import React, { useState, useRef, useEffect, useImperativeHandle } from 'react';
import { Button, Box } from '@mui/material';
import Elements from './Elements';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useTranslation } from 'react-i18next';

export default function Index({ cRef, defaultData, removeId, setRemoveId }) {
  const childRefs = useRef([]);
  const componentRefs = useRef([]);
  // const [componentCount, setComponentCount] = useState(1);
  const [components, setComponents] = useState([1]);
  const [addCount, setaddCount] = useState(1);
  const { t } = useTranslation();
  useImperativeHandle(cRef, () => ({
    getComponentData,
  }));
  const [formData, setFormData] = useState([]);
  const defaultDefaultData = {
    price_component: [
      {
        step_size: '',
        type: 'FLAT',
        vat: '0.00',
        price: '',
      },
    ],
    restrictions: { reservation: 'RESERVATION_EXPIRES' },
    restrictions_show: false,
    eleType: 'expires',
  };

  useEffect(() => {
    if (defaultData.elements) {
      const transData = defaultData.elements.filter((item) => item.eleType === 'expires');

      if (transData.length != 0) {
        const formDataArray = [];
        const componentsArray = [];

        transData.forEach((ele, index) => {
          formDataArray.push(ele);
          componentsArray.push(index + 1);
        });

        // Update formData and components arrays together
        setFormData(formDataArray);
        setComponents(componentsArray);
        // setComponentCount(componentsArray[componentsArray.length - 1]);
      }
    }
  }, [defaultData.elements]);
  // const handleAddForm = () => {
  //   setComponentCount(componentCount + 1);
  //   setComponents([...components, componentCount + 1]);
  // };

  // const handleRemoveForm = () => {
  //   if (componentCount > 1) {
  //     setComponentCount(componentCount - 1);
  //     setComponents(components.slice(0, -1));
  //     componentRefs.current.pop();
  //   }
  // };
  // const handleAddForm = () => {
  //   if (getComponentData()) {
  //     setaddCount(addCount + 1);
  //     setComponents([...components, addCount * 10]);
  //     setFormData([...formData, defaultDefaultData]);
  //   }
  // };
  const handleAddForm = () => {
    const getData = getComponentData();
    if (getData !== false) {
      setaddCount(addCount + 1);
      // setEnergyObj(false);
      setComponents([...components, addCount * 10]);
      setFormData([...getData, defaultDefaultData]);
    }
  };
  const handleRemoveForm = (index, id) => {
    if (components.length > 1) {
      const getData = getComponentData();

      if (getData !== false) {
        id ? setRemoveId([...removeId, id]) : null;
        setComponents(components.filter((_, i) => i !== index));
        componentRefs.current = componentRefs.current.filter((ref, i) => i !== index);

        const updatedFormData = getData.filter((_, i) => i !== index);
        setFormData(updatedFormData);
      }
    }
  };

  const getComponentData = () => {
    let formDataArray = [];
    let hasError = false;
    componentRefs.current.forEach((ref) => {
      if (!ref.current) return;
      const childData = ref.current.getComponentData();
      if (childData === false) {
        hasError = true;
      } else {
        formDataArray.push(childData);
      }
    });

    if (hasError) {
      return false;
    } else {
      return formDataArray;
    }
  };

  return (
    <div>
      <div>
        <span style={{ color: 'red' }}>* {t('Mandatory fields')}</span>
        <div style={{ float: 'right' }}>
          <AddCircleOutlineIcon style={{ cursor: 'pointer' }} title={t('Add')} onClick={handleAddForm} />
          {/* <RemoveCircleOutlineIcon style={{ cursor: 'pointer' }} title={t('Remove')} onClick={handleRemoveForm} /> */}
        </div>
      </div>
      {components.map((index, ind) => {
        const ref = React.createRef();
        componentRefs.current[index] = ref;
        return (
          <div key={ind}>
            <Elements
              key={ind}
              index={ind}
              handleRemoveForm={handleRemoveForm}
              defaultData={formData[ind]}
              componentIndex={ind}
              cRef={ref}
            />
          </div>
        );
      })}
    </div>
  );
}
