import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
// mui
import { Box, Card, Stack, LinearProgress, TablePagination, Snackbar, Alert } from '@mui/material';
// component
import Iconify from '../../../../components/Iconify';
import LoginAgain from '../../../../components/LoginAgain';
import TablePaginationActions from './pagination';
import { useTranslation } from 'react-i18next';
import Row from './tableRow';
import { tariffListFunction } from '../../../../api/AllAPI';

// ----------------------------------------------------------------------

let CollapsibleTable = ({ setSearchWord, searchWord }, ref) => {
  const { t, i18n } = useTranslation();
  const [dbdata, setDbdata] = useState([]);
  // const [update, setUpdate] = useState();
  const [unauthorized, setUnauthorized] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [Amount_data, setAmount_data] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setError(false);
  };

  const formatDataFunc = (transData) => {
    const newGetdata = [];
    for (let index = 0; index < transData.length; index++) {
      const transelement = transData[index];
      const getData = {};
      let eleData = [];
      const dataArr = Object.keys(transelement);
      for (let index = 0; index < dataArr.length; index++) {
        const element = dataArr[index];
        if (element !== 'element') {
          getData[element] = transelement[element];
        } else {
          eleData = transelement[element];
        }
      }
      getData.tariff_type = transelement.tariff_type ? transelement.tariff_type : null;
      getData.reservation_show = false;
      getData.reservationExpires_show = false;
      ///////////////
      getData.min_price = {
        excl_vat: getData.min_price_excl_vat ? getData.min_price_excl_vat : '0.00',
        incl_vat: getData.min_price_incl_vat ? getData.min_price_incl_vat : '0.00',
      };
      getData.max_price = {
        excl_vat: getData.max_price_excl_vat ? getData.max_price_excl_vat : '0.00',
        incl_vat: getData.max_price_incl_vat ? getData.max_price_incl_vat : '0.00',
      };
      // tariff_text
      if (transelement.tariff_text != 0) {
        getData.description = transelement.tariff_text[0].description;
      }

      for (let index = 0; index < transelement.tariff_text.length; index++) {
        const element = transelement.tariff_text[index];
        if (element.language === selectedLanguage) {
          getData.description = element.description;
        }
      }
      // tariff_text
      let getElesArrData = [];
      for (let index = 0; index < eleData.length; index++) {
        const ele = eleData[index];
        let obj = {
          eleType: 'element',
          price_component: [],
          id: ele.id ? ele.id : null,
          restrictions_show: false,
        };

        if (ele.reservation && ele.reservation === 'RESERVATION') {
          getData.reservation_show = true;
          obj.eleType = 'reservation';
        }
        if (ele.reservation && ele.reservation === 'RESERVATION_EXPIRES') {
          getData.reservationExpires_show = true;
          obj.eleType = 'expires';
        }
        obj.price_component = [
          {
            price: ele.price,
            step_size: ele.step_size,
            type: ele.tariff_dimension_type, ///////////////
            vat: ele.vat,
          },
        ];
        obj.restrictions = {
          end_date: ele.end_date || null,
          end_time: ele.end_time || null,
          start_date: ele.start_date || null,
          start_time: ele.start_time || null,
          day_of_week: ele.days || null,
        };
        obj.restrictions_show =
          ele.end_date !== null ||
          ele.end_time !== null ||
          ele.start_date !== null ||
          ele.start_time !== null ||
          ele.days.length !== 0;
        [
          'max_duration',
          'min_duration',
          'min_kwh',
          'max_kwh',
          'min_power',
          'max_power',
          'min_current',
          'max_current',
        ].forEach((property) => {
          if (ele[property]) {
            obj.restrictions[property] = ele[property];
            obj.restrictions_show = true;
          }
        });

        getElesArrData.push(obj);
        getData.elements = getElesArrData;
      }
      newGetdata.push(getData);
    }
    return newGetdata;
  };
  const [pageCount, setpageCount] = useState(0);
  useEffect(() => {
    if (pageCount !== 0) {
      update_List({ oprType: 'change' });
    } else {
      setpageCount(pageCount + 1);
    }
  }, [page, rowsPerPage]);

  const update_List = (dataType) => {
    const type = dataType.oprType;
    let data = {
      page_number: page + 1,
      page_size: rowsPerPage,
      name: searchWord,
    };
    switch (type) {
      case 'add':
        setPage(0);
        data.page_number = 1;
        setSearchWord('');
        break;
      case 'edit':
        break;
      case 'delete':
        break;
      case 'change':
        break;
      case 'search':
        setPage(0);
        data.page_number = 1;
        break;
      default:
        break;
    }
    console.log(data);
    setLoading(true);
    tariffListFunction(JSON.stringify(data), setErrorMessage, setError).then((promiseResult) => {
      console.log("promised results is", promiseResult)
      if (promiseResult.category === 'Success') {
        setDbdata(formatDataFunc(promiseResult.data.data))
        setAmount_data(promiseResult.data.total_record);
        setSuccess(true);
      } else {
        setErrorMessage(promiseResult.message);
        setError(true);
        setDbdata([]);
        setAmount_data(0);
        setPage(0);
      }
      setLoading(false);
    });
    //   searchTariffFunction(JSON.stringify({ name: value }),setErrorMessage, setError).then((promiseResult) => {
    //       if (promiseResult.category === 'Success') {
    //         setDbdata(formatDataFunc(promiseResult.data));
    //         setSuccess(true);
    //       } else {
    //         setErrorMessage(promiseResult.message);
    //         setError(true);
    //         setDbdata(setData);
    //       }
    //     });
  };
  useImperativeHandle(ref, () => ({
    update_List,
  }));
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // update_List({ oprType: 'change' });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value, 10);
    setPage(0);
    // update_List({ oprType: 'change' });
  };
  const setData = [
    {
      id: '49d705b5-1733-48af-a281-805602963ae2',
      name: 'update7',
      currency: 'SGD',
      tariff_url: null,
      min_price_excl_vat: 123,
      min_price_incl_vat: 0,
      max_price_excl_vat: 256,
      max_price_incl_vat: 0,
      start_date_time: null,
      end_date_time: null,
      tariff_text: [{ description: '{}', language: 'en' }],
      reservation_show: true,
      reservationExpires_show: true,
      min_price: { excl_vat: 123, incl_vat: 0 },
      max_price: { excl_vat: 256, incl_vat: 0 },
      description: '{}',
      elements: [
        {
          eleType: 'element',
          id: 1,
          price_component: [{ price: 0.5, step_size: 1, type: 'FLAT', vat: 0 }],
          restrictions_show: true,
          restrictions: {
            end_date: null,
            end_time: null,
            start_date: null,
            start_time: '16:44',
            day_of_week: ['WEDNESDAY'],
          },
        },
        {
          eleType: 'element',
          id: 2,
          price_component: [{ price: 0.65, step_size: 1, type: 'ENERGY', vat: 0 }],
          restrictions_show: true,
          restrictions: {
            end_date: null,
            end_time: null,
            start_date: null,
            start_time: '16:44',
            day_of_week: ['TUESDAY'],
            min_power: 5,
          },
        },
        {
          eleType: 'reservation',
          id: 3,
          price_component: [{ price: 1, step_size: 2, type: 'FLAT', vat: 0 }],
          restrictions_show: false,
          restrictions: { end_date: null, end_time: null, start_date: null, start_time: null, day_of_week: [] },
        },
        {
          eleType: 'reservation',
          id: 4,
          price_component: [{ price: 1, step_size: 1, type: 'TIME', vat: 0 }],
          restrictions_show: true,
          restrictions: {
            end_date: null,
            end_time: null,
            start_date: null,
            start_time: null,
            day_of_week: ['TUESDAY'],
            min_power: 2,
          },
        },
        {
          eleType: 'expires',
          id: 5,
          price_component: [{ price: 1, step_size: 1, type: 'FLAT', vat: 0 }],
          restrictions_show: false,
          restrictions: { end_date: null, end_time: null, start_date: null, start_time: null, day_of_week: [] },
        },
      ],
    },
    {
      id: 'e82f574a-61e7-44f4-ab17-7a8792d3715c',
      name: 'update6',
      currency: 'SGD',
      tariff_url: null,
      min_price_excl_vat: 0,
      min_price_incl_vat: 0,
      max_price_excl_vat: 0,
      max_price_incl_vat: 0,
      start_date_time: null,
      end_date_time: null,
      tariff_text: [{ description: '{}', language: 'en' }],
      reservation_show: false,
      reservationExpires_show: false,
      min_price: { excl_vat: 0, incl_vat: 0 },
      max_price: { excl_vat: 0, incl_vat: 0 },
      description: '{}',
      elements: [
        {
          eleType: 'element',
          price_component: [{ price: 0.5, step_size: 1, type: 'FLAT', vat: 0 }],
          restrictions_show: false,
          restrictions: { end_date: null, end_time: null, start_date: null, start_time: null, day_of_week: [] },
        },
        {
          eleType: 'element',
          price_component: [{ price: 0.65, step_size: 1, type: 'ENERGY', vat: 0 }],
          restrictions_show: false,
          restrictions: { end_date: null, end_time: null, start_date: null, start_time: null, day_of_week: [] },
        },
      ],
    },
  ];
  return (
    <Box>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '10px',
          borderBottom: '1px solid #363a3f',
          alignContent: 'center',
        }}
      >
        <p
          style={{
            lineHeight: '22px',
            heght: '35px',
            padding: '22px 16px',
            fontSize: '14px',
            fontWeight: 700,
            color: '#F4F4F4',
            fontFamily: 'Arial',
          }}
        >
          {t('Amount_data')}: {Amount_data}
        </p>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 50, { label: t('All'), value: -1 }]}
          count={Amount_data}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={t('rows per page')}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={(props) => <TablePaginationActions t={t} {...props} />}
          sx={{
            color: '#F4F4F4',
            fontFamily: 'Arial',
            fontWeight: 400,
            fontSize: '14px',
            border: 'none',
          }}
        />
      </div>
      {unauthorized && <LoginAgain />}
      <Snackbar
        open={success}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
      >
        <Alert severity="success">{t('Success')}!</Alert>
      </Snackbar>
      <Snackbar
        open={error}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleCloseSnackbar}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>

      {loading && (
        <div style={{ marginTop: '-15px', width: '100%' }}>
          <LinearProgress />
        </div>
      )}
      {dbdata.map((row, ind) => (
        <Row
          updateList={update_List}
          key={row.id}
          row={row}
          // update_List={update_List}
          setSuccess={(childData) => setSuccess(childData)}
          setError={(childData) => setError(childData)}
          setErrorMessage={(childData) => setErrorMessage(childData)}
        />
      ))}
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20, 50, { label: t('All'), value: -1 }]}
        count={Amount_data}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage={t('rows per page')}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={(props) => <TablePaginationActions t={t} {...props} />}
        sx={{
          width: '100%',
          float: 'right',
          color: '#F4F4F4',
          fontFamily: 'Arial',
          fontWeight: 400,
          fontSize: '14px',
        }}
      />
    </Box>
  );
};

export default forwardRef(CollapsibleTable);
